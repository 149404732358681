import styled from "styled-components";
export const Header = styled.h1`
    color: rgba(0, 109, 255, 0.8);
    text-shadow: 1px 4px 6px #fcfcfc, 0 0 0 rgba(0, 0, 0, 1), 1px 4px 6px #fcfcfc;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 650;
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 16px;
    text-align:left;
`;

export const BlueBackground = styled.div`
    padding: 0.5rem 0.5rem;
    background-color: #175bcb;
    color: #f2f2f2;
    width: 100%;
`;
export const CalcText = styled.div`
    border: 1px solid #dddddd;
    padding: 5px 11px;
    border-radius: 6px;
    min-width: 27vw;
    font-size:14px;
    color:#000
`;
export const CalcTextA = styled.div`
    border: 1px solid #dddddd;
    padding: 5px 11px;
    border-radius: 6px;
    // min-width: 27vw;
    font-size:14px;
    color:#000
`;
export const WhiteBack = styled.div`
    background-color:#FFFFFF;
    padding 20px 10px;
    width: 100%;
`;

export const Padding = styled.div`
    padding-bottom: 10px;
`;
export const BtnStyle = {
    fontSize: '14px',
    padding: '4px 25px',
    width: '90px',
    margin: '0 15px'
}