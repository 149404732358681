export const APP_NAME = "Boost With HMA";

export const FACEBOOK_API_URL = "https://graph.facebook.com/v12.0";
export const FACEBOOK_API_DATA_LIMIT = 1000;
export const FACEBOOK_API_TYPE = "adinterest";

export const PAGE_HEAD_TITLE = "- Boost With HMA";
export const HOMEPAGE_HEADER = "10x Your Advertising ROAS";
export const HOMEPAGE_SUB_HEADER = "Boost Your Ads With A Ninja™ Technique";

// RELATED KEYWORDS - EARLIER WAS GOOGLE
export const GOOGLE_KEYWORDS_API_DATA_LIMIT = 13;
export const GOOGLE_KEYWORDS_IDEAS_API_DATA_LIMIT = 12;
// KEYWORD SUGGESTIONS - EARLIER WAS YOUTUBE
export const YOUTUBE_KEYWORDS_API_DATA_LIMIT = 25;
export const RANKED_KEYWORDS_API_DATA_LIMIT = 25;
export const SHOPPING_ADS_KEYWORDS_API_DATA_LIMIT = 100;

export const FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE = 120;
export const FREE_KEYWORD_RESEARCH_QUOTA_BING = 120;
export const FREE_KEYWORD_RESEARCH_QUOTA = 120;
export const FREE_KEYWORD_RESEARCH_QUOTA_SPY_ADS = 1000;
export const FREE_KEYWORD_REQUEST_QUOTA_AI = 1000;

// export const STRIPE_PUB_KEY = "pk_test_Tj3U1UfZBQTZcAgQuoCyyeQt00XeG1m8oA"; // TEST
export const STRIPE_PUB_KEY = "pk_live_WgVCx7txaYFshxSNtTM7uue400KuHEEXme"; // LIVE

export const EMAIL_VALIDATE_CLEAN_API_URL =
  "https://email-api.grorapid.com/api/v1/email-verify/validate/clean";
export const EMAIL_VALIDATE_CLEAN_API_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjA5MjBjNzUxOWRhN2FhM2Y3ZjIzM2MiLCJpYXQiOjE2MjUyMDUzNTB9.fD9bKBqVBWzKyBsj53_XbIjTTDUWkHsMUcmOAVQKyiY";

export const FIREBASE_URL_FACEBOOK =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/fbHiddenInterests";
export const FIREBASE_URL_KW_RELATED_AND_SUGGESTED =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/relatedAndSuggestedKeywords";

export const FIREBASE_URL_KW_RANKED =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/rankedKeywords";

export const FIREBASE_URL_KW_IDEAS =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/keywordIdeas";
export const FIREBASE_URL_AI_GENERATOR =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/aiGenerator";
export const FIREBASE_URL_CUR_CONVERTOR =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/convertCurrency";
export const FIREBASE_URL_STRIPE_CREATE_SUBSCRIPTION =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/createSubscription";

export const google_ads_api_token =
  "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/google_ads_api_token";
export const firebaseURL = {
  SPY_SHOPPING_ADS_TASK_ID:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/spyShoppingAdsTaskId",
  SPY_SHOPPING_ADS_TASK_DATA:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/spyShoppingAdsTaskData",
  adspyderAdsApi:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/spyAdsApi",
  spyAdsDetailsApi:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/spyAdsDetailsApi",

  shoppingAdsTaskPost:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/shoppingAdsTaskPost",
  shoppingAdsTaskGet:
    "https://us-central1-boost-with-hma-6dbda.cloudfunctions.net/shoppingAdsTaskGet",
};
