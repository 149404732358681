import React, { useState, useEffect, useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import { Popconfirm, Button, notification } from 'antd';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import { Header } from '../../styles/styles'
import { Database } from '../../firebase';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

const ListCollections = props => {

    const authUser = useContext(UserContext);
    const [collections, setCollections] = useState([]);

    const columns = [
        {
            dataField: 'searchType',
            text: 'Type',
        },
        {
            dataField: 'collectionName',
            text: 'Collection',
        },
        {
            dataField: 'createdDate',
            text: 'Date',

        },
        {
            dataField: 'view',
            text: 'Action',
            formatter: (cell, row) => {
                return <React.Fragment>
                    <Button type="link" >
                        <Link to={'/ai-ads-collection/' + row.view}>View</Link>
                    </Button>
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => deleteCollection(row.view)}>
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm>
                </React.Fragment>
            },
        }
    ];

    useEffect(() => {
        document.title = `AI Ads Collections ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {
        (async function () {
            await fetchCollections();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCollections = async () => {
        // setCollections([]);
        const { uid } = authUser
        if (uid) {
            const q = query(collection(Database, "aiAdsCollection"), where("userId", "==", uid));
            const querySnapshot = await getDocs(q);

            setCollections(
                querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    const dateCreated = moment(data.createdDate.toDate()).format('DD-MM-YYYY')
                    return ({
                        searchType: data.searchType,
                        collectionName: data.collectionName,
                        createdDate: dateCreated,
                        view: doc.id
                    })
                })
            );
        }
    }

    const deleteCollection = async (id) => {
        await deleteDoc(doc(Database, "aiAdsCollection", id));
        const updatedData = collections.filter(item => item.view !== id);
		setCollections(updatedData);
        notification['success']({
            message: 'Collection has been deleted.',
        });
    }

    const pageListRenderer = ({ pages, onPageChange }) => {
        const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
        if (pages.length > 1) {
            return (
                <div className='text-center'>
                    {pageWithoutIndication.map((p, index) => {
                        return (
                            <button key={index} className={p.active ? 'btn paginations active' : 'btn paginations '} actives={p.active} onClick={() => onPageChange(p.page)}>{p.page}</button>
                        )
                    })}
                </div>
            );
        } else {
            return false
        }

    };

    const options = {
        pageListRenderer,
        hideSizePerPage: true,
    };

    return (
        <div className="content-wrapper">
            <Header>Your Collections</Header>
            <div className="cust-table">
                {collections.length > 0 &&
                    <BootstrapTable
                        keyField="view"
                        data={collections}
                        columns={columns}
                        headerColumn
                        bordered={false}
                        pagination={paginationFactory(options)}
                    />
                }
            </div>
        </div>
    )
}

export default ListCollections;
