import axios from 'axios';
import { firebaseURL } from '../constants';

export const shoppingAdsTaskPost = async (type, data) => {

    const currencyCode = data.currency_code;
    const currencySymbol = data.currency_symbol;

    let currencyConversionData = {
        currencyCode: currencyCode,
        currencySymbol: currencySymbol,
    };

    const resultKw = await axios.post(firebaseURL.shoppingAdsTaskPost, {
        data: data,
        type: type,
    }, {
        headers: { 'Accept': 'application/json' }
    }).then(response => {
        // console.log("response:", response)
        return response.data
    }).catch((error) => {
        // console.log(error);
    });

    let apidata = {
        taskId: resultKw,
        currencyConversionData,
    };

    return apidata;
}

export const shoppingAdsTaskGet = async (type, data, currencyConversionData) => {

    const resultKw = await axios.post(firebaseURL.shoppingAdsTaskGet, {
        data: data,
        type: type,
    }, {
        headers: { 'Accept': 'application/json' }
    }).then(response => {
        // console.log("response:", response)
        return response.data
    }).catch((error) => {
        console.log(error);
    });

    let apidata = [];
    if (resultKw && resultKw.status_code === 20000 && resultKw.result) {

        let fdata;
        if (type === "AMAZON_LISTING") {
            fdata = formatAmazonListing(resultKw.result, currencyConversionData)
        } else {
            fdata = formatGoogleSpyAds(resultKw.result, currencyConversionData)
        }
        apidata = {
            result: fdata,
            status_code: resultKw.status_code,
            status_message: resultKw.status_message,
            ...(type === "AMAZON_LISTING" && {
                categories: resultKw.categories,
                item_types: resultKw.item_types,
            }),
        }
    } else {
        apidata = resultKw;
    }

    return apidata;
}

export const formatGoogleSpyAds = (response, currencyConversionData) => {

    response = response.filter(ele => ele.type === "google_shopping_paid" || ele.type === "google_shopping_serp")

    const data = response.map((ele) => {
        return {
            product_images: ele.product_images,
            title: ele.title,
            currency: ele.currency,
            price: ele.price,
            url: ele.url,
            description: ele.description,
            type: ele.type,
        }
    })

    const output = data.map((ele) => {

        let price = ele.price;
        if (price) {
            price = price.toFixed(2);
            price = `${currencyConversionData.currencySymbol} ${price}`;
        } else {
            price = "-";
        }

        return {
            product_images: ele.product_images,
            title: ele.title,
            currency: ele.currency,
            price: price,
            url: ele.url,
            description: ele.description,
            type: ele.type,
        }
    })
    return output;
}

export const formatAmazonListing = (response, currencyConversionData) => {

    response = response.filter(ele => ele.type === "amazon_serp" || ele.type === "amazon_paid")

    const data = response.map((ele) => {

        let price_from = ele.price_from;
        let price_to = ele.price_to;
        if (price_from) {
            price_from = price_from.toFixed(2);
            price_from = `${currencyConversionData && currencyConversionData.currencySymbol ? currencyConversionData.currencySymbol : ""} ${price_from}`;
        } else {
            price_from = "-";
        }
        if (price_to) {
            price_to = price_to.toFixed(2);
            price_to = `${currencyConversionData && currencyConversionData.currencySymbol ? currencyConversionData.currencySymbol : ""} ${price_to}`;
        } else {
            price_to = "-";
        }

        return {
            rank_absolute: ele.rank_absolute,
            domain: ele.domain,
            title: ele.title,
            url: ele.url,
            image_url: ele.image_url,
            price_from: price_from,
            price_to: price_to,
            currency: ele.currency,
            rating: ele.rating,
            is_amazon_choice: ele.is_amazon_choice,
            is_best_seller: ele.is_best_seller,
        }
    })

    return data;
}