import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { Button, Table, Tag } from 'antd';
import { collection, query, getDocs, where } from "firebase/firestore";
import { getUserAccountPlan } from "../../../utils/Helpers";
import { Database } from '../../../firebase';
import { PAGE_HEAD_TITLE } from "../../../constants";
import { Header } from '../../../styles/styles'

const ListUsers = (props) => {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        document.title = `Users - Admin ${PAGE_HEAD_TITLE}`;
        (async function () {
            await fetchUsers();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUsers = async () => {
        const q = query(collection(Database, "users"), where('email', '!=', 'srikar712@gmail.com'));
        const querySnapshot = await getDocs(q);

        setUsers(
            querySnapshot.docs.map(doc => {
                const data = doc.data();
                const dateCreated = moment(data.createdDate.toDate()).format('DD-MM-YYYY');
                const accountType = getUserAccountPlan(data);
                const price = data.subscriptionPlan && data.subscriptionPlan.price ? data.subscriptionPlan.price : null;
                return ({
                    name: data.name,
                    email: data.email,
                    createdDate: dateCreated,
                    key: doc.id,
                    id: doc.id,
                    accountType: accountType,
                    price: price,
                })
            })
        );
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Account Type',
            key: 'accountType',
            render: (text, record) => {
                let label = record.accountType;
                let bgColor = '#FF0000';
                if (label === "FREE") { bgColor = "lime"; }
                else if (label === "BRONZE") { bgColor = "#6A3805"; }
                else if (label === "SILVER") { bgColor = "#B4B4B4"; }
                else if (label === "GOLD") { bgColor = "#AF9500"; }
                return <Tag color={bgColor}>{label}</Tag>
            },
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <React.Fragment>
                    <Button type="link" >
                        <Link to={'/admin/users/' + record.id}>View Details</Link>
                    </Button>
                    {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => deleteCollection(record.id)}>
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm> */}
                </React.Fragment>
            ),
        },
    ];

    return (
        <div className="content-wrapper">
            <Header>Users</Header>

            <div className="cust-table">
                <Table columns={columns} dataSource={users} />
            </div>
        </div>
    )
}

export default ListUsers;
