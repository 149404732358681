import { StarFilled } from '@ant-design/icons'
import { Button, Input, Modal } from 'antd'
import React, { useState } from 'react'

const CollectionModal = (props) => {
  const { open, closeClickModal, onSubmit, searchType } = props

  const [collectionName, setCollectionName] = useState('')

  const onClickModal = () => {
    if (collectionName) {
      onSubmit(collectionName)
    }
  }

  return (
    <Modal
      className="save-collection-modal"
      closable
      visible={open}
      onCancel={() => closeClickModal()}
      footer={null}
      centered
      width="50%"
    >
      <div className="modal_header">
        <StarFilled style={{ color: '#E7EC0E', marginRight: '1rem' }} /> Save to
        collections
      </div>
      <div className="modal_content">
        <div className="">
          <h3>Name your Collection</h3>

          <div className="my-1">
            <Input
              placeholder="Name"
              value={collectionName}
              size={'large'}
              onChange={(e) => setCollectionName(e.target.value)}
              className="box-shadow-cust-1"
            />
          </div>
          <div className="my-1">
            <Input
              placeholder="input search text"
              disabled
              value={'Collection Type: ' + searchType.replace('_', ' ')}
              onChange={() => {}}
              size={'large'}
              className="no-hover"
            />
          </div>
          <div className="modal-actions">
            <Button type="primary" onClick={onClickModal}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CollectionModal
