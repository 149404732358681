import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Input, Button, Popover } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import UserContext from "../../userContext";
import { Flex, Title, BlueBackground, CalcText, WhiteBack, Padding } from '../../styles/PageStyle'
import LocationDropdown, { locationOptions } from "../locationDropdown";
import { formatNumber, subscribeToExport } from '../../utils/Helpers';
import { PAGE_HEAD_TITLE } from "../../constants";
import BunnyPlayer from '../BunnyPlayer';


const { Text } = Typography;

export default function RoasMaximizer() {

    const authUser = useContext(UserContext);

    //#region States
    const [locationData, setLocationData] = useState(locationOptions[6])
    const [ExpectedAdsSpend, setExpectedAdsSpend] = useState('')
    const [ExpectedRevenue, setExpectedRevenue] = useState('')
    const [AverageOrder, setAverageOrder] = useState('')
    const [PaymentGateway, setPaymentGateway] = useState('')
    const [ShippingCost, setShippingCost] = useState('')
    const [FulfillmentFees, setFulfillmentFees] = useState('')
    const [CostofGood, setCostofGood] = useState('')
    const [AgencyFreelancers, setAgencyFreelancers] = useState('')

    const [RevenueIdentified, setRevenueIdentified] = useState('')
    const [ADsBudgetIdentified, setADsBudgetIdentified] = useState('')
    const [ExpectedSalesfor, setExpectedSalesfor] = useState('')
    const [CreditCardFees, setCreditCardFees] = useState('')
    const [ShippingCost_1, setShippingCost_1] = useState('')
    const [FulfillmentFees2, setFulfillmentFees2] = useState('')
    const [CostofGoodsSold, setCostofGoodsSold] = useState('')
    const [AgencyFreelancers2, setAgencyFreelancers2] = useState('')

    const [GrossProfit, setGrossProfit] = useState('')
    const [NetProfit, setNetProfit] = useState('')
    const [AdjustedCostofGoods, setAdjustedCostofGoods] = useState('')
    const [ReturnonAd, setReturnonAd] = useState('')
    const [CostPerAcquisition, setCostPerAcquisition] = useState('')

    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);
    //#endregion

    //#region useeffects
    useEffect(() => {
        document.title = `ROAS Maximizer ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {
        const currencySymbol = locationData.currencySymbol;
        const data = [
            ["Boost With HMA"],
            [],
            ["ROAS Maximizer", "", `Currency - ${currencySymbol}`],
            [],
            ["Ecommerce Ads Metrics", "Basis Of Calculation", "Amount"],
            ["Expected Ads Spend Level", "Per Month", `${currencySymbol} ${formatNumber(ExpectedAdsSpend)}`],
            ["Expected Revenue", "Per Month", `${currencySymbol} ${formatNumber(ExpectedRevenue)}`],
            ["Average Order Value", "Per Transaction", `${currencySymbol} ${formatNumber(AverageOrder)}`],
            ["Payment Gateway Fees", "Per Transaction", `% ${formatNumber(PaymentGateway)}`],
            ["Shipping Cost", "Per Transaction", `${currencySymbol} ${formatNumber(ShippingCost)}`],
            ["Fulfillment Fees", "Per Transaction", `${currencySymbol} ${formatNumber(FulfillmentFees)}`],
            ["Cost of Good or Service", "Per Transaction", `${currencySymbol} ${formatNumber(CostofGood)}`],
            ["Agency/ Freelancers / Tools Fees", "Per Month", `${currencySymbol} ${formatNumber(AgencyFreelancers)}`],
            [],
            ["Expected Calculations Monthly"],
            ["Revenue Identified for the Month", "", `${currencySymbol} ${formatNumber(RevenueIdentified)}`],
            ["ADs Budget Identified for the Month", "", `${currencySymbol} ${formatNumber(ADsBudgetIdentified)}`],
            ["Expected Sales for the Month", "", `${formatNumber(ExpectedSalesfor)}`],
            ["Credit Card Fees", "", `${currencySymbol} ${formatNumber(CreditCardFees)}`],
            ["Shipping Costs", "", `${currencySymbol} ${formatNumber(ShippingCost_1)}`],
            ["Fulfillment Fees", "", `${currencySymbol} ${formatNumber(FulfillmentFees2)}`],
            ["Cost of Goods", "", `${currencySymbol} ${formatNumber(CostofGoodsSold)}`],
            ["Agency/ Freelancers / Tools Fees", "", `${currencySymbol} ${formatNumber(AgencyFreelancers2)}`],
            [],
            ["Actual Ads Results"],
            ["Gross Profit", "", `${currencySymbol} ${formatNumber(GrossProfit)}`],
            ["Net Profit", "", `${currencySymbol} ${formatNumber(NetProfit)}`],
            ["Adjusted Cost of Goods", "", `${currencySymbol} ${formatNumber(AdjustedCostofGoods)}`],
            ["Return on Ad Spend (ROAS)", "", `${formatNumber(ReturnonAd)}`],
            ["Cost Per Acquisition (CPA)", "", `${currencySymbol} ${formatNumber(CostPerAcquisition)}`],
        ];
        setCsvData(data);
    }, [locationData, ExpectedAdsSpend, ExpectedRevenue, AverageOrder, PaymentGateway, ShippingCost, FulfillmentFees, CostofGood, AgencyFreelancers, RevenueIdentified, ADsBudgetIdentified, ExpectedSalesfor, CreditCardFees, ShippingCost_1, FulfillmentFees2, CostofGoodsSold, AgencyFreelancers2, GrossProfit, NetProfit, AdjustedCostofGoods, ReturnonAd, CostPerAcquisition,])

    useEffect(() => {
        setRevenueIdentified(ExpectedRevenue)
    }, [ExpectedRevenue])

    useEffect(() => {
        setADsBudgetIdentified(ExpectedAdsSpend)
    }, [ExpectedAdsSpend])

    useEffect(() => {
        setExpectedSalesfor(RevenueIdentified / AverageOrder)
    }, [RevenueIdentified, AverageOrder])

    useEffect(() => {
        setCreditCardFees(RevenueIdentified * PaymentGateway / 100)
    }, [RevenueIdentified, PaymentGateway])

    useEffect(() => {
        // CreditCardFees * ShippingCost
        setShippingCost_1(ExpectedSalesfor * ShippingCost)
    }, [ExpectedSalesfor, ShippingCost])

    //////////////////////////// 
    useEffect(() => {
        setFulfillmentFees2(ExpectedSalesfor * FulfillmentFees)
    }, [ExpectedSalesfor, FulfillmentFees])

    useEffect(() => {
        setCostofGoodsSold(ExpectedSalesfor * CostofGood)
    }, [ExpectedSalesfor, CostofGood])

    useEffect(() => {
        setAgencyFreelancers2(AgencyFreelancers)
    }, [AgencyFreelancers])

    useEffect(() => {
        setGrossProfit(RevenueIdentified - CostofGoodsSold)
    }, [RevenueIdentified, CostofGoodsSold])

    useEffect(() => {
        // GrossProfit - ADsBudgetIdentified - CreditCardFees - ShippingCost_1 - FulfillmentFees2 - AgencyFreelancers2
        setNetProfit(GrossProfit - ADsBudgetIdentified - CreditCardFees - ShippingCost_1 - FulfillmentFees2 - AgencyFreelancers2)
    }, [GrossProfit, ADsBudgetIdentified, CreditCardFees, ShippingCost_1, FulfillmentFees2, AgencyFreelancers2])

    useEffect(() => {
        var adjustcosts = Number(CreditCardFees + ShippingCost_1 + FulfillmentFees2 + CostofGoodsSold) + Number(AgencyFreelancers2)
        // console.log('adjustcosts', adjustcosts);
        var adjustcostsdivid = adjustcosts / ExpectedSalesfor
        setAdjustedCostofGoods(adjustcostsdivid.toFixed(2))
    }, [CreditCardFees, ShippingCost_1, FulfillmentFees2, CostofGoodsSold, AgencyFreelancers2]) //eslint-disable-line

    useEffect(() => {
        setReturnonAd(RevenueIdentified / ADsBudgetIdentified)
    }, [RevenueIdentified, ADsBudgetIdentified])

    useEffect(() => {
        var val = AverageOrder / ReturnonAd
        setCostPerAcquisition(val.toFixed(2))
    }, [AverageOrder, ReturnonAd])

    var Loss = { backgroundColor: '#EB5757' }
    var Profit = { backgroundColor: '#8DD1B6' }
    /*********End Secound Calc**********/
    //#endregion

    const downloadPdfDocument = () => {
        // const downloadPdfDocument = (rootElementId) => {
        // const input = document.getElementById(rootElementId);
        setDownloadingPdf(true);
        const input = document.getElementById('divToDownload');
        // html2canvas(input, { scale: 0.81 })
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "mm", "a4");
                // const width = pdf.internal.pageSize.getWidth() - 14;
                // const height = pdf.internal.pageSize.getHeight();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 14;

                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + 5;
                pdf.text("Boost With HMA", 5, 10);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight);
                pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight, "a", "FAST");

                pdf.save("download.pdf", { returnPromise: true })
                    .then(() => {
                        setDownloadingPdf(false);
                    });
            })
    }

    const currencySymbol = locationData.currencySymbol;

    return (
        <Row justify="center" className='facg-main-wrapper' style={{ marginTop: '30px' }}>
            <BunnyPlayer type="ECOM_ROAS_MAXIMIZER" />

            <Col span={24} style={{ marginTop: "4rem", marginBottom: '6rem' }}>
                <Row justify="center">
                    <Col span={20} id="divToDownload">
                        <Row justify="space-between" style={{ marginBottom: 16, alignItems: 'center' }}>
                            <Col xs={12}>
                                <Text level={5} style={{ fontWeight: "550", fontSize: 18 }}>
                                    ROAS Maximizer
                                </Text>
                            </Col>
                            <Col xs={12} >
                                <Flex style={{ justifyContent: 'flex-end' }}>
                                    <LocationDropdown defaultValue={locationData} onChange={(value) => { setLocationData(value) }} />
                                </Flex>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col span={24}>
                                <BlueBackground>
                                    <Row align="middle">
                                        <Col span={12}>
                                            <Title>Ecommerce Ads Metrics</Title>
                                        </Col>
                                        <Col span={6} align="center">
                                            <Title>Basis Of Calculation</Title>
                                        </Col>
                                        <Col span={6} align="center">
                                            <Title>Amount</Title>
                                        </Col>
                                    </Row>
                                </BlueBackground>
                            </Col>
                            <Col span={24}>
                                <WhiteBack>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Expected Ads Spend Level</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Month</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={ExpectedAdsSpend}
                                                    onChange={(e) => setExpectedAdsSpend(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Expected Revenue</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Month</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={ExpectedRevenue}
                                                    onChange={(e) => setExpectedRevenue(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Average Order Value</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Transaction</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={AverageOrder}
                                                    onChange={(e) => setAverageOrder(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Payment Gateway Fees</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Transaction</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={'%'}
                                                    value={PaymentGateway}
                                                    onChange={(e) => setPaymentGateway(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Shipping Cost</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Transaction</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={ShippingCost}
                                                    onChange={(e) => setShippingCost(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Fulfillment Fees</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Transaction</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={FulfillmentFees}
                                                    onChange={(e) => setFulfillmentFees(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Cost of Good or Service</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Transaction</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={CostofGood}
                                                    onChange={(e) => setCostofGood(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle">
                                            <Col span={6}>
                                                <CalcText>Agency/ Freelancers / Tools Fees</CalcText>
                                            </Col>
                                            <Col offset={6} span={6} align="left">
                                                <div className='custom-text'>Per Month</div>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    value={AgencyFreelancers}
                                                    onChange={(e) => setAgencyFreelancers(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                </WhiteBack>
                            </Col>
                            <Col span={24}>
                                <BlueBackground>
                                    <Row align="middle" justify='space-between'>
                                        <Col span={24}>
                                            <Title>Expected Calculations Monthly</Title>
                                        </Col>
                                    </Row>
                                </BlueBackground>
                            </Col>
                            <Col span={24}>
                                <WhiteBack>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Revenue Identified for the Month</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={RevenueIdentified}
                                                    onChange={(e) => setRevenueIdentified(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>ADs Budget Identified for the Month</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={ADsBudgetIdentified}
                                                    onChange={(e) => setADsBudgetIdentified(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Expected Sales for the Month</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={ExpectedSalesfor}
                                                    onChange={(e) => setExpectedSalesfor(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Credit Card Fees</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={CreditCardFees}
                                                    onChange={(e) => setCreditCardFees(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Shipping Costs</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={ShippingCost_1}
                                                    onChange={(e) => setShippingCost_1(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Fulfillment Fees</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={FulfillmentFees2}
                                                    onChange={(e) => setFulfillmentFees2(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Cost of Goods</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={CostofGoodsSold}
                                                    onChange={(e) => setCostofGoodsSold(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Agency/ Freelancers / Tools Fees</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={AgencyFreelancers2}
                                                    onChange={e => setAgencyFreelancers2(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                </WhiteBack>
                            </Col>
                            <Col span={24}>
                                <BlueBackground>
                                    <Row align="middle" justify='space-between'>
                                        <Col span={24}>
                                            <Title>Actual Ads Results</Title>
                                        </Col>
                                    </Row>
                                </BlueBackground>
                            </Col>
                            <Col span={24}>
                                <WhiteBack>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Gross Profit</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={GrossProfit}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Net Profit</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={NetProfit < 0 ? Loss : Profit}
                                                    value={NetProfit}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Adjusted Cost of Goods</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={{ backgroundColor: '#F3F3F3' }}
                                                    value={AdjustedCostofGoods}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Return on Ad Spend (ROAS)</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    readOnly
                                                    style={ReturnonAd < 0 ? Loss : Profit}
                                                    value={ReturnonAd}
                                                    onChange={(e) => setReturnonAd(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                    <Padding>
                                        <Row align="middle" justify='space-between'>
                                            <Col span={6}>
                                                <CalcText>Cost Per Acquisition (CPA)</CalcText>
                                            </Col>
                                            <Col span={6} align="left">
                                                <Input
                                                    type="number"
                                                    prefix={currencySymbol}
                                                    readOnly
                                                    style={CostPerAcquisition < 0 ? Loss : Profit}
                                                    value={CostPerAcquisition}
                                                    onChange={(e) => setCostPerAcquisition(e.target.value)}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </Padding>
                                </WhiteBack>
                            </Col>
                        </Row>
                        {/* <Row style={{ marginTop: 50 }} justify="center">
                            <Button style={BtnStyle} type="primary" shape="round">Open</Button>
                            <Button style={BtnStyle} type="primary" shape="round">Save</Button>
                        </Row> */}
                    </Col>
                </Row>
                {
                    authUser.accountType === "FREE" ?
                        <Row justify="center" style={{ marginTop: '2rem' }}>
                            <Popover content={subscribeToExport} className="">
                                <Button className="primary-button not-allowed-button">Export as PDF</Button>
                            </Popover>

                            <Popover content={subscribeToExport} className="" >
                                <Button className="primary-button not-allowed-button" style={{ marginLeft: '20px' }}>Export as CSV</Button>
                            </Popover>
                        </Row>
                        :
                        <Row justify="center">
                            <Button className="primary-button mt-2rem" onClick={downloadPdfDocument} disabled={downloadingPdf}>{downloadingPdf ? 'Exporting...' : 'Export as PDF'}</Button>
                            <CSVLink
                                className="csv-download-button primary-button mt-2rem"
                                data={csvData}
                                onClick={() => {
                                    // console.log("You click the link");
                                    if (downloadingCsv) {
                                        return false;
                                    } else {
                                        setDownloadingCsv(true);
                                        const timeout = setTimeout(() => {
                                            setDownloadingCsv(false);
                                            clearTimeout(timeout);
                                        }, 3000)
                                    }
                                }}
                                filename={"roas-maximizer.csv"}
                            >
                                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
                            </CSVLink>
                        </Row>
                }
            </Col>
        </Row>
    );
}
