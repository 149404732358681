import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getDoc, doc } from "firebase/firestore";
import { Database } from '../../../firebase';
import { PAGE_HEAD_TITLE } from "../../../constants";
import UserMain from '../../User/UserMain';

const ListUsers = (props) => {

    const { id: userId } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        document.title = `User - Admin ${PAGE_HEAD_TITLE}`;
        (async function () {
            await fetchUser();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUser = async () => {

        if (userId) {
            const docRef = doc(Database, "users", userId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = await docSnap.data();

                const formatedDate = moment(data.createdDate.toDate()).format('DD-MM-YYYY')
                data.dateCreated = formatedDate;
                // console.log("data:", data);
                setUser(data);
            } else {
                console.log("No such document!");
            }
        }
    }

    return <UserMain user={user} authUser={null} />
}

export default ListUsers;
