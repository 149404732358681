import React, { useEffect, useRef, useState } from "react";
import { Title } from "../aiAds/AiAdsGenerator";
import { Col, notification, Row, Select } from "antd";
import backIcon from "../../styles/icons/back.svg";
import { addDays, startOfMonth } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enUS } from "date-fns/locale";
import EngagementPieChart from "./components/EngagementPieChart";
import DualLineChart from "./components/DualLineChart";
import logo from "../../styles/images/facebooklogo.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import companyLogo from "../../styles/images/logo.png";
import {
  IoIosInformationCircleOutline,
  IoMdTrendingDown,
  IoMdTrendingUp,
} from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BootstrapTable from "react-bootstrap-table-next";
import CPMChart from "./components/CPMCharts";
import Tooltip from "./components/ToolTip";
import GraphComponent from "./components/GraphChart";
import {
  calculatePercentageChange,
  formatDate,
  getTimeRange,
} from "../../utils/Helpers";
import { useLocation } from "react-router-dom";
import TableWithTrends from "./components/TableWithTrends";
import LeadsAgeBarChart from "./components/PlotCharts";
import DeviceTable from "./components/DeviceTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CampaginTable from "./components/CampaginTable";

/* global FB */
const FacebookEcom = () => {
  const [adAccounts, setAdAccounts] = useState([]);
  const [adCamp, setAdCamp] = useState();
  const [adAccountValue, setAdAccountValue] = useState();
  const [AccountValue, setAccountValue] = useState(false);
  const { Option } = Select;
  const [adCampaigns, setAdCampaigns] = useState([]);
  const [owner_name, set_owner_name] = useState(null);
  const [adsDetailUi, setAdDetailsUi] = useState(false);
  const [login, setlogin] = useState(false);
  const [fullReport, setFullReport] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const [previousInsightsData, setPreviousInsightsData] = useState({});
  const [ctrPercentage, setCtrPercentage] = useState(0);
  const [age_data, setAgeData] = useState([]);
  const [leads, setLeads] = useState(0);
  const [previousLeads, setPreviousLeads] = useState(0);
  const [avgCpl, setAvgCpl] = useState(0);
  const [previousAvgCpl, setPreviousAvgCpl] = useState(0);
  const [fullReportSpend, setFullReportSpend] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [postReactions, setPostReactions] = useState("0");
  const [postLikes, setPostLikes] = useState("0");
  const [postSaves, setPostSaves] = useState("0");
  const [costPerPostSave, setCostPerPostSave] = useState("0");
  const [costPerReaction, setCostPerReaction] = useState("0");
  const [costPerPostShare, setCostPerPostShare] = useState("0");
  const [costPerPostComment, setCostPerPostComment] = useState("0");
  const [postComments, setPostComments] = useState("0");
  const [loading, setLoadingState] = useState(false);
  const [postReactionsChange, setPostReactionsChange] = useState("0");
  const [postSaveChange, setPostSaveChange] = useState("0");
  const [postShareChange, setPostShareChange] = useState("0");
  const [postCommentChange, setPostCommentChange] = useState("0");
  const [CostPerReactionChanges, setCostPerReactionChanges] = useState("0");
  const [CostPerLeadChanges, setCostPerLeadChanges] = useState("0");
  const [CostPerPostSaveChanges, setCostPerPostSaveChanges] = useState("0");
  const [CostPerPostShareChanges, setCostPerShareChanges] = useState("0");
  const [CostPerPostCommentChanges, setCostPerCommentChanges] = useState("0");
  const [videoWatch25, setVideoWatch25] = useState(0);
  const [videoWatch50, setVideoWatch50] = useState(0);
  const [videoWatch75, setVideoWatch75] = useState(0);
  const [videoWatch100, setVideoWatch100] = useState(0);
  const [videoWatchReaction25, setVideoWatchReaction25] = useState(0);
  const [videoWatchReaction50, setVideoWatchReaction50] = useState(0);
  const [videoWatchReaction75, setVideoWatchReaction75] = useState(0);
  const [videoWatchReaction100, setVideoWatchReaction100] = useState(0);
  const [videoCostWatch25, setVideoCostWatch25] = useState(0);
  const [videoCostWatch50, setVideoCostWatch50] = useState(0);
  const [videoCostWatch75, setVideoCostWatch75] = useState(0);
  const [videoCostWatch100, setVideoCostWatch100] = useState(0);
  const [videoCostWatchReaction25, setVideoCostWatchReaction25] = useState(0);
  const [videoCostWatchReaction50, setVideoCostWatchReaction50] = useState(0);
  const [videoCostWatchReaction75, setVideoCostWatchReaction75] = useState(0);
  const [videoCostWatchReaction100, setVideoCostWatchReaction100] = useState(0);
  const [amountReactionsChange, setAmountReactionsChange] = useState(0);
  const [cpmReactionsChange, setCpmReactionsChange] = useState(0);
  const [ctrReactionsChange, setCtrReactionsChange] = useState(0);
  const [leadReactionsChange, setLeadReactionsChange] = useState(0);
  const [ImpressionReactionsChange, setImpressionReactionsChange] = useState(0);
  const [reachReactionsChange, setReachReactionsChange] = useState(0);
  const [clickReactionsChange, setclickReactionsChange] = useState(0);
  const [linkClickReactionsChange, setClickReactionsChange] = useState(0);
  const [dateWiseData, setDateWiseData] = useState([]);
  const [prevoiusDateWiseData, setPreviousDateWiseData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [prevDeviceData, setPrevDeviceData] = useState([]);
  const [campaginData, setCampaginData] = useState([]);
  const [prevCampaginData, setPrevCampaginData] = useState([]);

  const reportRef = useRef();
  const [data, setData] = useState([]);
  const [cpmChart, setCpmChartData] = useState([]);
  const [ctrChart, setCtrChartData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState([
    {
      startDate: startOfMonth(new Date()), // Set start date to the first day of the current month
      endDate: new Date(), // Set end date to today
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("isLoggedIn");

    if (storedLoginStatus) {
      setlogin(JSON.parse(storedLoginStatus));
    }
  }, []);

  const statusChangeCallback = (response) => {
    if (response.status === "connected") {
      testAPI();
      checkPermissions();
      localStorage.setItem("isLoggedIn", JSON.stringify(true));
      setlogin(true);
    } else {
      // notification["error"]({
      //   message: "Please log into this webpage",
      // });
      setlogin(false);
      // localStorage.setItem("isLoggedIn", JSON.stringify(false));
      localStorage.removeItem("isLoggedIn");
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        statusChangeCallback(response); // Handle response if user is logged in
      } else {
        setlogin(false); // Reset login state if not connected
      }
    });
  };

  useEffect(() => {
    let authStatus = null;
    const loadFBSDK = () => {
      window.fbAsyncInit = function () {
        FB.init({
          appId: "1839132246545497",
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });

        FB.AppEvents.logPageView();

        window.FB.Event.subscribe("auth.authResponseChange", (response) => {
          setTimeout(() => {
            FB.getLoginStatus((statusResponse) => {
              if (authStatus !== statusResponse.status) {
                authStatus = statusResponse.status;
                statusChangeCallback(statusResponse); // Call function only on change
              }
            });
          }, 200);
        });

        FB.getLoginStatus(function (response) {
          statusChangeCallback(response);
        });
        // window.FB.Event.subscribe('auth.authResponseChange', (response) => {
        //   statusChangeCallback(response);
        // });

        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      };

      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      document.body.appendChild(script);
    };

    if (!window.FB) {
      loadFBSDK();
    } else {
      // FB SDK is already loaded, check login status
      FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    }
  }, []);

  useEffect(() => {
    // Check if Facebook SDK is available
    if (window.FB) {
      window.FB.XFBML.parse(); // Re-render the button using Facebook's SDK
    }
  }, [location.pathname]);

  const checkPermissions = () => {
    FB.api("/me/permissions", function (response) {});
  };

  const testAPI = () => {
    FB.api("/me", "GET", function (response) {
      if (response.name) {
        set_owner_name(response.name);
      } else {
        notification["error"]({
          message: "Failed to retrieve user information.",
        });
      }
    });

    FB.api("/me/adaccounts", "GET", { fields: "id,name" }, function (response) {
      if (response && response.data && response.data.length > 0) {
        setAdAccounts(response.data);
      } else {
        notification["error"]({
          message: "No ads Account Found",
        });
      }
    });
  };

  const selectedAdAccount = (value) => {
    setAdAccountValue(value);
    fetchAdCampaigns(value);
  };

  const fetchAdCampaigns = (accountId) => {
    FB.api(
      `/${accountId}/campaigns`,
      "GET",
      { fields: "id,name" },
      function (response) {
        if (
          response &&
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setAdCampaigns(response.data);
        } else {
          setAdCampaigns([]);
        }
      }
    );
  };

  const start_fetching_report = async (status) => {
    let value;
    if (status == "Account") {
      setAccountValue(true);
      if (adAccountValue !== null && adAccountValue != undefined) {
        value = adAccountValue;
      } else {
        notification["error"]({
          message: "Chose Account First",
        });
        return;
      }
    } else {
      setAccountValue(false);
      if (adCamp !== null && adCamp != undefined) {
        value = adCamp;
      } else {
        notification["error"]({
          message: "Chose Campaign First",
        });
        return;
      }
    }

    const start = new Date(state[0].startDate);
    const end = new Date(state[0].endDate);
    const startDate = formatDateInFB(start);
    const endDate = formatDateInFB(end);

    setLoadingState(true);
    await fetch_account_details(value, startDate, endDate);
    await fetchLeadsByGender(value, startDate, endDate);
    await fetch_age_details(value, startDate, endDate);
    await fetch_full_EngagementData(value, startDate, endDate);
    await fetchLongTerm(value, startDate, endDate);
    await CampaignData(value, startDate, endDate);
    setLoadingState(false);
  };

  const start_Refetching_report = async (status) => {
    let value;
    if (status == "Account") {
      if (adAccountValue !== null && adAccountValue != undefined) {
        value = adAccountValue;
        setAccountValue(true);
      } else {
        // notification["error"]({
        //   message: "Chose Account First",
        // });
        return;
      }
    } else {
      if (adCamp !== null && adCamp != undefined) {
        value = adCamp;
      } else {
        // notification["error"]({
        //   message: "Chose Campaign First",
        // });
        return;
      }
    }

    const start = new Date(state[0].startDate);
    const end = new Date(state[0].endDate);
    const startDate = formatDateInFB(start);
    const endDate = formatDateInFB(end);

    setLoadingState(true);
    await fetch_account_details(value, startDate, endDate);
    await fetchLeadsByGender(value, startDate, endDate);
    await fetch_age_details(value, startDate, endDate);
    await fetch_full_EngagementData(value, startDate, endDate);
    await fetchLongTerm(value, startDate, endDate);
    await CampaignData(value, startDate, endDate);
    setLoadingState(false);
  };

  const fetch_account_details = async (value, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate previous dates
    const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    const previousEndDate = new Date(start.getTime() - 24 * 60 * 60 * 1000); // Move end to the day before the current start date
    const previousStartDate = new Date(
      previousEndDate.getTime() - daysDifference * 24 * 60 * 60 * 1000
    );

    const formattedPreviousStartDate = previousStartDate
      .toISOString()
      .split("T")[0];
    const formattedPreviousEndDate = previousEndDate
      .toISOString()
      .split("T")[0];

    if (value !== null && value !== undefined) {
      try {
        // Fetch data for the current date range
        const currentResponse = await new Promise((resolve, reject) => {
          FB.api(
            `/${value}/insights`,
            "GET",
            {
              fields:
                "reach,ctr,clicks,cpm,impressions,inline_link_clicks,spend,actions.action_type(post_save,post_share),cost_per_action_type.action_type(post_save,post_share)",
              time_range: { since: startDate, until: endDate },
            },
            (response) => {
              if (response && response.data && response.data.length > 0) {
                resolve(response.data[0]);
              } else {
                reject(new Error("No ads data found for current range"));
              }
            }
          );
        });

        setAdDetailsUi(true);
        setInsightsData(currentResponse);

        if (currentResponse && currentResponse.ctr) {
          setCtrPercentage(formatNumber(currentResponse.ctr));
        }

        const leadAction = currentResponse.actions
          ? currentResponse.actions.find(
              (action) => action.action_type === "lead"
            )
          : null;
        const leadValue = leadAction ? leadAction.value : 0;
        setLeads(leadValue);

        const leadCpl = currentResponse.cost_per_action_type
          ? currentResponse.cost_per_action_type.find(
              (cost_per_action_type) =>
                cost_per_action_type.action_type === "lead"
            )
          : null;
        const leadCplValue = leadCpl ? leadCpl.value : 0;
        setAvgCpl(leadCplValue);

        // Fetch data for the previous date range
        const previousResponse = await new Promise((resolve, reject) => {
          FB.api(
            `/${value}/insights`,
            "GET",
            {
              fields:
                "reach,ctr,clicks,cpm,impressions,inline_link_clicks,spend,actions.action_type(post_save,post_share),cost_per_action_type.action_type(post_save,post_share)",
              time_range: {
                since: formattedPreviousStartDate,
                until: formattedPreviousEndDate,
              },
            },
            (response) => {
              if (response && response.data && response.data.length > 0) {
                resolve(response.data[0]);
              } else {
                resolve(null);
              }
            }
          );
        });

        if (previousResponse) {
          setPreviousInsightsData(previousResponse);

          const previousLeadAction = previousResponse.actions
            ? previousResponse.actions.find(
                (action) => action.action_type === "lead"
              )
            : null;
          const previousLeadValue = previousLeadAction
            ? previousLeadAction.value
            : 0;
          setPreviousLeads(previousLeadValue);

          const previousLeadCpl = previousResponse.cost_per_action_type
            ? previousResponse.cost_per_action_type.find(
                (cost_per_action_type) =>
                  cost_per_action_type.action_type === "lead"
              )
            : null;
          const previousLeadCplValue = previousLeadCpl
            ? previousLeadCpl.value
            : 0;
          setPreviousAvgCpl(previousLeadCplValue);

          // Calculate the percentage changes
          const ImpressionReaction = calculatePercentageChange(
            currentResponse.impressions,
            previousResponse.impressions
          );
          const ReachReaction = calculatePercentageChange(
            currentResponse.reach,
            previousResponse.reach
          );
          const ClicksReaction = calculatePercentageChange(
            currentResponse.clicks,
            previousResponse.clicks
          );
          const LinkClickReaction = calculatePercentageChange(
            currentResponse.inline_link_clicks,
            previousResponse.inline_link_clicks
          );

          setImpressionReactionsChange(ImpressionReaction?.toFixed(2));
          setReachReactionsChange(ReachReaction?.toFixed(2));
          setclickReactionsChange(ClicksReaction?.toFixed(2));
          setClickReactionsChange(LinkClickReaction?.toFixed(2));
        }
      } catch (error) {
        notification["error"]({
          message: error.message,
        });
        setAdDetailsUi(false);
        setLoadingState(false);
      }
    } else {
      notification["error"]({
        message: "Select the Ad Account First",
      });
    }
  };

  const fetch_full_EngagementData = async (value, startDate, endDate) => {
    // Parse the input dates
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate previous dates
    const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    const previousEndDate = new Date(start.getTime() - 24 * 60 * 60 * 1000); // Move end to the day before the current start date
    const previousStartDate = new Date(
      previousEndDate.getTime() - daysDifference * 24 * 60 * 60 * 1000
    );

    // Convert dates to the required format
    const formattedStartDate = previousStartDate.toISOString().split("T")[0];
    const formattedEndDate = previousEndDate.toISOString().split("T")[0];

    // Function to fetch data based on the given dates
    const fetchData = (start, end) => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/insights`,
          "GET",
          {
            fields:
              "actions.action_type(post_save,comment,post,post_reaction),cost_per_action_type.action_type(onsite_conversion.post_save),spend,ctr,cpm",
            time_range: { since: start, until: end },
          },
          function (response) {
            if (response && response.data && response.data.length > 0) {
              resolve(response.data);
            } else {
              console.warn(
                `No data found for date range ${start} to ${end}. Returning empty data.`
              );
              resolve([]);
            }
          }
        );
      });
    };

    // Fetch data for the selected range
    const currentData = await fetchData(startDate, endDate);

    // Fetch data for the previous range
    const previousData = await fetchData(formattedStartDate, formattedEndDate);
    const previousActions =
      previousData.length > 0 ? previousData : [{ actions: [] }];
    // Combine data from both responses
    const allData = [...currentData, ...previousData];

    // Aggregate data as per your existing logic
    const aggregatedData = allData.flatMap((campaign) =>
      campaign.actions
        .filter((action) =>
          [
            "post_reaction",
            "onsite_conversion.post_save",
            "comment",
            "post",
            "lead",
          ].includes(action.action_type)
        )
        .map((action) => ({
          action_type: action.action_type,
          value: action.value,
        }))
    );

    const CurrentAggregatedData = currentData.flatMap((campaign) =>
      campaign.actions
        .filter((action) =>
          [
            "post_reaction",
            "onsite_conversion.post_save",
            "comment",
            "post",
          ].includes(action.action_type)
        )
        .map((action) => ({
          action_type: action.action_type,
          value: action.value,
        }))
    );

    const ConversionData = allData.flatMap((campaign) =>
      campaign.actions
        .filter((action) =>
          ["onsite_conversion.post_save,lead"].includes(action.action_type)
        )
        .map((action) => ({
          action_type: action.action_type,
          value: action.value,
        }))
    );

    const CostPerData = allData.flatMap((campaign) =>
      campaign.cost_per_action_type
        .filter((action) => ["lead"].includes(action.action_type))
        .map((action) => ({
          action_type: action.action_type,
          value: action.value,
        }))
    );

    const spendData = allData.find((item) => item.spend)?.spend;
    const CPMData = allData.find((item) => item.cpm)?.cpm;
    const CTRData = allData.find((item) => item.ctr)?.ctr;
    const PreviousCPMSpendData = previousData.find((item) => item.cpm)?.cpm;
    const PreviousCTRSpendData = previousData.find((item) => item.ctr)?.ctr;

    // --------------Prevous Reaction--------------------------
    setPostReactions(
      aggregatedData.find((item) => item.action_type === "post_reaction")
        ?.value || "0"
    );
    const currentReactions =
      aggregatedData.find((item) => item.action_type === "post_reaction")
        ?.value || "0";
    const previousReactions =
      previousActions
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "post_reaction")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || "0";

    const percentageChangeValue = calculatePercentageChange(
      currentReactions,
      previousReactions
    );
    setPostReactionsChange(percentageChangeValue.toFixed(2));

    // --------------Post Save Reactions--------------------------

    const aggregatedCostSaveData = Number(
      ConversionData.find(
        (item) => item.action_type === "onsite_conversion.post_save"
      )?.value || "0"
    );
    setCostPerPostSave(
      aggregatedCostSaveData > 0
        ? spendData / Number(aggregatedCostSaveData)
        : 0
    );
    const currentPostSaveReactions =
      ConversionData.find(
        (item) => item.action_type === "onsite_conversion.post_save"
      )?.value || "0";
    const prevoiusPostSaveReactions =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter(
              (action) => action.action_type === "onsite_conversion.post_save"
            )
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || "0";

    const percentagePostSaveValue = calculatePercentageChange(
      currentPostSaveReactions,
      prevoiusPostSaveReactions
    );

    setPostSaveChange(percentagePostSaveValue.toFixed(2));

    // ---------------- Post Like Reactions ------------------------

    setPostLikes(
      aggregatedData.find((item) => item.action_type === "post")?.value || "0"
    );

    const currentPostShareReactions =
      aggregatedData.find((item) => item.action_type === "post")?.value || "0";
    const prevoiusPostShareReactions =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "post")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || "0";

    const percentagePostShareValue = calculatePercentageChange(
      currentPostShareReactions,
      prevoiusPostShareReactions
    );

    setPostShareChange(percentagePostShareValue.toFixed(2));
    // -------------------------- Set Post comment Changes ---------------------------------
    setPostComments(
      CurrentAggregatedData.find((item) => item.action_type === "comment")
        ?.value || "0"
    );

    const currentPostCommentReactions =
      CurrentAggregatedData.find((item) => item.action_type === "comment")
        ?.value || "0";
    const prevoiusPostCommentReactions =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "comment")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || "0";

    const percentagePostCommentValue = calculatePercentageChange(
      currentPostCommentReactions,
      prevoiusPostCommentReactions
    );

    setPostCommentChange(percentagePostCommentValue?.toFixed(2));

    // ----------- CPC -----------------

    const aggregatedValue = Number(
      aggregatedData.find((item) => item.action_type === "post_reaction")
        ?.value || 0
    );

    setCostPerReaction(
      aggregatedValue > 0 ? spendData / Number(aggregatedValue) : 0
    );
    //  ------ Previous Cost per Reaction --------------
    const previousCostPerReactions =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "post_reaction")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const PreviousspendData = previousData.find((item) => item.spend)?.spend;
    const currentCostPerReaction = spendData / aggregatedValue;
    const previousCostPerReaction =
      previousCostPerReactions === 0 || isNaN(previousCostPerReactions)
        ? 0
        : PreviousspendData / previousCostPerReactions;

    const percentageChangeCostPerReaction = calculatePercentageChange(
      currentCostPerReaction,
      previousCostPerReaction
    );

    setCostPerReactionChanges(percentageChangeCostPerReaction?.toFixed(2));
    // ------------------End----------------------

    // -------------------Cost Post Save Changes-----------------------
    setPostSaves(
      CurrentAggregatedData.find(
        (item) => item.action_type === "onsite_conversion.post_save"
      )?.value || "0"
    );
    const costperSaveValue =
      CurrentAggregatedData.find(
        (item) => item.action_type === "onsite_conversion.post_save"
      )?.value || "0";
    const previousCostPerSave =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter(
              (action) => action.action_type === "onsite_conversion.post_save"
            )
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const PreviousPostSaveSpendData = previousData.find(
      (item) => item.spend
    )?.spend;
    const currentCostPerPostSave = spendData / costperSaveValue;
    const previousCostPerPostSave =
      previousCostPerSave === 0 || isNaN(previousCostPerSave)
        ? 0
        : PreviousPostSaveSpendData / previousCostPerSave;

    const percentageChangeCostPerPostSave = calculatePercentageChange(
      currentCostPerPostSave,
      previousCostPerPostSave
    );
    setCostPerPostSaveChanges(percentageChangeCostPerPostSave?.toFixed(2));

    // -------------------Lead------------
    const aggregatedSingleLeadValue = Number(
      aggregatedData.find((item) => item.action_type === "lead")?.value || 0
    );

    const previousSingleLeadReactions =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "lead")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const percentageChangeLeadPerReaction = calculatePercentageChange(
      aggregatedSingleLeadValue,
      previousSingleLeadReactions
    );

    setLeadReactionsChange(percentageChangeLeadPerReaction?.toFixed(2));

    // ------------ Cost per Lead -----------------------
    const aggregatedLeadValue = Number(
      CostPerData.find((item) => item.action_type === "lead")?.value || "0"
    );
    const previousCostPerLeads =
      previousData
        .flatMap((campaign) =>
          campaign.cost_per_action_type
            .filter((action) => action.action_type === "lead")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const percentageChangeCostPerLead = calculatePercentageChange(
      aggregatedLeadValue,
      previousCostPerLeads
    );

    setCostPerLeadChanges(percentageChangeCostPerLead?.toFixed(2));

    // --------------------- CPM -------------------

    const percentageCPMSaveValue = calculatePercentageChange(
      CPMData,
      PreviousCPMSpendData
    );

    setCpmReactionsChange(percentageCPMSaveValue?.toFixed(2));

    // ---------------------- CTR-------------------
    const percentageCTRSaveValue = calculatePercentageChange(
      CTRData,
      PreviousCTRSpendData
    );

    setCtrReactionsChange(percentageCTRSaveValue?.toFixed(2));
    // -------------------- Cost per Share Changes -------------------

    const aggregatedPostShareValue = Number(
      aggregatedData.find((item) => item.action_type === "post")?.value || "0"
    );
    if (aggregatedPostShareValue === 0 || isNaN(aggregatedPostShareValue)) {
      setCostPerPostShare(0); // Set to 0 when there's no data
    } else {
      setCostPerPostShare(spendData / aggregatedPostShareValue);
    }
    // -------------- cost per post share changes --------------------
    const previousCostPerShare =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "post")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const currentCostPerShare =
      aggregatedPostShareValue === 0 || isNaN(aggregatedPostShareValue)
        ? 0
        : spendData / aggregatedPostShareValue;
    const previousCostPerShareChanges =
      previousCostPerShare === 0 || isNaN(previousCostPerShare)
        ? 0
        : PreviousspendData / previousCostPerShare;
    const percentageChangeCostPerShare = calculatePercentageChange(
      currentCostPerShare,
      previousCostPerShareChanges
    );

    setCostPerShareChanges(
      isNaN(percentageChangeCostPerShare)
        ? 0
        : percentageChangeCostPerShare?.toFixed(2)
    );

    // -------------------End --------------------------------

    // --------------------- Cost per comment Changes--------------------
    const aggregatedPostCommentValue = Number(
      CurrentAggregatedData.find((item) => item.action_type === "comment")
        ?.value || 0
    );
    const costPerPostComment =
      aggregatedPostCommentValue === 0 || isNaN(aggregatedPostCommentValue)
        ? 0
        : spendData / aggregatedPostCommentValue;
    setCostPerPostComment(costPerPostComment);
    const previousCostPerComment =
      previousData
        .flatMap((campaign) =>
          campaign.actions
            .filter((action) => action.action_type === "comment")
            .map((action) => action.value)
        )
        .reduce((sum, value) => sum + Number(value), 0) || 0;

    const currentCostPerComment =
      aggregatedPostCommentValue === 0 || isNaN(aggregatedPostCommentValue)
        ? 0
        : spendData / aggregatedPostCommentValue;
    const previousCostPerCommentChanges =
      previousCostPerComment === 0 || isNaN(previousCostPerComment)
        ? 0
        : PreviousspendData / previousCostPerComment;
    const percentageChangeCostPerComment = calculatePercentageChange(
      currentCostPerComment,
      previousCostPerCommentChanges
    );

    setCostPerCommentChanges(
      isNaN(percentageChangeCostPerComment)
        ? 0
        : percentageChangeCostPerComment.toFixed(2)
    );

    // --------------------- Amount spend changes -------------------------

    const percentageSpentSaveValue = calculatePercentageChange(
      spendData,
      PreviousspendData
    );

    setAmountReactionsChange(percentageSpentSaveValue?.toFixed(2));

    // -----------------------Video Engagement ----------------------

    const fetchVideoData = (start, end) => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/insights`,
          "GET",
          {
            fields:
              "video_p25_watched_actions,video_p50_watched_actions,video_p75_watched_actions,video_p100_watched_actions,video_play_actions,campaign_name",
            time_range: { since: start, until: end },
          },
          function (response) {
            if (response && response.data && response.data.length > 0) {
              resolve(response.data);
            } else {
              console.warn(
                `No data found for date range ${start} to ${end}. Returning empty data.`
              );
              resolve([]);
            }
          }
        );
      });
    };

    const currentVideoData = await fetchVideoData(startDate, endDate);
    const previousVideoData = await fetchVideoData(
      formattedStartDate,
      formattedEndDate
    );
    const previousVideoActions =
      previousVideoData.length > 0 ? previousVideoData : [{ actions: [] }];
    // -----------Video 25%--------------------
    setVideoWatch25(
      Array.isArray(currentVideoData) &&
        currentVideoData.length > 0 &&
        currentVideoData[0]?.video_p25_watched_actions?.[0]?.value
        ? Number(currentVideoData[0].video_p25_watched_actions[0].value)
        : 0
    );
    const currentVideo25Reactions =
      currentVideoData &&
      currentVideoData[0] &&
      currentVideoData[0].video_p25_watched_actions &&
      currentVideoData[0].video_p25_watched_actions[0]
        ? currentVideoData[0].video_p25_watched_actions[0].value
        : 0;
    const previousVideo25Reactions =
      previousVideoActions &&
      previousVideoActions[0] &&
      previousVideoActions[0].video_p25_watched_actions &&
      previousVideoActions[0].video_p25_watched_actions[0]
        ? previousVideoActions[0].video_p25_watched_actions[0].value
        : 0;
    const percentage25Video = calculatePercentageChange(
      currentVideo25Reactions,
      previousVideo25Reactions
    );
    setVideoWatchReaction25(percentage25Video?.toFixed(2) || 0);

    // -------------Video 50%-----------------
    setVideoWatch50(
      currentVideoData &&
        currentVideoData[0] &&
        currentVideoData[0].video_p50_watched_actions &&
        currentVideoData[0].video_p50_watched_actions[0]
        ? currentVideoData[0].video_p50_watched_actions[0].value
        : 0
    );
    const currentVideo50Reactions =
      currentVideoData &&
      currentVideoData[0] &&
      currentVideoData[0].video_p50_watched_actions &&
      currentVideoData[0].video_p50_watched_actions[0]
        ? currentVideoData[0].video_p50_watched_actions[0].value
        : 0;

    const previousVideo50Reactions =
      previousVideoActions &&
      previousVideoActions[0] &&
      previousVideoActions[0].video_p50_watched_actions &&
      previousVideoActions[0].video_p50_watched_actions[0]
        ? previousVideoActions[0].video_p50_watched_actions[0].value
        : 0;
    const percentage50Video = calculatePercentageChange(
      currentVideo50Reactions,
      previousVideo50Reactions
    );
    setVideoWatchReaction50(percentage50Video?.toFixed(2) || 0);

    // ------------------- Video 75%-------------------
    setVideoWatch75(
      currentVideoData &&
        currentVideoData[0] &&
        currentVideoData[0].video_p75_watched_actions &&
        currentVideoData[0].video_p75_watched_actions[0]
        ? currentVideoData[0].video_p75_watched_actions[0].value
        : 0
    );
    const currentVideo75Reactions =
      currentVideoData &&
      currentVideoData[0] &&
      currentVideoData[0].video_p75_watched_actions &&
      currentVideoData[0].video_p75_watched_actions[0]
        ? currentVideoData[0].video_p75_watched_actions[0].value
        : 0;

    const previousVideo75Reactions =
      previousVideoActions &&
      previousVideoActions[0] &&
      previousVideoActions[0].video_p75_watched_actions &&
      previousVideoActions[0].video_p75_watched_actions[0]
        ? previousVideoActions[0].video_p75_watched_actions[0].value
        : 0;
    const percentage75Video = calculatePercentageChange(
      currentVideo75Reactions,
      previousVideo75Reactions
    );
    setVideoWatchReaction75(percentage75Video?.toFixed(2) || 0);

    // -----------------------  Video 100% -----------------
    setVideoWatch100(
      currentVideoData &&
        currentVideoData[0] &&
        currentVideoData[0].video_p100_watched_actions &&
        currentVideoData[0].video_p100_watched_actions[0]
        ? currentVideoData[0].video_p100_watched_actions[0].value
        : 0
    );
    const currentVideo100Reactions =
      currentVideoData &&
      currentVideoData[0] &&
      currentVideoData[0].video_p100_watched_actions &&
      currentVideoData[0].video_p100_watched_actions[0]
        ? currentVideoData[0].video_p100_watched_actions[0].value
        : 0;

    const previousVideo100Reactions =
      previousVideoActions &&
      previousVideoActions[0] &&
      previousVideoActions[0].video_p100_watched_actions &&
      previousVideoActions[0].video_p100_watched_actions[0]
        ? previousVideoActions[0].video_p100_watched_actions[0].value
        : 0;
    const percentage100Video = calculatePercentageChange(
      currentVideo100Reactions,
      previousVideo100Reactions
    );
    setVideoWatchReaction100(percentage100Video?.toFixed(2) || 0);

    // --------------Video Cost 25%-----------------
    setVideoCostWatch25(
      currentVideo25Reactions > 0
        ? spendData / Number(currentVideo25Reactions)
        : 0
    );
    // --------------Video Cost 50%-----------------
    setVideoCostWatch50(
      currentVideo50Reactions > 0
        ? spendData / Number(currentVideo50Reactions)
        : 0
    );

    // --------------Video Cost 75%-----------------
    setVideoCostWatch75(
      currentVideo75Reactions > 0
        ? spendData / Number(currentVideo75Reactions)
        : 0
    );

    // --------------Video Cost 100%-----------------
    setVideoCostWatch100(
      currentVideo100Reactions > 0
        ? spendData / Number(currentVideo100Reactions)
        : 0
    );

    //  ---------------Video Cost 25 Reaction --------------
    const current25CostReaction =
      currentVideo25Reactions > 0 ? spendData / currentVideo25Reactions : 0;
    const Prevoius25CostReaction =
      previousVideo25Reactions > 0
        ? PreviousspendData / previousVideo25Reactions
        : 0;
    const percentageChangeVideo25Cost = calculatePercentageChange(
      current25CostReaction,
      Prevoius25CostReaction
    );
    setVideoCostWatchReaction25(percentageChangeVideo25Cost?.toFixed(2));

    //  ---------------Video Cost 50 Reaction --------------
    const current50CostReaction =
      currentVideo50Reactions > 0 ? spendData / currentVideo50Reactions : 0;
    const Prevoius50CostReaction =
      previousVideo50Reactions > 0
        ? PreviousspendData / previousVideo50Reactions
        : 0;
    const percentageChangeVideo50Cost = calculatePercentageChange(
      current50CostReaction,
      Prevoius50CostReaction
    );
    setVideoCostWatchReaction50(percentageChangeVideo50Cost?.toFixed(2));

    //  ---------------Video Cost 75 Reaction --------------
    const current75CostReaction =
      currentVideo75Reactions > 0 ? spendData / currentVideo75Reactions : 0;
    const Prevoius75CostReaction =
      previousVideo75Reactions > 0
        ? PreviousspendData / previousVideo75Reactions
        : 0;
    const percentageChangeVideo75Cost = calculatePercentageChange(
      current75CostReaction,
      Prevoius75CostReaction
    );
    setVideoCostWatchReaction75(percentageChangeVideo75Cost?.toFixed(2));
    //  ---------------Video Cost 100 Reaction --------------
    const current100CostReaction =
      currentVideo100Reactions > 0 ? spendData / currentVideo100Reactions : 0;
    const Prevoius100CostReaction =
      previousVideo75Reactions > 0
        ? PreviousspendData / previousVideo75Reactions
        : 0;

    const percentageChangeVideo100Cost = calculatePercentageChange(
      current100CostReaction,
      Prevoius100CostReaction
    );
    setVideoCostWatchReaction100(percentageChangeVideo100Cost?.toFixed(2));

    // ----------------------- Device Data ---------------------------
    const fetchDeviceData = (start, end) => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/insights`,
          "GET",
          {
            fields: "cpm,spend,unique_clicks,actions.action_type(lead)",
            breakdowns: "impression_device",
            time_range: { since: start, until: end },
          },
          function (response) {
            console.log("response", response);
            if (response && response.data && response.data.length > 0) {
              console.log("res===>", response);
              resolve(response.data);
            } else {
              console.warn(
                `No data found for date range ${start} to ${end}. Returning empty data.`
              );
              resolve([]);
            }
          }
        );
      });
    };

    const currentDeviceData = await fetchDeviceData(startDate, endDate);
    const previousDeviceData = await fetchDeviceData(
      formattedStartDate,
      formattedEndDate
    );

    setDeviceData(currentDeviceData);
    setPrevDeviceData(previousDeviceData);

    // ---------------------- End Device Data ------------------------

    // ------------------------- Date vise Data fetch0-------------------------

    const formatDate = (date) => date.toISOString().split("T")[0];
    const fetchDataForDate = (date) => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/insights`,
          "GET",
          {
            fields:
              "spend,reach,actions.action_type(lead),cost_per_unique_click",
            time_range: { since: date, until: date },
          },
          function (response) {
            if (response && response.data && response.data.length > 0) {
              resolve({ date, data: response.data });
            } else {
              console.warn(
                `No data found for date ${date}. Returning empty data.`
              );
              resolve({ date, data: [] });
            }
          }
        );
      });
    };

    // Fetch data for the current date range
    const fetchDataForRange = async (start, end) => {
      const dailyDataPromises = [];
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const formattedDate = formatDate(new Date(d));
        dailyDataPromises.push(fetchDataForDate(formattedDate));
      }
      const dailyDataResults = await Promise.all(dailyDataPromises);
      return dailyDataResults.map((item) => ({
        date: item.date,
        data: item.data,
      }));
    };

    // Fetch current and backdate data
    const fetchAllData = async () => {
      // Fetch current date range data
      const currentData = await fetchDataForRange(start, end);

      // Fetch previous date range data
      const previousData = await fetchDataForRange(
        previousStartDate,
        previousEndDate
      );

      // Set data to the state
      setDateWiseData(currentData);
      setPreviousDateWiseData(previousData);
    };

    // Call the function to fetch all data
    fetchAllData();

    // ----------------------------Date End -------------------------

    fetch_account_camp_details(value, startDate, endDate);
  };

  // const CampaginData = async (value,startDate,endDate) =>{
  //   console.log("ankush",value,startDate,endDate)
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);

  //   // Calculate previous dates
  //   const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

  //   const previousEndDate = new Date(start.getTime() - 24 * 60 * 60 * 1000); // Move end to the day before the current start date
  //   const previousStartDate = new Date(
  //     previousEndDate.getTime() - daysDifference * 24 * 60 * 60 * 1000
  //   );

  //   // Convert dates to the required format
  //   const formattedStartDate = previousStartDate.toISOString().split("T")[0];
  //   const formattedEndDate = previousEndDate.toISOString().split("T")[0];

  //   // ------------------------ Fetch Campagin Data -------------------------
  //   const fetchAllCampaignData = (start, end) => {
  //     return new Promise((resolve, reject) => {
  //       FB.api(
  //         `/${value}/campaigns`,
  //         "GET",
  //         {
  //           fields: "id,name", // Include 'id' and 'name'
  //           time_range: { since: start, until: end },
  //         },
  //         function (response) {
  //           if (response && response.data && response.data.length > 0) {
  //             console.log("Fetched campaign data:", response);
  //             resolve(response.data); // Each object contains 'id' and 'name'
  //           } else {
  //             console.warn(
  //               `No data found for account ${value} in date range ${start} to ${end}.`
  //             );
  //             resolve([]); // Return empty array when no data is found
  //           }
  //         }
  //       );
  //     });
  //   };

  //   const fetchCampaignInsights = (campaignId, campaignName, start, end) => {
  //     return new Promise((resolve, reject) => {
  //       FB.api(
  //         `/${campaignId}/insights`,
  //         "GET",
  //         {
  //           fields: "campaign_name,cpm,spend,unique_clicks,actions.action_type(lead)",
  //           time_range: { since: start, until: end },
  //         },
  //         function (response) {
  //           if (response && response.data) {
  //             // Attach the campaign name to the response
  //             const insightsWithCampaignName = response.data.map((insight) => ({
  //               ...insight,
  //               campaign_name: campaignName,
  //             }));
  //             console.log(`Insights for campaign ${campaignId}:`, insightsWithCampaignName);
  //             resolve(insightsWithCampaignName);
  //           } else {
  //             // Return only the campaign name when no data is found
  //             console.warn(`No insights found for campaign ${campaignId}.`);
  //             resolve([
  //               {
  //                 campaign_name: campaignName,
  //                 cpm: null,
  //                 spend: null,
  //                 unique_clicks: null,
  //                 actions: null,
  //               },
  //             ]);
  //           }
  //         }
  //       );
  //     });
  //   };

  //   const fetchAllCampaignInsights = async (start, end) => {
  //     try {
  //       const campaigns = await fetchAllCampaignData(start, end);
  //       if (campaigns.length === 0) {
  //         console.log("No campaigns to fetch insights for.");
  //         return [];
  //       }

  //       // Fetch insights for each campaign, passing both ID and name
  //       const insightsPromises = await campaigns.map((campaign) =>
  //         fetchCampaignInsights(campaign.id, campaign.name, start, end)
  //       );

  //       // Wait for all insights to be fetched
  //       const allInsights = await Promise.all(insightsPromises);

  //       // Flatten the nested array structure if needed
  //       const flatInsights = allInsights.flat();

  //       console.log("All campaign insights fetched:", flatInsights);

  //       return flatInsights;
  //     } catch (error) {
  //       console.error("Error fetching campaign insights:", error);
  //       throw error;
  //     }
  //   };

  //   // Usage
  //   const CampaignFreshData = await fetchAllCampaignInsights(startDate, endDate);
  //  console.log("CampaignFreshData,",CampaignFreshData)
  //   setCampaginData(CampaignFreshData)

  //   const prevFreshData = await fetchAllCampaignInsights(formattedStartDate, formattedEndDate);
  //   setPrevCampaginData(prevFreshData)
  // }
  const CampaignData = async (value, startDate, endDate) => {
    console.log("ankush", value, startDate, endDate);

    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate previous date range
    const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    const previousEndDate = new Date(start.getTime() - 24 * 60 * 60 * 1000);
    const previousStartDate = new Date(
      previousEndDate.getTime() - daysDifference * 24 * 60 * 60 * 1000
    );

    const formattedStartDate = previousStartDate.toISOString().split("T")[0];
    const formattedEndDate = previousEndDate.toISOString().split("T")[0];

    const fetchAllCampaignData = (start, end) => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/campaigns`,
          "GET",
          {
            fields: "id,name",
            time_range: { since: start, until: end },
          },
          (response) => {
            if (response && response.data && response.data.length > 0) {
              console.log("Fetched campaign data:", response);
              resolve(response.data);
            } else {
              console.warn(
                `No data found for account ${value} in date range ${start} to ${end}.`
              );
              resolve([]); // Return empty array when no data is found
            }
          }
        );
      });
    };

    const fetchCampaignInsights = (campaignId, campaignName, start, end) => {
      return new Promise((resolve) => {
        FB.api(
          `/${campaignId}/insights`,
          "GET",
          {
            fields:
              "campaign_name,cpm,spend,unique_clicks,actions.action_type(lead)",
            time_range: { since: start, until: end },
          },
          (response) => {
            if (response && response.data) {
              const insightsWithCampaignName = response.data.map((insight) => ({
                ...insight,
                campaign_name: campaignName,
              }));

              resolve(insightsWithCampaignName);
            } else {
              resolve([
                {
                  campaign_name: campaignName,
                  id: campaignName?.id,
                  cpm: null,
                  spend: null,
                  unique_clicks: null,
                  actions: null,
                },
              ]);
            }
          }
        );
      });
    };

    const fetchAllCampaignInsights = async (start, end) => {
      const campaigns = await fetchAllCampaignData(start, end);
      if (campaigns.length === 0) {
        return [];
      }

      // Ensure correct usage of async/await with map
      const insightsPromises = campaigns.map((campaign) =>
        fetchCampaignInsights(campaign.id, campaign.name, start, end)
      );

      const allInsights = await Promise.all(insightsPromises);
      return allInsights.flat();
    };

    try {
      const currentCampaignData = await fetchAllCampaignInsights(
        startDate,
        endDate
      );
      console.log("Current Campaign Data:", currentCampaignData);
      setCampaginData(currentCampaignData);

      const previousCampaignData = await fetchAllCampaignInsights(
        formattedStartDate,
        formattedEndDate
      );
      console.log("Previous Campaign Data:", previousCampaignData);
      setPrevCampaginData(previousCampaignData);
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };

  const fetchLongTerm = async (value, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const fetchDataa = (start, end) => {
      const timeRanges = [
        { label: "Last 60 Days", ...getTimeRange(60) },
        { label: "Last 30 Days", ...getTimeRange(30) },
        { label: "Last 14 Days", ...getTimeRange(14) },
        { label: "Last 7 Days", ...getTimeRange(7) },
        { label: "Last 3 Days", ...getTimeRange(3) },
        { label: "Yesterday", ...getTimeRange(1) },
        {
          label: "Today",
          since: formatDate(new Date()),
          until: formatDate(new Date()),
        },
      ];

      return new Promise((resolve, reject) => {
        FB.api(
          `/${value}/insights`,
          "GET",
          {
            fields: `ctr,cpm,actions.action_type(post_save,comment,post,post_reaction),cost_per_action_type.action_type(onsite_conversion.post_save,lead),spend`,
            time_ranges: timeRanges.map((range) => ({
              since: range.since || formatDate(start),
              until: range.until || formatDate(end),
            })),
          },
          function (response) {
            if (response && response.data && response.data.length > 0) {
              const formattedData = response.data.map((data, index) => ({
                label: timeRanges[index].label,
                range: data,
              }));

              const tableData = timeRanges.map((range, index) => {
                const data =
                  formattedData.find((d) => d.label === range.label)?.range ||
                  {};
                return {
                  id: index,
                  period: range.label,
                  amountSpent: "₹ " + formatNumber(data.spend || 0),
                  cpm: "₹ " + formatNumber(data.cpm || 0),
                  uniqueCTR: formatNumber(data.ctr || 0) + "%",
                  leads:
                    data.actions?.find((a) => a.action_type === "lead")
                      ?.value || "0",
                  costPerLead: `₹ ${formatNumber(
                    data.cost_per_action_type?.find(
                      (a) => a.action_type === "lead"
                    )?.value || "0"
                  )}`,
                };
              });

              const CpmData = tableData.map((item) => ({
                label: item.period,
                CPM: formatNumber(parseFloat(item.cpm.replace("₹ ", ""))),
              }));

              const CtrData = tableData.map((item) => ({
                label: item.period,
                CTR: item.uniqueCTR,
              }));

              const transformedData = [];
              tableData.forEach((item) => {
                transformedData.push({
                  date: item.period,
                  value: parseFloat(item.costPerLead.replace("₹ ", "")),
                  category: "Cost Per Lead",
                });
                transformedData.push({
                  date: item.period,
                  value: parseFloat(item.cpm.replace("₹ ", "")),
                  category: "CPM",
                });
                transformedData.push({
                  date: item.period,
                  value: parseFloat(item.amountSpent.replace("₹ ", "")),
                  category: "Cost Per Unique Click",
                });
                transformedData.push({
                  date: item.period,
                  value: parseFloat(item.uniqueCTR.replace("%", "")),
                  category: "Unique CTR",
                });
              });

              setData(tableData); // Uncomment if setData is defined
              setCpmChartData(CpmData);
              setCtrChartData(CtrData);
              setGraphData(transformedData);
              resolve(tableData);
            } else {
              // Default table data with zero values if no data was returned
              const defaultTableData = timeRanges.map((range, index) => ({
                id: index,
                period: range.label,
                amountSpent: "₹ 0",
                cpm: "₹ 0",
                uniqueCTR: "0%",
                leads: "0",
                costPerLead: "₹ 0",
              }));

              setData(defaultTableData);
              setCpmChartData(
                defaultTableData.map((item) => ({
                  label: item.period,
                  CPM: 0,
                }))
              );
              setCtrChartData(
                defaultTableData.map((item) => ({
                  label: item.period,
                  CTR: "0%",
                }))
              );
              setGraphData([]);
              resolve(defaultTableData);
            }
          }
        );
      });
    };

    return await fetchDataa(start, end);
  };

  const fetch_age_details = async (value, startDate, endDate) => {
    FB.api(
      `/${value}/insights`,
      "GET",
      {
        // access_token: accesstoken,
        fields: "clicks,actions",
        breakdowns: "age",
        time_range: { since: startDate, until: endDate },
      },
      function (response) {
        if (response && response.data && response.data.length > 0) {
          const formattedData = response.data.map((item) => {
            const leadAction = item.actions
              ? item.actions.find((action) => action.action_type === "lead")
              : null;
            const leadValue = leadAction ? parseInt(leadAction.value) : 0;
            return {
              leads: leadValue,
              age: item.age,
            };
          });

          setAgeData(formattedData);
          setAdDetailsUi(true);
        }
      }
    );
  };

  const fetch_account_camp_details = async (value, startDate, endDate) => {
    FB.api(
      `/${value}/insights`,
      "GET",
      {
        // access_token: accesstoken,
        fields: "spend,actions",
        time_range: { since: startDate, until: endDate },
        time_increment: 1, // Break down the data by day
      },
      function (response) {
        if (response && response.data && response.data.length > 0) {
          const formattedData = response.data.map((insight) => {
            const spend = insight.spend || 0;

            // Find the lead action in the actions array
            const leadAction = insight.actions
              ? insight.actions.find((action) => action.action_type === "lead")
              : null;
            const leadValue = leadAction ? leadAction.value : 0;

            return {
              clicks: spend,
              link_clicks: leadValue,
              age: insight.date_start, // Add the date for daily breakdown
            };
          });

          // Store the formatted data in state
          setFullReportSpend(formattedData);
          setAdDetailsUi(true);
          setFullReport(true);
        } else {
          notification["error"]({
            message: "No Data Found",
          });
        }
      }
    );
  };

  const fetchLeadsByGender = async (value, startDate, endDate) => {
    FB.api(
      `/${value}/insights`,
      "GET",
      {
        // access_token: accesstoken,
        fields: "actions",
        breakdowns: "gender",
        time_range: { since: startDate, until: endDate },
      },
      function (response) {
        if (response && response.data && response.data.length > 0) {
          const formattedGenderData = response.data.map((item) => {
            const leadAction = item.actions
              ? item.actions.find((action) => action.action_type === "lead")
              : null;
            const leadValue = leadAction ? parseInt(leadAction.value) : 0;

            return {
              gender: item.gender,
              leads: leadValue,
            };
          });
          setGenderData(formattedGenderData);
        }
      }
    );
  };

  const pressedBack = () => {
    setAdDetailsUi(false);
    setFullReport(false);
    history.push("/leads-facebook-grader");
  };

  // Facebook logout function
  const logout = () => {
    window.FB.logout(function (response) {
      if (response.status !== "connected") {
        setlogin(false);
        localStorage.removeItem("isLoggedIn");
        setAdDetailsUi(false);
        set_owner_name(null);
        setAdAccounts([]);
        setAdCampaigns([]);
        notification.success({
          message: "Account Unlink Successfully",
        });
        setTimeout(() => {
          if (window.FB) {
            window.FB.XFBML.parse();
          }
        }, 0);
      } else {
        notification.error({
          message: "Failed to log out from Facebook",
        });
      }
    });
  };

  function formatNumber(value) {
    const number = parseFloat(value).toFixed(2);
    const parts = number.toString().split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];
    let formattedNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart) {
      formattedNumber += "." + decimalPart;
    }
    return formattedNumber;
  }

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    // setShowDatePicker(false);
  };

  const formatDateRange = (start, end) => {
    if (!start || !end) return "";

    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, "0"); // Ensure 2 digits for the day
      const month = date.toLocaleString("en-IN", { month: "short" }); // Get abbreviated month
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  const formatDateInFB = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getBase64ImageFromUrl = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  };

  const downloadReport = async () => {
    const input = reportRef.current;

    // Get the actual full height of the container (including non-visible parts)
    const originalHeight = input.style.height;
    const originalWidth = input.style.width;
    const originalOverflow = input.style.overflow;

    // Set styles to ensure the entire content is captured
    input.style.height = `${input.scrollHeight}px`;
    input.style.width = "auto";
    input.style.overflow = "visible";

    // Convert the company logo to base64
    const base64Logo = await getBase64ImageFromUrl(companyLogo);

    const img = new Image();
    img.src = base64Logo;

    img.onload = () => {
      const logoAspectRatio = img.width / img.height;

      html2canvas(input, {
        scrollY: -window.scrollY, // Capture from the top
        scale: 2, // Adjust scale for better quality
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        const logoWidth = 30;
        const logoHeight = logoWidth / logoAspectRatio;

        const text = "© 2024 Marketing With HMA. All Rights Reserved.";
        pdf.setFontSize(10);

        const startXPosition = 10; // Align footer to the left
        const footerYPosition = pageHeight - 15; // Footer position
        const headerYPosition = 15; // Header position

        // Function to add header with logo and line
        const addHeader = () => {
          pdf.addImage(
            base64Logo,
            "PNG",
            (imgWidth - logoWidth) / 2,
            headerYPosition - logoHeight,
            logoWidth,
            logoHeight
          ); // Center logo
          // pdf.setDrawColor(128, 128, 128);
          // pdf.setLineWidth(0.5);
          // pdf.line(7, headerYPosition + 10, 200, headerYPosition + 10);  // Gray line below the header
        };

        // Function to add footer on the last page only
        const addFooter = () => {
          pdf.setDrawColor(128, 128, 128);
          pdf.setLineWidth(0.5);
          pdf.line(
            7,
            footerYPosition - logoHeight - 7 - 5,
            200,
            footerYPosition - logoHeight - 7 - 5
          ); // Gray line above footer
          pdf.addImage(
            base64Logo,
            "PNG",
            startXPosition,
            footerYPosition - logoHeight + 3,
            logoWidth,
            logoHeight
          ); // Footer logo
          pdf.setTextColor(128, 128, 128);
          pdf.text(text, startXPosition + logoWidth + 5, footerYPosition); // Footer text
        };

        // Add first page content
        pdf.addImage(
          imgData,
          "PNG",
          0,
          position + headerYPosition + 15,
          imgWidth,
          imgHeight
        ); // Adjust content position below header
        addHeader();
        heightLeft -= pageHeight;

        // Add additional pages if needed
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "PNG",
            0,
            position + headerYPosition + 15,
            imgWidth,
            imgHeight
          ); // Adjust content position below header
          heightLeft -= pageHeight;
        }

        // Add footer only on the last page
        addFooter();

        // Restore the original styles
        input.style.height = originalHeight;
        input.style.width = originalWidth;
        input.style.overflow = originalOverflow;

        // Save the PDF
        pdf.save("report_with_footer_on_last_page.pdf");
      });
    };
  };

  const columns = [
    {
      dataField: "period",
      text: "Past Period",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    {
      dataField: "amountSpent",
      text: "Amount Spent",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    {
      dataField: "cpm",
      text: "CPM",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    {
      dataField: "uniqueCTR",
      text: "Unique CTR",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    {
      dataField: "leads",
      text: "Leads (All)",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
    {
      dataField: "costPerLead",
      text: "Cost Per Lead (All)",
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
      },
    },
  ];

  return (
    <div ref={reportRef}>
      <div className="facebook-container">
        {login ? (
          // top header date and button
          <div className="facebook-header">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div className="facebook-header-row">
                <img
                  src={backIcon}
                  alt="back"
                  onClick={pressedBack}
                  style={{ cursor: "pointer" }}
                />
                <div
                  className="date-picker-container"
                  style={{ color: "black", fontWeight: "600" }}
                >
                  {/* This div controls the opening/closing of the date picker */}
                  <div
                    onClick={() => setShowDatePicker((prev) => !prev)}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                  >
                    {formatDateRange(state[0].startDate, state[0].endDate)}{" "}
                    {showDatePicker ? "▲" : "▼"}
                  </div>

                  <div>
                    {showDatePicker && (
                      <div
                        className="date-picker-dropdown"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DateRangePicker
                          onChange={(ranges) => {
                            handleSelect(ranges);
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={state}
                          direction="horizontal"
                          locale={enUS}
                        />

                        {/* Add a submit button after the date selection */}
                        {state[0].startDate && state[0].endDate && (
                          <div
                            className="button-container"
                            style={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              onClick={() => {
                                setShowDatePicker(false); // Close the picker without applying
                              }}
                              className="ant-btn ant-btn-default cancel-button"
                              style={{ marginBottom: "10px" }}
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => {
                                setShowDatePicker(false); // Close the picker
                                start_Refetching_report("Report");
                              }}
                              className="ant-btn ant-btn-default primary-button"
                              style={{ marginBottom: "10px" }}
                            >
                              Apply
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {adsDetailUi && !loading ? (
                <button
                  className="ant-btn ant-btn-default primary-button"
                  onClick={downloadReport}
                >
                  Download
                </button>
              ) : (
                <></>
              )}
            </div>

            {!loading ? (
              <button
                onClick={logout}
                className="ant-btn ant-btn-danger"
                style={{ marginLeft: "10px" }}
              >
                Account Unlink
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        {loading && adsDetailUi ? (
          // loading panel
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="200px"
              width="200px"
              viewBox="0 0 200 200"
              className="pencil"
            >
              <defs>
                <clipPath id="pencil-eraser">
                  <rect height="30" width="30" ry="5" rx="5"></rect>
                </clipPath>
              </defs>
              <circle
                transform="rotate(-113,100,100)"
                strokeLinecap="round"
                strokeDashoffset="439.82"
                strokeDasharray="439.82 439.82"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                r="70"
                className="pencil__stroke"
              ></circle>
              <g transform="translate(100,100)" className="pencil__rotate">
                <g fill="none">
                  <circle
                    transform="rotate(-90)"
                    strokeDashoffset="402"
                    strokeDasharray="402.12 402.12"
                    strokeWidth="30"
                    stroke="hsl(223,90%,50%)"
                    r="64"
                    className="pencil__body1"
                  ></circle>
                  <circle
                    transform="rotate(-90)"
                    strokeDashoffset="465"
                    strokeDasharray="464.96 464.96"
                    strokeWidth="10"
                    stroke="hsl(223,90%,60%)"
                    r="74"
                    className="pencil__body2"
                  ></circle>
                  <circle
                    transform="rotate(-90)"
                    strokeDashoffset="339"
                    strokeDasharray="339.29 339.29"
                    strokeWidth="10"
                    stroke="hsl(223,90%,40%)"
                    r="54"
                    className="pencil__body3"
                  ></circle>
                </g>
                <g
                  transform="rotate(-90) translate(49,0)"
                  className="pencil__eraser"
                >
                  <g className="pencil__eraser-skew">
                    <rect
                      height="30"
                      width="30"
                      ry="5"
                      rx="5"
                      fill="hsl(223,90%,70%)"
                    ></rect>
                    <rect
                      clipPath="url(#pencil-eraser)"
                      height="30"
                      width="5"
                      fill="hsl(223,90%,60%)"
                    ></rect>
                    <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
                    <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
                    <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
                    <rect
                      height="2"
                      width="30"
                      y="6"
                      fill="hsla(223,10%,10%,0.2)"
                    ></rect>
                    <rect
                      height="2"
                      width="30"
                      y="13"
                      fill="hsla(223,10%,10%,0.2)"
                    ></rect>
                  </g>
                </g>
                <g
                  transform="rotate(-90) translate(49,-30)"
                  className="pencil__point"
                >
                  <polygon
                    points="15 0,30 30,0 30"
                    fill="hsl(33,90%,70%)"
                  ></polygon>
                  <polygon
                    points="15 0,6 30,0 30"
                    fill="hsl(33,90%,50%)"
                  ></polygon>
                  <polygon
                    points="15 0,20 10,10 10"
                    fill="hsl(223,10%,10%)"
                  ></polygon>
                </g>
              </g>
            </svg>

            <p
              style={{
                color: "#4BB543",
                fontWeight: "700",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              😊 Please wait! While we are drawing the charts for you 😊
            </p>
          </div>
        ) : (
          <Row
            align="top"
            justify="top"
            style={{
              width: "100%",
              paddingLeft: "30px",
              paddingRight: "30px",
              marginBottom: "10%",
            }}
          >
            {adsDetailUi ? (
              // main page component
              <Col span={24} justify="center" align="center">
                {/*  {meta ads lead gen section } */}
                <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded">
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#1a1a2e",
                      }}
                    >
                      <Title>
                        Meta Ads Lead Gen Performance |{" "}
                        {formatDateRange(state[0].startDate, state[0].endDate)}
                      </Title>
                    </h5>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "10px",
                        color: "#B0B0B0",
                        marginTop: "10px",
                      }}
                    >
                      CREATED BY{" "}
                      <span style={{ fontWeight: "bold" }}>Boost With HMA</span>
                    </p>
                  </div>
                </div>

                {/* second 5 box section  */}
                <div className="facebook-background-container">
                  {/* first box  */}
                  <ComponentCard
                    heading="Amount Spent"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(insightsData.spend)}`}
                    numPercent={`${Math.abs(amountReactionsChange).toFixed(
                      2
                    )}%`}
                    rate={amountReactionsChange}
                  />
                  {/* second box  */}
                  <ComponentCard
                    heading="Cost Per Lead"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(avgCpl)}`}
                    numPercent={`${Math.abs(CostPerLeadChanges).toFixed(2)}%`}
                    rate={CostPerLeadChanges}
                  />
                  {/* third box  */}
                  <ComponentCard
                    heading="Leads"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`${formatNumber(leads).replace(".00", "")}`}
                    numPercent={`${Math.abs(leadReactionsChange).toFixed(2)}%`}
                    rate={leadReactionsChange}
                  />
                  {/* fourth box  */}
                  <ComponentCard
                    heading="CPM"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(insightsData.cpm)}`}
                    numPercent={`${Math.abs(cpmReactionsChange).toFixed(2)}%`}
                    rate={cpmReactionsChange}
                  />
                  {/* fifth box  */}
                  <ComponentCard
                    heading="CTR"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`${ctrPercentage} %`}
                    numPercent={`${Math.abs(ctrReactionsChange).toFixed(2)}%`}
                    rate={ctrReactionsChange}
                  />
                </div>

                {/* third section chart */}
                <div
                  className="facebook-value-card-container hover-container"
                  style={{
                    width: "100%",
                    padding: "30px 30px",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    className="facebook-logo-container"
                    style={{ marginBottom: "20px" }}
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={logo}
                      alt="fb"
                    />
                    <p
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Leads vs Spend
                    </p>
                  </div>
                  <DualLineChart
                    data={fullReportSpend}
                    type={"Facebook"}
                    cat1={"Amount Spent"}
                    cat2={"Leads"}
                    Xval={"Days"}
                    Yval={"Spend and Leads"}
                  />
                </div>
                {/* table  */}
                <div style={{ marginBottom: "40px", width: "100p%" }}>
                  <div className="card">
                    <div className="card-body">
                      <TableWithTrends
                        data={dateWiseData}
                        prvdata={prevoiusDateWiseData}
                        date={formatDateRange(
                          state[0].startDate,
                          state[0].endDate
                        )}
                      />
                    </div>
                  </div>
                </div>

                {/* lead funnel inside*/}
                <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded">
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#1a1a2e",
                      }}
                    >
                      <Title>
                        Lead Funnel & Insights |{" "}
                        {formatDateRange(state[0].startDate, state[0].endDate)}
                      </Title>
                    </h5>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "10px",
                        color: "#B0B0B0",
                        marginTop: "10px",
                      }}
                    >
                      CREATED BY{" "}
                      <span style={{ fontWeight: "bold" }}>Boost With HMA</span>
                    </p>
                  </div>
                </div>
                {/* four card  */}
                <div className="facebook-background-container">
                  {/* first box  */}
                  <ComponentCard
                    heading="Impressions"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={formatNumber(insightsData.impressions).replace(
                      ".00",
                      ""
                    )}
                    numPercent={`${Math.abs(ImpressionReactionsChange).toFixed(
                      2
                    )}%`}
                    rate={ImpressionReactionsChange}
                  />
                  {/* second box */}
                  <ComponentCard
                    heading="Reach"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={formatNumber(insightsData.reach).replace(".00", "")}
                    numPercent={`${Math.abs(reachReactionsChange).toFixed(2)}%`}
                    rate={reachReactionsChange}
                  />
                  {/* third box  */}
                  <ComponentCard
                    heading="Clicks"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={formatNumber(insightsData.clicks).replace(
                      ".00",
                      ""
                    )}
                    numPercent={`${Math.abs(clickReactionsChange).toFixed(2)}%`}
                    rate={clickReactionsChange}
                  />
                  {/* four box  */}
                  <ComponentCard
                    heading="Link Clicks"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={formatNumber(
                      insightsData.inline_link_clicks
                    ).replace(".00", "")}
                    numPercent={`${Math.abs(linkClickReactionsChange).toFixed(
                      2
                    )}%`}
                    rate={linkClickReactionsChange}
                  />
                </div>
                {AccountValue ? (
                  <div>
                    <div style={{ marginBottom: "40px" }}>
                      <div className="card">
                        <div className="card-body">
                          <CampaginTable
                            data={campaginData}
                            prvdata={prevCampaginData}
                            date={formatDateRange(
                              state[0].startDate,
                              state[0].endDate
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* Second Chart */}
                <div className="facebook-background-container-charts">
                  <div
                    className="facebook-value-card-container hover-container"
                    style={{ width: "50%" }}
                  >
                    <div className="facebook-logo-container">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={logo}
                        alt="fb"
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Leads (All) | Age
                      </p>
                    </div>
                    <LeadsAgeBarChart data={age_data} />
                  </div>

                  <div
                    className="facebook-value-card-container hover-container"
                    style={{ width: "50%" }}
                  >
                    <div className="facebook-logo-container">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={logo}
                        alt="fb"
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Leads by Gender
                      </p>
                    </div>
                    <EngagementPieChart data={genderData} />
                  </div>
                </div>
                {/* tables  */}
                <div>
                  <div style={{ marginBottom: "40px" }}>
                    <div className="card">
                      <div className="card-body">
                        <DeviceTable
                          data={deviceData}
                          prvdata={prevDeviceData}
                          date={formatDateRange(
                            state[0].startDate,
                            state[0].endDate
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* post engagment  */}
                <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded">
                  <div className="card-body">
                    <h5
                      className="card-title"
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#1a1a2e",
                      }}
                    >
                      <Title>
                        Post Engagement |{" "}
                        {formatDateRange(state[0].startDate, state[0].endDate)}
                      </Title>
                    </h5>
                    <p
                      className="card-text"
                      style={{
                        fontSize: "10px",
                        color: "#B0B0B0",
                        marginTop: "10px",
                      }}
                    >
                      CREATED BY{" "}
                      <span style={{ fontWeight: "bold" }}>Boost With HMA</span>
                    </p>
                  </div>
                </div>
                {/* 8 boxes  */}
                <div className="facebook-background-container">
                  {/* first  */}
                  <ComponentCard
                    heading="Post Reactions"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={postReactions}
                    numPercent={`${Math.abs(postReactionsChange).toFixed(2)}%`}
                    rate={postReactionsChange}
                  />
                  {/* second  */}
                  <ComponentCard
                    heading="Post Save"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={postSaves}
                    numPercent={`${Math.abs(postSaveChange).toFixed(2)}%`}
                    rate={postSaveChange}
                  />
                  {/* third / */}
                  <ComponentCard
                    heading="Post Shares"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={postLikes}
                    numPercent={`${Math.abs(postShareChange).toFixed(2)}%`}
                    rate={postShareChange}
                  />
                  {/* fourth  */}
                  <ComponentCard
                    heading="Post Comments"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={postComments}
                    numPercent={`${Math.abs(postCommentChange).toFixed(2)}%`}
                    rate={postCommentChange}
                  />
                </div>

                <div
                  className="facebook-background-container"
                  style={{ marginTop: "-50px" }}
                >
                  {/* fifth  */}
                  <ComponentCard
                    heading="Cost per Reaction"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(costPerReaction)}`}
                    numPercent={`${Math.abs(CostPerReactionChanges).toFixed(
                      2
                    )}%`}
                    rate={CostPerReactionChanges}
                  />
                  {/* six  */}
                  <ComponentCard
                    heading="Cost per Post Save"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(costPerPostSave)}`}
                    numPercent={`${Math.abs(CostPerPostSaveChanges).toFixed(
                      2
                    )}%`}
                    rate={CostPerPostSaveChanges}
                  />

                  {/* seven  */}
                  <ComponentCard
                    heading="Cost per Post Share"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(costPerPostShare)}`}
                    numPercent={`${Math.abs(CostPerPostShareChanges).toFixed(
                      2
                    )}%`}
                    rate={CostPerPostShareChanges}
                  />
                  {/* eight  */}
                  <ComponentCard
                    heading="Cost per Post Comment"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(costPerPostComment)}`}
                    numPercent={`${Math.abs(CostPerPostCommentChanges).toFixed(
                      2
                    )}%`}
                    rate={CostPerPostCommentChanges}
                  />
                  {/* -------------------------New Cards ------------------------ */}
                </div>

                {/* video engagment  */}
                <div>
                  <div className="card text-center shadow-sm p-3 mb-5 bg-white rounded">
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "#1a1a2e",
                        }}
                      >
                        <Title>
                          Video Ads Engagement |{" "}
                          {formatDateRange(
                            state[0].startDate,
                            state[0].endDate
                          )}
                        </Title>
                      </h5>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "10px",
                          color: "#B0B0B0",
                          marginTop: "10px",
                        }}
                      >
                        CREATED BY{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Boost With HMA
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* 8 card  */}
                <div className="facebook-background-container">
                  {/* first  */}
                  <ComponentCard
                    heading="Video Watches at 25%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={videoWatch25}
                    numPercent={`${Math.abs(videoWatchReaction25).toFixed(2)}%`}
                    rate={videoWatchReaction25}
                  />
                  {/* second  */}
                  <ComponentCard
                    heading="Video Watches at 50%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={videoWatch50}
                    numPercent={`${Math.abs(videoWatchReaction50).toFixed(2)}%`}
                    rate={videoWatchReaction50}
                  />
                  {/* third  */}
                  <ComponentCard
                    heading="Video Watches at 75%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={videoWatch75}
                    numPercent={`${Math.abs(videoWatchReaction75).toFixed(2)}%`}
                    rate={videoWatchReaction75}
                  />
                  {/* four  */}
                  <ComponentCard
                    heading="Video Watches at 100%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={videoWatch100}
                    numPercent={`${Math.abs(videoWatchReaction100).toFixed(
                      2
                    )}%`}
                    rate={videoWatchReaction100}
                  />
                </div>
                <div
                  className="facebook-background-container"
                  style={{ marginTop: "-50px" }}
                >
                  {/* fifth  */}
                  <ComponentCard
                    heading="CPVW 25%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(videoCostWatch25)}`}
                    numPercent={`${Math.abs(videoCostWatchReaction25).toFixed(
                      2
                    )}%`}
                    rate={videoCostWatchReaction25}
                  />
                  {/* six  */}
                  <ComponentCard
                    heading="CPVW 50%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(videoCostWatch50)}`}
                    numPercent={`${Math.abs(videoCostWatchReaction50).toFixed(
                      2
                    )}%`}
                    rate={videoCostWatchReaction50}
                  />
                  {/* seven  */}
                  <ComponentCard
                    heading="CPVW 75%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(videoCostWatch75)}`}
                    numPercent={`${Math.abs(videoCostWatchReaction75).toFixed(
                      2
                    )}%`}
                    rate={videoCostWatchReaction75}
                  />
                  {/* eight  */}
                  <ComponentCard
                    heading="CPVW 100%"
                    DateRange={formatDateRange(
                      state[0].startDate,
                      state[0].endDate
                    )}
                    number={`₹ ${formatNumber(videoCostWatch100)}`}
                    numPercent={`${Math.abs(videoCostWatchReaction100).toFixed(
                      2
                    )}%`}
                    rate={videoCostWatchReaction100}
                  />
                </div>

                {/* long term  */}
                <div>
                  <div className="card text-center shadow-sm p-3 mb-2 bg-white rounded">
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "#1a1a2e",
                        }}
                      >
                        <Title>Long-term analysis for Last 60 Days</Title>
                      </h5>
                      <p
                        className="card-text"
                        style={{
                          fontSize: "10px",
                          color: "#B0B0B0",
                          marginTop: "10px",
                        }}
                      >
                        CREATED BY{" "}
                        <span style={{ fontWeight: "bold" }}>
                          Boost With HMA
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* table  */}
                <div className="mt-4" style={{ marginBottom: "40px" }}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        <img
                          style={{ width: "24px", height: "24px" }}
                          src={logo}
                          alt="fb"
                        />{" "}
                        More compact way to see key metrics for Last 60 Days
                      </h5>
                      <p className="text-muted">Last 60 days</p>
                      <BootstrapTable
                        keyField="id"
                        data={data}
                        columns={columns}
                        bordered={false}
                        striped
                        hover
                        condensed
                        classes="custom-table"
                        noDataIndication={() => <div>No data available</div>}
                      />
                    </div>
                  </div>
                </div>

                {/* -------------------------Charts---------------------- */}
                <div
                  className="facebook-background-container-charts"
                  style={{ marginBottom: "50px" }}
                >
                  <div
                    className="facebook-value-card-container hover-container"
                    style={{ width: "81%" }}
                  >
                    <div className="facebook-logo-container">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={logo}
                        alt="fb"
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          width: "100%",
                          color: "#000000 !important",
                        }}
                      >
                        CPM for Last 60 days
                      </p>
                    </div>
                    <CPMChart cpmChart={cpmChart} value={"CPM"} />
                  </div>

                  <div
                    className="facebook-value-card-container hover-container"
                    style={{ width: "81%" }}
                  >
                    <div className="facebook-logo-container">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={logo}
                        alt="fb"
                      />
                      <p
                        style={{
                          fontWeight: "600",
                          textAlign: "center",
                          width: "100%",
                          color: "#000000 !important",
                        }}
                      >
                        Unique CTR for Last 60 days
                      </p>
                    </div>
                    <CPMChart cpmChart={ctrChart} value={"CTR"} />
                  </div>
                </div>

                {/* ------------------- Graph ------------------------- */}
                <div className="mt-4" style={{ marginBottom: "40px" }}>
                  <div className="card text-center shadow-sm p-3 mb-2 bg-white rounded">
                    <GraphComponent data={graphData} />
                  </div>
                </div>
              </Col>
            ) : !login ? (
              // page when facebook not login
              <Col span={24} justify="center" align="middle">
                <Title style={{ marginBottom: 30, marginTop: "50px" }}>
                  Login With Facebook to check your AddScore
                </Title>
                <div style={{ marginBottom: "80px" }}></div>
                <div
                  className="fb-login-button"
                  data-width=""
                  data-size="large"
                  data-button-type="continue_with"
                  data-layout="default"
                  data-auto-logout-link="false"
                  data-use-continue-as="false"
                  data-scope="ads_read"
                  data-config-id="1720609635360111"
                  onClick={checkLoginState}
                />
                <div id="status"></div>
              </Col>
            ) : (
              // input select option page
              <Col span={24} justify="center" align="middle">
                <div className="facebook-grader-form">
                  <Title style={{ marginBottom: "30px" }}>
                    Check Your Ad Report in One Click {owner_name}
                  </Title>
                  <h2>Select your Ad Account*</h2>
                  <Select
                    placeholder="Select Ad Account"
                    style={{
                      width: "100%",
                      padding: "10px 0",
                      textAlign: "start",
                    }}
                    onChange={selectedAdAccount}
                  >
                    {adAccounts.map((accounts) => (
                      <Option key={accounts.id} value={accounts.id}>
                        {accounts.name} ({accounts.id})
                      </Option>
                    ))}
                  </Select>
                  <p style={{ fontSize: "12px", paddingTop: "20px" }}>
                    This Campaigns from your account which you select
                    Previously**
                  </p>

                  <h2>Select your Ad Campaigns*</h2>

                  <Select
                    placeholder="Select Campaign"
                    style={{
                      width: "100%",
                      padding: "10px 0",
                      textAlign: "start",
                    }}
                    onChange={(value) => setAdCamp(value)}
                  >
                    {adCampaigns.map((campaigns) => (
                      <Option key={campaigns.id} value={campaigns.id}>
                        {campaigns.name}
                      </Option>
                    ))}
                  </Select>
                  {
                    !loading  ? (
                      <button
                    className="ant-btn ant-btn-default primary-button"
                    style={{ marginTop: "20px" }}
                    onClick={() => start_fetching_report("Report")}
                  >
                    Get Report
                  </button>
                    ) : (
                      <></>
                    )
                  }
                  
                  {!fullReport && !loading ? (
                    <button
                      className="ant-btn ant-btn-default primary-button"
                      style={{ margin: "15px" }}
                      onClick={() => start_fetching_report("Account")}
                    >
                      Account Report
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  );
};

export default FacebookEcom;

const ComponentCard = ({ heading, DateRange, number, numPercent, rate }) => {
  return (
    <div
      className="facebook-plane-column"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div className="facebook-value-card-container hover-container">
        <img
          style={{
            width: "35px",
            height: "40px",
            marginBottom: "5px",
          }}
          src={logo}
          alt="fb"
        />
        <span style={{ fontWeight: "600", fontSize: "16px" }}>
          {heading}{" "}
          <Tooltip text={heading}>
            <IoIosInformationCircleOutline size={16} />
          </Tooltip>
        </span>

        <span
          style={{
            fontWeight: "300",
            marginTop: "2px",
            fontSize: "14px",
            textAlign: "center",
            width: "100%",
          }}
        >
          {DateRange}
        </span>

        <div
          className="d-flex gap-2 justify-content-between w-100 align-items-center"
          style={{
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <h2
            style={{
              color: "black",
              fontSize: "20px",
              paddingTop: "10px",
              fontWeight: "600",
            }}
          >
            {number}
          </h2>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "3px 10px",
              borderRadius: "10px",
              backgroundColor: rate > 0 ? "#F0FAF4" : "#FEF1F1",
              color: rate > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {rate > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span
              style={{
                fontWeight: "600",
                fontSize: "11px",
              }}
            >
              {numPercent}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
