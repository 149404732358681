import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { Link, useParams } from 'react-router-dom';
import { Button, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import { Database } from '../../firebase';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

const ViewCollection = props => {

    const authUser = useContext(UserContext);
    const { id: collectionId } = useParams();

    const [collectionData, setCollectionData] = useState(null);

    const columns = [
        {
            dataField: 'adText',
            text: 'Ads',
        }
    ];

    useEffect(() => {
        document.title = `AI Ads Collection ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {
        (async function () {
            await getUserCollectionItems();
        })();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserCollectionItems = async () => {
        var { uid } = authUser
        if (uid) {
            const docRef = doc(Database, "aiAdsCollection", collectionId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = await docSnap.data();
                data.data = JSON.parse(data.data);
                // console.log("data:", data);
                setCollectionData(data);
            } else {
                console.log("No such document!");
            }
        }
    }

    return (
        <div className="content-wrapper">
            <div className="collection-header">
                <Button title='Back' type="link" icon={<LeftOutlined style={{ verticalAlign: 'middle', marginRight: 5, }} />}>
                    <Link style={{ verticalAlign: 'middle', fontWeight: 600 }} to='/ai-ads-collection'>Go Back</Link>
                </Button>
                {
                    collectionData ? <Row className="mb-4">
                        <h2 className="explore-title">{collectionData.collectionName} ({collectionData.data.length})</h2>
                    </Row> : null
                }
            </div>

            <div className='cust-table'>
                {collectionData ?
                    <BootstrapTable
                        keyField="adText"
                        data={collectionData.data}
                        columns={columns}
                        bordered={false}
                    />
                    :
                    null
                }
            </div>
        </div>
    )

}

export default ViewCollection;
