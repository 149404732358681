import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { STRIPE_PUB_KEY } from './constants';
import { BrowserRouter as Router } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(STRIPE_PUB_KEY);

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Router>
  // </React.StrictMode>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
