import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { Link, useParams } from 'react-router-dom';
import { Button, Row } from 'antd';
import { LeftOutlined, StarFilled } from '@ant-design/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import UserContext from '../../userContext';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import { PAGE_HEAD_TITLE } from "../../constants";
import { Database } from '../../firebase';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import CreateLayersModal from './CreateLayerModal';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import './style.css';

const ViewCollection = props => {

    const { id: collectionId } = useParams();
    const authUser = useContext(UserContext);

    const [open, setOpen] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false); // eslint-disable-line no-unused-vars
    const [inputValue, setInputValue] = useState(''); // eslint-disable-line no-unused-vars
    const [collectionData, setCollectionData] = useState(null);
    const [selectedInterests, setSelectedInterests] = useState([]);

    const columns = [
        {
            dataField: 'name',
            text: 'Interests',
        },
        {
            dataField: 'audienceSizeLowerBound',
            text: 'Audience Size - Lower Bound',
            sort: true
        },
        {
            dataField: 'audienceSizeUpperBound',
            text: 'Audience Size - Upper Bound',
            sort: true
        },
        {
            dataField: 'topic',
            text: 'Path',
        }
    ];

    useEffect(() => {
        document.title = `Facebook Hidden Interest - Collection ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {
        getUserCollectionItems();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUserCollectionItems = async () => {
        setIsPageLoading(true);
        var { uid } = authUser
        if (uid) {
            try {

                const docRef = doc(Database, "fbHiddenInterestsCollection", collectionId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = await docSnap.data();
                    data.interests = JSON.parse(data.interests);
                    setCollectionData(data);
                    setIsPageLoading(false);
                } else {
                    setIsPageLoading(false);
                    console.log("No such document!");
                }
            } catch (e) {
                setIsPageLoading(false);
            }
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedInterests([...selectedInterests, row])
        } else {
            const interests = [...selectedInterests];
            // const itemIndex = selectedInterests.filter((item) => item.name === row.name);
            // console.log("itemIndex:", itemIndex[0])
            const itemIndex = interests.indexOf(row);
            // console.log("itemIndex:", itemIndex)
            interests.splice(itemIndex, 1);
            setSelectedInterests(interests);
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            let key;
            let interests = [];
            for (key in rows) {
                interests.push(rows[key]);
            }
            setSelectedInterests(rows);
        } else {
            setSelectedInterests([]);
        }
    }

    const onSubmit = () => {
    }

    const selectRow = {
        mode: 'checkbox',
        selectionRenderer: (props) => (
            <>
                <input type={props.mode} checked={props.checked} defaultChecked={props.checked} onChange={() => { }} hidden />
                <StarFilled style={{ color: props.checked ? '#e7ec0e' : '#175BCB', cursor: 'pointer' }} />
            </>
        ),
        selectionHeaderRenderer: (props) => (
            <div style={{ width: '20%' }}>
                <input type={props.mode} checked={props.checked} defaultChecked={props.checked} onChange={() => { }} hidden />
                <StarFilled style={{ color: props.checked ? '#e7ec0e' : '', cursor: 'pointer' }} />
            </div>
        ),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    };

    // const pageListRenderer = ({ pages, onPageChange }) => {
    //     const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    //     if (pages.length > 1) {
    //         return (
    //             <div className='text-center'>
    //                 {pageWithoutIndication.map(p => {
    //                     // console.log(p, 'p')
    //                     return (
    //                         <button className={p.active ? 'btn paginations active' : 'btn paginations '} actives={p.active} onClick={() => onPageChange(p.page)}>{p.page}</button>
    //                     )
    //                 })}
    //             </div>
    //         );
    //     } else {
    //         return false
    //     }
    // };

    // const options = {
    //     pageListRenderer,
    //     hideSizePerPage: true,
    // };

    return (
        <div className="content-wrapper">
            {/* {isPageLoading ? <Loading loading background="#2ecc71" loaderColor="#3498db" /> : null} */}
            <div className='content-top'>
                <Button title='Back' type="link" icon={<LeftOutlined style={{ verticalAlign: 'middle', marginRight: 5, }} />}>
                    <Link style={{ verticalAlign: 'middle', fontWeight: 600 }} to='/facebook-hidden-interests-collection'>Go Back</Link>
                </Button>
            </div>

            <ClipboardCopy text={selectedInterests.map(item => item.name).join(", ")} />

            <div className="select-layers-box">
                {
                    !selectedInterests.length ?
                        <p>Select interests below to add them here...</p>
                        : selectedInterests.map((item) => item.name).join(", ")
                }
            </div>
            <div className="mb-4 mt-4 text-center">
                <Button type="primary" onClick={() => setOpen(true)}>Create Layers</Button>
                {open &&
                    <CreateLayersModal
                        open={open}
                        selectedInterests={selectedInterests}
                        setOpen={() => setOpen(true)}
                        setClose={() => setOpen(false)}
                        onSubmit={onSubmit}
                        setChangeInput={(e) => setInputValue(e.target.value)}
                    />
                }
            </div>

            <div className='cust-table'>
                {collectionData ?
                    <React.Fragment>
                        <Row className="save_collection mb-4">
                            <h2 className="explore-title">{collectionData.collectionName} ({collectionData.interests.length} interests)</h2>
                        </Row>
                        <BootstrapTable
                            keyField="name"
                            data={collectionData.interests}
                            columns={columns}
                            selectRow={selectRow}
                            bordered={false}
                        // pagination={paginationFactory(options)}
                        />
                    </React.Fragment>
                    :
                    'No collection data'
                }
            </div>
        </div>
    )

}

export default ViewCollection;
