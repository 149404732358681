import React, { useState, useEffect, useContext } from 'react';
import { getDoc, doc } from "firebase/firestore";
import moment from 'moment';

import UserMain from '../User/UserMain';
import UserContext from '../../userContext';
import { Database } from '../../firebase';
import { PAGE_HEAD_TITLE } from "../../constants";

const Profile = (props) => {

    const authUser = useContext(UserContext);

    // console.log("authUser:", authUser)
    const [user, setUser] = useState(null);

    useEffect(() => {
        // console.log("loaded")
        document.title = `Profile ${PAGE_HEAD_TITLE}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetchUser();
    }, []); //eslint-disable-line

    const fetchUser = async () => {

        if (authUser && authUser.uid) {
            const docRef = doc(Database, "users", authUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = await docSnap.data();

                const formatedDate = moment(data.createdDate.toDate()).format('DD-MM-YYYY')
                data.dateCreated = formatedDate;
                // console.log("data:", data);
                setUser(data);
            } else {
                console.log("No such document!");
            }
        }
    }

    // useEffect(() => {
    //     setUser(authUser.accountData);
    // }, [authUser])

    return <UserMain user={user} authUser={authUser} />
}

export default Profile;
