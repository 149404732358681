import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Button, Input, Popover } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import BunnyPlayer from '../BunnyPlayer';

import { Flex, Title, BlueBackground, CalcTextA, WhiteBack, Padding } from '../../styles/PageStyle';
import LocationDropdown, { locationOptions } from "../locationDropdown";
import { formatNumber, nFormatter, subscribeToExport } from '../../utils/Helpers';

export default function LeadsRoasAnalysis() {

    const authUser = useContext(UserContext);

    const { Text } = Typography;
    const [locationData, setLocationData] = useState(locationOptions[6])
    // Ads Spend Per Month
    const [ComparePrice1, setComparePrice1] = useState()
    const [ComparePrice2, setComparePrice2] = useState()
    const [ComparePrice3, setComparePrice3] = useState()

    // Average Cost Per Click (CPC)
    const [ExpectedAverage1, setExpectedAverage1] = useState('')
    const [ExpectedAverage2, setExpectedAverage2] = useState('')
    const [ExpectedAverage3, setExpectedAverage3] = useState('')

    // Total Website Sessions from Ads
    const [TotalWebsite1, setTotalWebsite1] = useState('')
    const [TotalWebsite2, setTotalWebsite2] = useState('')
    const [TotalWebsite3, setTotalWebsite3] = useState('')

    // Lead Conversion Rate
    const [LeadConversion1, setLeadConversion1] = useState('')
    const [LeadConversion2, setLeadConversion2] = useState('')
    const [LeadConversion3, setLeadConversion3] = useState('')

    // Total Leads
    const [TotalLeads1, setTotalLeads1] = useState('')
    const [TotalLeads2, setTotalLeads2] = useState('')
    const [TotalLeads3, setTotalLeads3] = useState('')

    // Cost Per Raw Inquiry/Lead
    const [CostPerRaw1, setCostPerRaw1] = useState('')
    const [CostPerRaw2, setCostPerRaw2] = useState('')
    const [CostPerRaw3, setCostPerRaw3] = useState('')

    // QualifiedLead Percentage
    const [QualifiedLead1, setQualifiedLead1] = useState('')
    const [QualifiedLead2, setQualifiedLead2] = useState('')
    const [QualifiedLead3, setQualifiedLead3] = useState('')

    // Email Sequence Completion Rate
    const [EmailSequence1, setEmailSequence1] = useState('')
    const [EmailSequence2, setEmailSequence2] = useState('')
    const [EmailSequence3, setEmailSequence3] = useState('')

    // Up-Sell Take Rate
    const [UpSeleTake1, setUpSeleTake1] = useState('');
    const [UpSeleTake2, setUpSeleTake2] = useState('');
    const [UpSeleTake3, setUpSeleTake3] = useState('');

    // Up-Sells Sold
    const [UpSellsSold1, setUpSellsSold1] = useState('');
    const [UpSellsSold2, setUpSellsSold2] = useState('');
    const [UpSellsSold3, setUpSellsSold3] = useState('');

    // Average Revenue Per Sale
    const [AverageRevenue1, setAverageRevenue1] = useState('');
    const [AverageRevenue2, setAverageRevenue2] = useState('');
    const [AverageRevenue3, setAverageRevenue3] = useState('');

    // Product Cost
    const [ProductCost1, setProductCost1] = useState('');
    const [ProductCost2, setProductCost2] = useState('');
    const [ProductCost3, setProductCost3] = useState('');

    // Agency/ Freelancers
    const [AgencyFreelancers1, setAgencyFreelancers1] = useState('');
    const [AgencyFreelancers2, setAgencyFreelancers2] = useState('');
    const [AgencyFreelancers3, setAgencyFreelancers3] = useState('');

    // Gross Revenue
    const [GrossRevenue1, setGrossRevenue1] = useState('');
    const [GrossRevenue2, setGrossRevenue2] = useState('');
    const [GrossRevenue3, setGrossRevenue3] = useState('');

    // Average Costs
    const [AverageCosts1, setAverageCosts1] = useState('');
    const [AverageCosts2, setAverageCosts2] = useState('');
    const [AverageCosts3, setAverageCosts3] = useState('');

    // Gross Profit
    const [GrossProfit1, setGrossProfit1] = useState('');
    const [GrossProfit2, setGrossProfit2] = useState('');
    const [GrossProfit3, setGrossProfit3] = useState('');

    // Return on Ad Spend (ROAS)
    const [ReturnonAd1, setReturnonAd1] = useState('');
    const [ReturnonAd2, setReturnonAd2] = useState('');
    const [ReturnonAd3, setReturnonAd3] = useState('');

    // Return on Investment (ROI)
    const [ReturnonInvestment1, setReturnonInvestment1] = useState('');
    const [ReturnonInvestment2, setReturnonInvestment2] = useState('');
    const [ReturnonInvestment3, setReturnonInvestment3] = useState('');

    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        document.title = `ROAS Analysis ${PAGE_HEAD_TITLE}`;
    }, []);

    let compare1 = ComparePrice1 / ExpectedAverage1
    let compare2 = ComparePrice2 / ExpectedAverage2
    let compare3 = ComparePrice3 / ExpectedAverage3

    useEffect(() => {
        setTotalWebsite1(compare1.toFixed(2))
        setTotalWebsite2(compare2.toFixed(2))
        setTotalWebsite3(compare3.toFixed(2))
    }, [ComparePrice1, ComparePrice2, ComparePrice3, ExpectedAverage1, ExpectedAverage2, ExpectedAverage3]); //eslint-disable-line

    let Formula1 = TotalWebsite1 * LeadConversion1 / 1e2
    let Formula2 = TotalWebsite2 * LeadConversion2 / 1e2
    let Formula3 = TotalWebsite3 * LeadConversion3 / 1e2
    useEffect(() => {
        setTotalLeads1(Formula1.toFixed(2))
        setTotalLeads2(Formula2.toFixed(2))
        setTotalLeads3(Formula3.toFixed(2))
    }, [TotalWebsite1, TotalWebsite2, TotalWebsite3, LeadConversion1, LeadConversion2, LeadConversion3]); //eslint-disable-line

    let Formula_1 = ComparePrice1 / TotalLeads1
    let Formula_2 = ComparePrice2 / TotalLeads2
    let Formula_3 = ComparePrice3 / TotalLeads3

    useEffect(() => {
        setCostPerRaw1(Formula_1.toFixed(2))
        setCostPerRaw2(Formula_2.toFixed(2))
        setCostPerRaw3(Formula_3.toFixed(2))
    }, [ComparePrice1, ComparePrice2, ComparePrice3, TotalLeads1, TotalLeads2, TotalLeads3]); //eslint-disable-line

    let Formula__1 = EmailSequence1 * UpSeleTake1 / 1e2
    let Formula__2 = EmailSequence1 * UpSeleTake2 / 1e2
    let Formula__3 = EmailSequence3 * UpSeleTake3 / 1e2
    useEffect(() => {
        setUpSellsSold1(Formula__1.toFixed(2))
        setUpSellsSold2(Formula__2.toFixed(2))
        setUpSellsSold3(Formula__3.toFixed(2))
    }, [EmailSequence1, EmailSequence1, EmailSequence3, UpSeleTake1, UpSeleTake2, UpSeleTake3]); //eslint-disable-line

    let FormulaGrossrevenue_1 = UpSellsSold1 * AverageRevenue1
    let FormulaGrossrevenue_2 = UpSellsSold2 * AverageRevenue2
    let FormulaGrossrevenue_3 = UpSellsSold3 * AverageRevenue3

    useEffect(() => {
        setGrossRevenue1(FormulaGrossrevenue_1)
        setGrossRevenue2(FormulaGrossrevenue_2)
        setGrossRevenue3(FormulaGrossrevenue_3)
    }, [UpSellsSold1, UpSellsSold2, UpSellsSold3, AverageRevenue1, AverageRevenue2, AverageRevenue3]); //eslint-disable-line

    let result1 = GrossRevenue1 * ProductCost1 / 100
    let AvregeCostCalc1 = Number(result1) + Number(AgencyFreelancers1) + Number(ComparePrice1)

    let result2 = GrossRevenue2 * ProductCost2 / 100
    let AvregeCostCalc2 = Number(result2) + Number(AgencyFreelancers2) + Number(ComparePrice2)

    let result3 = GrossRevenue3 * ProductCost3 / 100
    let AvregeCostCalc3 = Number(result3) + Number(AgencyFreelancers3) + Number(ComparePrice3)

    useEffect(() => {
        setAverageCosts1(AvregeCostCalc1)
        setAverageCosts2(AvregeCostCalc2)
        setAverageCosts3(AvregeCostCalc3)
    }, [GrossRevenue1, GrossRevenue2, GrossRevenue3, ProductCost1, ProductCost2, ProductCost3, AgencyFreelancers1, AgencyFreelancers2, AgencyFreelancers3, ComparePrice1, ComparePrice3, ComparePrice2]); //eslint-disable-line


    var GrossProfit_1 = GrossRevenue1 - AverageCosts1
    var GrossProfit_2 = GrossRevenue2 - AverageCosts2
    var GrossProfit_3 = GrossRevenue3 - AverageCosts3

    useEffect(() => {
        setGrossProfit1(GrossProfit_1.toFixed(2))
        setGrossProfit2(GrossProfit_2.toFixed(2))
        setGrossProfit3(GrossProfit_3.toFixed(2))
    }, [GrossRevenue1, AverageCosts1, GrossRevenue2, AverageCosts2, GrossRevenue3, AverageCosts3]); //eslint-disable-line


    var returnOnAds1 = (GrossRevenue1 - AgencyFreelancers1) - ComparePrice1
    var Res1 = returnOnAds1 / ComparePrice1

    var returnOnAds2 = (GrossRevenue2 - AgencyFreelancers2) - ComparePrice2
    var Res2 = returnOnAds2 / ComparePrice2

    var returnOnAds3 = (GrossRevenue3 - AgencyFreelancers3) - ComparePrice3
    var Res3 = returnOnAds3 / ComparePrice3


    useEffect(() => {
        setReturnonAd1(Res1 * 1e2)
        setReturnonAd2(Res2 * 1e2)
        setReturnonAd3(Res3 * 1e2)
    }, [GrossRevenue1, GrossRevenue2, GrossRevenue3, AgencyFreelancers1, AgencyFreelancers2, AgencyFreelancers3, ComparePrice1, ComparePrice2, ComparePrice3]); //eslint-disable-line


    var ReturnonInvestment_1 = GrossProfit1 / AverageCosts1
    var ReturnonInvestment_2 = GrossProfit2 / AverageCosts2
    var ReturnonInvestment_3 = GrossProfit3 / AverageCosts3
    useEffect(() => {
        setReturnonInvestment1((ReturnonInvestment_1 * 100).toFixed(2))
        setReturnonInvestment2((ReturnonInvestment_2 * 100).toFixed(2))
        setReturnonInvestment3((ReturnonInvestment_3 * 100).toFixed(2))
    }, [GrossProfit1, AverageCosts1, GrossProfit2, AverageCosts2, GrossProfit3, AverageCosts3]); //eslint-disable-line


    var Loss = { backgroundColor: '#EB5757' }
    var Profit = { backgroundColor: '#8DD1B6' }

    const HandleFirstFiels = (val) => {
        if (val <= 1e9) setComparePrice1(val)
    }
    const HandleSecFiels = (val) => {
        if (val <= 1e9) setComparePrice2(val)
    }
    const HandleThirdFiels = (val) => {
        if (val <= 1e9) setComparePrice3(val)
    }

    const downloadPdfDocument = () => {
        // const downloadPdfDocument = (rootElementId) => {
        // const input = document.getElementById(rootElementId);
        setDownloadingPdf(true);
        const input = document.getElementById('divToDownload');
        // html2canvas(input, { scale: 0.81 })
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "mm", "a4");
                // const width = pdf.internal.pageSize.getWidth() - 14;
                // const height = pdf.internal.pageSize.getHeight();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 14;

                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + 5;
                pdf.text("Boost With HMA", 5, 10);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight);
                pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight, "a", "FAST");

                pdf.save("download.pdf", { returnPromise: true })
                    .then(() => {
                        setDownloadingPdf(false);
                    });
            })
    }

    useEffect(() => {
        const budget1 = nFormatter(ComparePrice1) || '2K';
        const budget2 = nFormatter(ComparePrice2) || '5K';
        const budget3 = nFormatter(ComparePrice3) || '10K';
        const currencySymbol = locationData.currencySymbol;
        const data = [
            ["Boost With HMA"],
            [],
            ["Leads Ads ROAS Analysis", "", `Currency - ${currencySymbol}`],
            [],
            ["Leads Ads Metrics", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],
            ["Ads Spend Per Month", `${currencySymbol} ${formatNumber(ComparePrice1)}`, `${currencySymbol} ${formatNumber(ComparePrice2)}`, `${currencySymbol} ${formatNumber(ComparePrice3)}`],
            ["Average Cost Per Click (CPC)", `${currencySymbol} ${formatNumber(ExpectedAverage1)}`, `${currencySymbol} ${formatNumber(ExpectedAverage2)}`, `${currencySymbol} ${formatNumber(ExpectedAverage3)}`],
            ["Total Website Sessions from Ads", `${formatNumber(TotalWebsite1)}`, `${formatNumber(TotalWebsite2)}`, `${formatNumber(TotalWebsite3)}`],
            ["Lead Conversion Rate", `% ${formatNumber(LeadConversion1)}`, `% ${formatNumber(LeadConversion2)}`, `% ${formatNumber(LeadConversion3)}`],
            ["Total Leads", `${formatNumber(TotalLeads1)}`, `${formatNumber(TotalLeads2)}`, `${formatNumber(TotalLeads3)}`],
            ["Cost Per Raw Inquiry/Lead", `${currencySymbol} ${formatNumber(CostPerRaw1)}`, `${currencySymbol} ${formatNumber(CostPerRaw2)}`, `${currencySymbol} ${formatNumber(CostPerRaw3)}`],

            ["Conversion Metrics", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],
            ["Qualified Lead", `${formatNumber(QualifiedLead1)}`, `${formatNumber(QualifiedLead2)}`, `${formatNumber(QualifiedLead3)}`],
            ["Email Sequence Completion", `${formatNumber(EmailSequence1)}`, `${formatNumber(EmailSequence2)}`, `${formatNumber(EmailSequence3)}`],
            ["Up-Sell Take Rate", `% ${formatNumber(UpSeleTake1)}`, `% ${formatNumber(UpSeleTake2)}`, `% ${formatNumber(UpSeleTake3)}`],
            ["Up-Sell Sold", `${formatNumber(UpSellsSold1)}`, `${formatNumber(UpSellsSold2)}`, `${formatNumber(UpSellsSold3)}`],
            ["Average Revenue Per Sale", `${currencySymbol} ${formatNumber(AverageRevenue1)}`, `${currencySymbol} ${formatNumber(AverageRevenue2)}`, `${currencySymbol} ${formatNumber(AverageRevenue3)}`],
            ["Product Cost", `% ${formatNumber(ProductCost1)}`, `% ${formatNumber(ProductCost2)}`, `% ${formatNumber(ProductCost3)}`],
            ["Agency/ Freelancers / Tools Fees", `${currencySymbol} ${formatNumber(AgencyFreelancers1)}`, `${currencySymbol} ${formatNumber(AgencyFreelancers2)}`, `${currencySymbol} ${formatNumber(AgencyFreelancers3)}`],

            ["Actual Results", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],
            ["Gross Revenue", `${currencySymbol} ${formatNumber(GrossRevenue1)}`, `${currencySymbol} ${formatNumber(GrossRevenue2)}`, `${currencySymbol} ${formatNumber(GrossRevenue3)}`],
            ["Average Costs", `${currencySymbol} ${formatNumber(AverageCosts1)}`, `${currencySymbol} ${formatNumber(AverageCosts2)}`, `${currencySymbol} ${formatNumber(AverageCosts3)}`],
            ["Gross Profit", `${currencySymbol} ${formatNumber(GrossProfit1)}`, `${currencySymbol} ${formatNumber(GrossProfit2)}`, `${currencySymbol} ${formatNumber(GrossProfit3)}`],
            ["Return on Ad Spend (ROAS)", `% ${formatNumber(ReturnonAd1)}`, `% ${formatNumber(ReturnonAd2)}`, `% ${formatNumber(ReturnonAd3)}`],
            ["Return on Investment (ROI)", `% ${formatNumber(ReturnonInvestment1)}`, `% ${formatNumber(ReturnonInvestment2)}`, `% ${formatNumber(ReturnonInvestment3)}`],
        ];
        setCsvData(data);
    }, [locationData, ComparePrice1, ComparePrice2, ComparePrice3, ExpectedAverage1, ExpectedAverage2, ExpectedAverage3, TotalWebsite1, TotalWebsite2, TotalWebsite3, LeadConversion1, LeadConversion2, LeadConversion3, TotalLeads1, TotalLeads2, TotalLeads3, CostPerRaw1, CostPerRaw2, CostPerRaw3, QualifiedLead1, QualifiedLead2, QualifiedLead3, EmailSequence1, EmailSequence2, EmailSequence3, UpSeleTake1, UpSeleTake2, UpSeleTake3, UpSellsSold1, UpSellsSold2, UpSellsSold3, AverageRevenue1, AverageRevenue2, AverageRevenue3, ProductCost2, ProductCost2, ProductCost2, AgencyFreelancers1, AgencyFreelancers2, AgencyFreelancers3, GrossRevenue1, GrossRevenue2, GrossRevenue3, AverageCosts1, AverageCosts2, AverageCosts3, GrossProfit1, GrossProfit2, GrossProfit3, ReturnonAd1, ReturnonAd2, ReturnonAd3, ReturnonInvestment1, ReturnonInvestment2, ReturnonInvestment3]); //eslint-disable-line

    const currencySymbol = locationData.currencySymbol;

    return (
        <Row justify="center" className='facg-main-wrapper' style={{ marginTop: '30px' }}>
            <BunnyPlayer type="LEADS_ROAS_ANALYSIS" />
            <Col span={24} style={{ marginTop: "2rem", marginBottom: '6rem' }}>
                <Row justify="center">
                    <Col span={20} id="divToDownload">
                        <Row justify="space-between" style={{ marginBottom: 16, alignItems: 'center' }}>
                            <Col xs={12} >
                                <Text level={5} style={{ fontWeight: "550", fontSize: 18 }}>
                                    Leads Ads ROAS Analysis
                                </Text>
                            </Col>
                            <Col xs={12} >
                                <Flex style={{ justifyContent: 'flex-end' }}>
                                    <LocationDropdown defaultValue={locationData} onChange={(value) => { setLocationData(value) }} />
                                </Flex>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={8}>
                                        <Title>Leads Ads Metrics</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ComparePrice1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <Row>
                            <WhiteBack>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Ads Spend Per Month</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            onChange={(e) => HandleFirstFiels(e.target.value)}
                                            value={ComparePrice1}
                                            type="text"
                                            max={10}
                                            prefix={currencySymbol}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            onChange={(e) => HandleSecFiels(e.target.value)}
                                            value={ComparePrice2}
                                            max={10}
                                            type="text"
                                            prefix={currencySymbol}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            onChange={(e) => HandleThirdFiels(e.target.value)}
                                            value={ComparePrice3}
                                            max={10}
                                            type="text"
                                            prefix={currencySymbol}
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Average Cost Per Click (CPC)</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={currencySymbol}
                                            type="number"
                                            value={ExpectedAverage1}
                                            onChange={(e) => setExpectedAverage1(e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={currencySymbol}
                                            value={ExpectedAverage2}
                                            onChange={(e) => setExpectedAverage2(e.target.value)}
                                            type="number"
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={currencySymbol}
                                            value={ExpectedAverage3}
                                            onChange={(e) => setExpectedAverage3(e.target.value)}
                                            type="number"
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Total Website Sessions from Ads</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalWebsite1}
                                            onChange={(e) => setTotalLeads1(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalWebsite2}
                                            onChange={(e) => setTotalLeads2(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalWebsite3}
                                            onChange={(e) => setTotalLeads3(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Lead Conversion Rate</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            value={LeadConversion1}
                                            onChange={(e) => setLeadConversion1(e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            value={LeadConversion2}
                                            onChange={(e) => setLeadConversion2(e.target.value)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            value={LeadConversion3}
                                            onChange={(e) => setLeadConversion3(e.target.value)}
                                            type="number"
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Total Leads</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalLeads1}
                                            onChange={(e) => setTotalLeads1(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalLeads2}
                                            onChange={(e) => setTotalLeads2(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            value={TotalLeads3}
                                            onChange={(e) => setTotalLeads3(e.target.value)}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={8}>
                                        <CalcTextA>Cost Per Raw Inquiry/Lead</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={CostPerRaw1}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={CostPerRaw2}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={CostPerRaw3}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row> </Padding>
                            </WhiteBack>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={8}>
                                        <Title>Conversion Metrics</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ComparePrice1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <WhiteBack>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Qualified Lead</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={QualifiedLead1}
                                        onChange={(e) => setQualifiedLead1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        value={QualifiedLead2}
                                        type="number"
                                        onChange={(e) => setQualifiedLead2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        value={QualifiedLead3}
                                        type="number"
                                        onChange={(e) => setQualifiedLead3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Email Sequence Completion</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={EmailSequence1}
                                        onChange={(e) => setEmailSequence1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={EmailSequence2}
                                        onChange={(e) => setEmailSequence2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={EmailSequence3}
                                        onChange={(e) => setEmailSequence3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Up-Sell Take Rate </CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={UpSeleTake1}
                                        onChange={(e) => setUpSeleTake1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={UpSeleTake2}
                                        onChange={(e) => setUpSeleTake2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={UpSeleTake3}
                                        onChange={(e) => setUpSeleTake3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Up-Sells Sold</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={UpSellsSold1}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={UpSellsSold2}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        value={UpSellsSold3}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Average Revenue Per Sale</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AverageRevenue1}
                                        onChange={(e) => setAverageRevenue1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={currencySymbol}
                                        type="number"
                                        value={AverageRevenue2}
                                        onChange={(e) => setAverageRevenue2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={currencySymbol}
                                        type="number"
                                        value={AverageRevenue3}
                                        onChange={(e) => setAverageRevenue3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Product Cost</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={'%'}
                                        value={ProductCost1}
                                        onChange={(e) => setProductCost1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={ProductCost2}
                                        onChange={(e) => setProductCost2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={ProductCost3}
                                        onChange={(e) => setProductCost3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Agency/ Freelancers / Tools Fees</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AgencyFreelancers1}
                                        onChange={(e) => setAgencyFreelancers1(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AgencyFreelancers2}
                                        onChange={(e) => setAgencyFreelancers2(e.target.value)}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AgencyFreelancers3}
                                        onChange={(e) => setAgencyFreelancers3(e.target.value)}
                                    />
                                </Col>
                            </Row></Padding>
                        </WhiteBack>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={8}>
                                        <Title>Actual Results</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ComparePrice1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ComparePrice3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <WhiteBack>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Gross Revenue</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={GrossRevenue1}
                                        onChange={(e) => setGrossRevenue1(e.target.value)}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={GrossRevenue2}
                                        onChange={(e) => setGrossRevenue2(e.target.value)}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={GrossRevenue3}
                                        onChange={(e) => setGrossRevenue3(e.target.value)}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Average Costs</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AverageCosts1}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AverageCosts2}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        value={AverageCosts3}
                                        style={{ backgroundColor: '#F3F3F3' }}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Gross Profit</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        style={GrossProfit1 < 0 ? Loss : Profit}
                                        value={GrossProfit1}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        style={GrossProfit2 < 0 ? Loss : Profit}
                                        value={GrossProfit2}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={currencySymbol}
                                        style={GrossProfit3 < 0 ? Loss : Profit}
                                        value={GrossProfit3}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Return on Ad Spend (ROAS)</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        type="number"
                                        prefix={'%'}
                                        value={ReturnonAd1}
                                        style={ReturnonAd1 < 0 ? Loss : Profit}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        style={ReturnonAd2 < 0 ? Loss : Profit}
                                        value={ReturnonAd2}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        style={ReturnonAd3 < 0 ? Loss : Profit}
                                        value={ReturnonAd3}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                            <Padding><Row justify="space-between">
                                <Col sm={8}>
                                    <CalcTextA>Return on Investment (ROI)</CalcTextA>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={ReturnonInvestment1}
                                        style={ReturnonInvestment1 < 0 ? Loss : Profit}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={ReturnonInvestment2}
                                        style={ReturnonInvestment2 < 0 ? Loss : Profit}
                                        disabled
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        prefix={'%'}
                                        type="number"
                                        value={ReturnonInvestment3}
                                        style={ReturnonInvestment3 < 0 ? Loss : Profit}
                                        disabled
                                    />
                                </Col>
                            </Row></Padding>
                        </WhiteBack>
                    </Col>
                </Row>

                {
                    authUser.accountType === "FREE" ?
                        <Row justify="center" style={{ marginTop: '2rem' }}>
                            <Popover content={subscribeToExport} className="">
                                <Button className="primary-button not-allowed-button">Export as PDF</Button>
                            </Popover>

                            <Popover content={subscribeToExport} className="" >
                                <Button className="primary-button not-allowed-button" style={{ marginLeft: '20px' }}>Export as CSV</Button>
                            </Popover>
                        </Row>
                        :
                        <Row justify="center">
                            <Button className="primary-button mt-2rem" onClick={downloadPdfDocument} disabled={downloadingPdf}>{downloadingPdf ? 'Exporting...' : 'Export as PDF'}</Button>
                            <CSVLink
                                className="csv-download-button primary-button mt-2rem"
                                data={csvData}
                                onClick={() => {
                                    // console.log("You click the link");
                                    if (downloadingCsv) {
                                        return false;
                                    } else {
                                        setDownloadingCsv(true);
                                        const timeout = setTimeout(() => {
                                            setDownloadingCsv(false);
                                            clearTimeout(timeout);
                                        }, 3000)
                                    }
                                }}
                                filename={"fb-roas-analysis.csv"}
                            >
                                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
                            </CSVLink>
                        </Row>
                }
            </Col>
        </Row>
    );
}
