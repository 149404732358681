import React from "react";
import { Line } from "@ant-design/charts";

const GraphComponent = ({ data }) => {
  // const data = [
  //   { date: 'Yesterday', value: 931.29, category: 'Cost Per Lead' },
  //   { date: 'Last 3 Days', value: 747.22, category: 'Cost Per Lead' },
  //   { date: 'Last 7 Days', value: 747, category: 'Cost Per Lead' },
  //   { date: 'Last 14 Days', value: 799.59, category: 'Cost Per Lead' },
  //   { date: 'Last 60 Days', value: 588.54, category: 'Cost Per Lead' },

  //   { date: 'Yesterday', value: 725.46, category: 'CPM' },
  //   { date: 'Last 3 Days', value: 616.56, category: 'CPM' },
  //   { date: 'Last 7 Days', value: 586, category: 'CPM' },
  //   { date: 'Last 14 Days', value: 585.51, category: 'CPM' },
  //   { date: 'Last 60 Days', value: 270.99, category: 'CPM' },

  //   { date: 'Yesterday', value: 68.62, category: 'Cost Per Unique Click' },
  //   { date: 'Last 3 Days', value: 58.01, category: 'Cost Per Unique Click' },
  //   { date: 'Last 7 Days', value: 53.37, category: 'Cost Per Unique Click' },
  //   { date: 'Last 14 Days', value: 55.53, category: 'Cost Per Unique Click' },

  //   { date: 'Yesterday', value: 1.2, category: 'Unique CTR' },
  //   { date: 'Last 3 Days', value: 1.22, category: 'Unique CTR' },
  //   { date: 'Last 7 Days', value: 1.26, category: 'Unique CTR' },
  //   { date: 'Last 14 Days', value: 1.12, category: 'Unique CTR' },
  // ];

  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        formatter: (v) => `${v}`, // Format labels if needed
      },
    },
    tooltip: {
      showMarkers: true,
      formatter: (datum) => {
        // Add symbols based on the category
        const valueWithSymbol =
          datum.category === "Unique CTR"
            ? `${datum.value}%` // Adding % for Unique CTR
            : `₹ ${datum.value}`; // Adding ₹ for other categories

        return {
          name: datum.category,
          value: valueWithSymbol,
        };
      },
    },
    point: {
      size: 5,
      shape: "circle",
    },
    lineStyle: {
      lineWidth: 2,
    },
    legend: {
      position: "top",
    },
    smooth: true, // Optional: makes lines smoother
    color: ["#1890ff", "#faad14", "#722ed1", "#eb2f96"], // Colors for each line
  };

  return <Line {...config} />;
};

export default GraphComponent;
