import React, { useState } from 'react';
import { Modal, Input, Row, Button } from 'antd';
import { StarFilled } from "@ant-design/icons";

const { Search } = Input;

const CollectionModal = (props) => {
  const { open, closeClickModal, onSubmit } = props;

  const [collectionName, setCollectionName] = useState('');

  const onClickModal = () => {
    if (collectionName) {
      onSubmit(collectionName)
    }
  }

  return (
    <Modal
      className='save-collection-modal'
      closable
      visible={open}
      onCancel={() => closeClickModal()}
      footer={null}
      centered
      width='50%'
    >
      <div className="modal_header" style={{ fontSize: 21 }}>
        <StarFilled style={{ color: '#E7EC0E', marginRight: '1rem' }} />  Save to collections
      </div>
      <div className="modal_content">
        <Row className="searchfbInterests">
          <>
            <h3 style={{ fontSize: 18 }}>Name your Collection</h3>
            <Search
              placeholder="Name"
              size="large"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
            />
            <div className="modal-actions">
              <Button type="primary" onClick={onClickModal}>Save</Button>
            </div>
          </>
        </Row>
      </div>
    </Modal>
  )
}

export default CollectionModal;

