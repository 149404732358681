import React from "react";
import { Bar } from "@ant-design/charts";

const CPMChart = ({ cpmChart, value }) => {
  const formattedData = cpmChart.length
    ? cpmChart.map((item) => ({
        label: item.label,
        [value]: parseFloat(item[value].replace("%", "")),
        Symbol: value,
      }))
    : [{ label: "No Data", value: 0 }];
    
  const config = {
    data: formattedData,
    xField: value,
    yField: "label",
    seriesField: "",
    color: "#1890ff",
    barWidthRatio: 0.8,
    label: {
      formatter: (text, item) => {
        return `${value === "CPM" ? "₹" : ""} ${text[value]}${value === "CTR" ? "%" : ""}`;
      },
      position: "middle",
      style: {
        fill: "#FFFFFF", // Set label color to black
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fill: "#000000",
        },
      },
    },
    tooltip: {
      customContent: (title, items) => {
        if (items && items.length) {
          const data = items[0].data;
          return `<div style="padding:10px;">
                <strong>${data.label}</strong>
                <br />
                <span style="display: inline-block; height: 10px;"></span> 
                <div>
                  ${items[0].name}: ${value === "CPM" ? "₹" : ""} ${data[value]}${value === "CTR" ? "&nbsp;% " : ""}
                </div>
              </div>`;
        }
        return null;
      },
    },
    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };

  return (
    <div style={{ width: "600px" }}>
      <Bar {...config} />
    </div>
  );
};

export default CPMChart;
