import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Button, Input, Popover } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import BunnyPlayer from '../BunnyPlayer';

import { Flex, Title, BlueBackground, CalcText, WhiteBack, Padding } from '../../styles/PageStyle'
import LocationDropdown, { locationOptions } from "../locationDropdown";
import { formatNumber, subscribeToExport } from '../../utils/Helpers';

const { Text } = Typography;

export default function LeadsCampaignGoals() {

  const authUser = useContext(UserContext);

  const [locationData, setLocationData] = useState(locationOptions[6])
  const [ExpectedAverage, setExpectedAverage] = useState()
  const [TotalAudience, setTotalAudience] = useState()
  const [ExpectedClick, setExpectedClick] = useState()
  const [ExpectedWebsite, setExpectedWebsite] = useState()

  const [ActualLead, setActualLead] = useState()
  const [ExpectLead, setExpectLead] = useState()

  const [ActualCostDifference, setActualCostDifference] = useState()
  const [TargetCost, setTargetCost] = useState()
  const [ActualCost, setActualCost] = useState()

  const [ApproximateMonthly, setApproximateMonthly] = useState()
  const [TotalLeads, setTotalLeads] = useState()

  const [TargetLead, setTargetLead] = useState()
  const [TotalLeadsAbouve, setTotalLeadsAbouve] = useState()

  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    document.title = `Campaign Goals ${PAGE_HEAD_TITLE}`;
  }, []);

  useEffect(() => {
    if (TotalAudience !== '' && ExpectedClick !== '') {
      setExpectedWebsite(TotalAudience * ExpectedClick / 100)
    }
  }, [TotalAudience, ExpectedClick]); //eslint-disable-line

  const [ActualLeadDifference, setActualLeadDifference] = useState()
  useEffect(() => {
    if (ActualLead !== "" && ExpectLead !== '') {
      setActualLeadDifference(ActualLead - ExpectLead)
    }
  }, [ActualLead, ExpectLead]); //eslint-disable-line

  useEffect(() => {
    if (TargetCost !== '' && ActualCost !== '') {
      setActualCostDifference(TargetCost - ActualCost)
    }
  }, [TargetCost, ActualCost]); //eslint-disable-line
  useEffect(() => {
    if (ApproximateMonthly !== '' && TotalLeads !== '') {
      setActualCost(ApproximateMonthly / TotalLeads)
    }
  }, [ApproximateMonthly, TotalLeads]); //eslint-disable-line

  useEffect(() => {
    if (ActualLead !== '' && ExpectedWebsite !== '') {
      setTotalLeads(ActualLead * ExpectedWebsite / 100)
    }
  }, [ActualLead, ExpectedWebsite]); //eslint-disable-line

  useEffect(() => {
    if (TargetLead !== '' && TotalLeads !== '') {
      setTotalLeadsAbouve(TotalLeads - TargetLead)
    }
  }, [TargetLead, TotalLeads]); //eslint-disable-line

  useEffect(() => {
    const currencySymbol = locationData.currencySymbol;
    const data = [
      ["Boost With HMA"],
      [],
      ["Leads Campaign Goals", "", `Currency - ${currencySymbol}`],
      [],
      ["Your Leads Advertising Goals"],
      ["Target Leads for Campaign", "", `${formatNumber(TargetLead)}`],
      ["Target Cost Per Lead", "", `${currencySymbol} ${formatNumber(TargetCost)}`],

      ["Expected Spends", "", ""],
      ["Approximate Monthly Spend", "", `${currencySymbol} ${formatNumber(ApproximateMonthly)}`],
      ["Total Audience Reach", "", `${formatNumber(TotalAudience)}`],
      ["Expected Click Through Rate (CTR)", "", `% ${formatNumber(ExpectedClick)}`],
      ["Expected Average Cost Per Click (CPC)", "", `${currencySymbol} ${formatNumber(ExpectedAverage)}`],
      ["Expect Lead Conversion Rate", "", `% ${formatNumber(ExpectLead)}`],
      ["Expected Website Sessions from FB", "", `${formatNumber(ExpectedWebsite)}`],
      [],
      ["Actual Leads Results", "Success Rates", "Difference"],
      ["Actual Lead Conversion Rate", `% ${formatNumber(ActualLead)}`, `% ${formatNumber(ActualLeadDifference)}`],
      ["Actual Cost Per Lead", `${currencySymbol} ${formatNumber(ActualCost)}`, `${currencySymbol} ${formatNumber(ActualCostDifference)}`],
      [],
      ["Total Results vs. Expected Results", "Total Results", "Above/Below Target"],
      ["Total Leads", `${formatNumber(TotalLeads)}`, `${formatNumber(TotalLeadsAbouve)}`],
    ];
    setCsvData(data);
  }, [locationData, TargetLead, TargetCost, ApproximateMonthly, TotalAudience, ExpectedClick, ExpectedAverage, ExpectLead, ExpectedWebsite, ActualLead, ActualLeadDifference, ActualCost, ActualCostDifference, TotalLeads, TotalLeadsAbouve]);

  const downloadPdfDocument = () => {
    // const downloadPdfDocument = (rootElementId) => {
    // const input = document.getElementById(rootElementId);
    setDownloadingPdf(true);
    const input = document.getElementById('divToDownload');
    // html2canvas(input, { scale: 0.81 })
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF("p", "mm", "a4");
        // const width = pdf.internal.pageSize.getWidth() - 14;
        // const height = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 14;

        const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + 5;
        pdf.text("Boost With HMA", 5, 10);
        // pdf.addImage(imgData, 'JPEG', 5, 14);
        // pdf.addImage(imgData, 'JPEG', 5, 14);
        // pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight);
        pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight, "a", "FAST");

        pdf.save("download.pdf", { returnPromise: true })
          .then(() => {
            setDownloadingPdf(false);
          });
      })
  }

  var Loss = { backgroundColor: '#EB5757' }
  var Profit = { backgroundColor: '#8DD1B6' }
  const currencySymbol = locationData.currencySymbol;

  return (
    <Row justify="center" className='facg-main-wrapper' style={{ marginTop: '30px' }}>

      <BunnyPlayer type="LEADS_CAMPAIGN_GOALS" />

      <Col span={24} style={{ marginTop: "2rem", marginBottom: '6rem' }}>
        <Row justify="center">
          <Col span={20} id="divToDownload">
            <Row justify="space-between" style={{ marginBottom: 16, alignItems: 'center' }}>
              <Col>
                <Text level={5} style={{ fontWeight: "550", fontSize: 18 }}>
                  Leads Campaign Goals
                </Text>
              </Col>
              <Col>
                <Flex style={{ justifyContent: 'flex-end' }}>
                  <LocationDropdown defaultValue={locationData} onChange={(value) => { setLocationData(value) }} />
                </Flex>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <BlueBackground>
                  <Title>Your Leads Advertising Goals</Title>
                </BlueBackground>
              </Col>
              <Col span={24}>
                <WhiteBack>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Target Leads for Campaign </CalcText>
                      </Col>
                      <Col span={6}>
                        <Input
                          type="number"
                          value={TargetLead}
                          onChange={(e) =>
                            setTargetLead(e.target.value)
                          }
                          suffix={' '}
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Target Cost Per Lead</CalcText>
                      </Col>
                      <Col span={6}>
                        <Input type="number" prefix={currencySymbol} value={TargetCost}
                          onChange={(e) =>
                            setTargetCost(e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Padding>
                </WhiteBack>
              </Col>
              <Col span={24}>
                <BlueBackground>
                  <Title>Expected Spends</Title>
                </BlueBackground>
              </Col>
              <Col span={24}>
                <WhiteBack>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Approximate Monthly Spend </CalcText>
                      </Col>
                      <Col span={6}>
                        <Input type="number" prefix={currencySymbol}
                          value={ApproximateMonthly}
                          onChange={(e) =>
                            setApproximateMonthly(e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Total Audience Reach</CalcText>
                      </Col>
                      <Col span={6}>
                        <Input type="number"
                          value={TotalAudience}
                          onChange={(e) =>
                            setTotalAudience(e.target.value)
                          }
                          suffix={' '}
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Expected Click Through Rate (CTR)</CalcText>
                      </Col>
                      <Col span={6}>
                        <Input type="number"
                          value={ExpectedClick}
                          prefix={'%'}
                          onChange={(e) =>
                            setExpectedClick(e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>
                          Expected Average Cost Per Click (CPC)
                        </CalcText>
                      </Col>
                      <Col span={6}>
                        <Input type="number" prefix={currencySymbol}
                          value={ExpectedAverage}
                          onChange={(e) =>
                            setExpectedAverage(e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Expect Lead Conversion Rate</CalcText>
                      </Col>
                      <Col span={6}>
                        <Input prefix={'%'} type="number"
                          value={ExpectLead}
                          onChange={(e) =>
                            setExpectLead(e.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </Padding>
                  <Padding>
                    <Row justify="space-between">
                      <Col span={12}>
                        <CalcText>Expected Website Sessions from FB</CalcText>
                      </Col>
                      <Col span={6}>
                        <Input
                          type="number"
                          style={{ backgroundColor: '#F3F3F3' }}
                          value={ExpectedWebsite}
                          suffix={' '}
                          disabled
                        // onChange={(e)=>setExpectedWebsite(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Padding>
                </WhiteBack>
              </Col>
              <Col span={24}>
                <BlueBackground>
                  <Row align="middle">
                    <Col span={12}>
                      <Title>Actual Leads Results</Title>
                    </Col>
                    <Col span={6} align="center">
                      <Title>Success Rates</Title>
                    </Col>
                    <Col span={6} align="center">
                      <Title>Difference</Title>
                    </Col>
                  </Row>
                </BlueBackground>
              </Col>
              <Col span={24}>
                <WhiteBack>
                  <Padding>
                    <Row align="middle">
                      <Col span={6}>
                        <CalcText>Actual Lead Conversion Rate</CalcText>
                      </Col>
                      <Col offset={6} span={6} align="left">
                        <Input
                          type="number"
                          prefix={'%'}
                          value={ActualLead}
                          onChange={(e) =>
                            setActualLead(e.target.value)
                          }
                        />
                      </Col>
                      <Col span={6} align="left">
                        <Input
                          type="number"
                          prefix={'%'}
                          value={ActualLeadDifference}
                          style={ActualLeadDifference < -1 ? Loss : Profit}
                          // onChange={(e) =>
                          //   setActualLeadDifference(e.target.value)
                          // }
                          disabled
                        />
                      </Col>
                    </Row>
                  </Padding>

                  <Padding>
                    <Row align="middle">
                      <Col span={6}>
                        <CalcText>Actual Cost Per Lead </CalcText>
                      </Col>
                      <Col offset={6} span={6} align="left">
                        <Input
                          type="number"
                          value={ActualCost}
                          prefix={currencySymbol}
                          style={{ backgroundColor: '#F3F3F3' }}
                          onChange={(e) =>
                            setActualCost('')
                          }
                          disabled
                        />
                      </Col>
                      <Col span={6} align="left">
                        <Input
                          type="number"
                          prefix={currencySymbol}
                          value={ActualCostDifference}
                          style={ActualCostDifference < -1 ? Loss : Profit}
                          // onChange={(e) =>
                          //   setActualCostDifference(e.target.value)
                          // }
                          disabled
                        />
                      </Col>
                    </Row>
                  </Padding>
                </WhiteBack>
              </Col>
              <Col span={24}>
                <BlueBackground>
                  <Row align="left">
                    <Col span={12}>
                      <Title>Total Results vs. Expected Results</Title>
                    </Col>
                    <Col span={6} align="left">
                      <Title>Total Results</Title>
                    </Col>
                    <Col span={6} align="left">
                      <Title style={{ paddingRight: "20px" }}>
                        Above/Below Target
                      </Title>
                    </Col>
                  </Row>
                </BlueBackground>
              </Col>
              <Col span={24}>
                <WhiteBack>
                  <Padding>
                    <Row align="middle">
                      <Col span={6}>
                        <CalcText>Total Leads </CalcText>
                      </Col>
                      <Col offset={6} span={6} align="left">
                        <Input
                          type="number"
                          value={TotalLeads}
                          style={{ backgroundColor: '#F3F3F3' }}
                          disabled
                        />
                      </Col>
                      <Col span={6} align="left">
                        <Input
                          type="number"
                          value={TotalLeadsAbouve}
                          style={TotalLeadsAbouve < -1 ? Loss : Profit}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Padding>
                </WhiteBack>
              </Col>
            </Row>
          </Col>
        </Row>

        {
          authUser.accountType === "FREE" ?
            <Row justify="center" style={{ marginTop: '2rem' }}>
              <Popover content={subscribeToExport} className="">
                <Button className="primary-button not-allowed-button">Export as PDF</Button>
              </Popover>

              <Popover content={subscribeToExport} className="" >
                <Button className="primary-button not-allowed-button" style={{ marginLeft: '20px' }}>Export as CSV</Button>
              </Popover>
            </Row>
            :
            <Row justify="center">
              <Button className="primary-button mt-2rem" onClick={downloadPdfDocument} disabled={downloadingPdf}>{downloadingPdf ? 'Exporting...' : 'Export as PDF'}</Button>
              <CSVLink
                className="csv-download-button primary-button mt-2rem"
                data={csvData}
                onClick={() => {
                  // console.log("You click the link");
                  if (downloadingCsv) {
                    return false;
                  } else {
                    setDownloadingCsv(true);
                    const timeout = setTimeout(() => {
                      setDownloadingCsv(false);
                      clearTimeout(timeout);
                    }, 3000)
                  }
                }}
                filename={"fb-campaign-goals.csv"}
              >
                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
              </CSVLink>
            </Row>
        }
      </Col>
    </Row>
  );
}
