import Back from "./images/back.svg";
import styled from "styled-components";

export const Header = styled.h6`
    color: rgba(0, 109, 255, 0.8);
    margin-top: 5px;
    font-size: 25px;
    text-shadow: 1px 4px 4px #fcfcfc, 0 0 0 rgba(0, 0, 0, 1), 1px 4px 4px #fcfcfc;
    margin-bottom: 0;
    font-weight: 700;
    text-align:center;
    margin-bottom: 30px
`;

export const Container = styled.div`
    background-image: url(${Back});
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    padding: 0 100px 50px 100px
`;

export const Container2 = styled.div`
  background-image: url(${Back});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-repeat: repeat-y;
  padding-bottom: 80px;
`;

export const Button = styled.button`
  border: none;
  margin-top: 20px;
  background-color: #175bcb;
  border-radius: 10px;
  color: white;
  font-weight: semi-bold;
  padding: 10px 20px;
`;
