import React from 'react';
import { Line } from '@ant-design/charts';

const SingleLineChart = ({ totalImpressions, totalClicks, chart_data , XLableValue ,YLableValue }) => {
  let dataForChart = [];

  // Check if chart_data is provided and transform it if necessary
  if (chart_data == null) {

     // Generate a pattern for clicks based on impression values
     let direction = 1; // Direction of trend, start with increasing
     let currentClicks = 0;
 
     for (let i = 0; i <= totalImpressions; i += 1000) {
       const clicks = currentClicks;
       dataForChart.push({ date: i.toString(), value: clicks });
 
       // Adjust currentClicks to simulate a fluctuating trend
       currentClicks += direction * Math.round(Math.random() * 100); // Randomly change clicks
 
       // Ensure clicks stay within reasonable bounds
       if (currentClicks < 0) {
         currentClicks = 0;
         direction = 1; // Switch to increasing
       } else if (currentClicks > totalClicks) {
         currentClicks = totalClicks;
         direction = -1; // Switch to decreasing
       }
     }
  } else if (chart_data.length > 0) {
    dataForChart = chart_data.map(item => ({
      date: item.dimensionValues[0].value,  // Using dimension value as the x-axis label
      value: parseInt(item.metricValues[0].value, 10)  // Convert string to integer for y-axis
    })); 
  }

  const config = {
    data: dataForChart,
    xField: 'date',
    yField: 'value',
    xAxis: {
      title: {
        text: XLableValue,
        style: { fontWeight: 800, fontSize: 14 }
      },
      type: 'cat', // Ensure x-axis is treated as categories
      label: { autoRotate: true, autoHide: false, autoEllipsis: false ,  formatter: (text) => {
        // Custom formatter to add ellipsis if needed
        if (text.length > 15) { // Example threshold for ellipsis
          return `${text.slice(0, 10)}...`;
        }
        return text;
      },
    },
    },
    yAxis: {
      title: {
        text: YLableValue,
        style: { fontWeight: 800, fontSize: 14 }
      },
    },
    label: null, // Hide labels
    point: {
      size: 5,
      shape: 'diamond',
    },
    tooltip: {
      showMarkers: true,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [{ type: 'marker-active' }],
  };

  return (
    <div style={{ width: '95%' }}>
    <Line {...config} />
    </div>
  );
};

export default SingleLineChart;
