import { StarFilled, StarOutlined } from '@ant-design/icons'
import moment from 'moment'
import React from 'react'

const YoutubeAd = (props) => {
  const {
    item,
    index,
    handleAddToFav,
    handleRemoveFromFav,
    fetchDetails,
  } = props
  return (
    <div
      key={`url-${index}`}
      className="col-sm-4 mt-2"
      style={{ paddingLeft: '8px', paddingRight: '0px' }}
    >
      <div className="card h-100">
        <div className="">
          <div className="star-posi">
            {item.isFav || !handleAddToFav ? (
              <StarFilled
                style={{ cursor: 'pointer', color: '#175bcb' }}
                onClick={() => handleRemoveFromFav(item)}
              />
            ) : (
              <StarOutlined
                style={{ cursor: 'pointer', color: '#175bcb' }}
                onClick={() => handleAddToFav(item)}
              />
            )}
          </div>

          <iframe
            src={`https://www.youtube.com/embed/${item.videoId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{
              borderRadius: '2px',
              margin: '10px auto',
              border: '1px solid #ccc',
              height: '250px',
              width: '100%',
              display: 'block',
            }}
          ></iframe>

          <h5 className="card-title mt-3 mb-3">{item.title}</h5>
          <p className="adLine">
            <b>Channel -</b> {item.channel}
          </p>
          <p className="adLine">
            <b>Views -</b> {item.views}
          </p>
          <p className="adLine">
            <b>Last Seen -</b> {moment(item.lastSeen).format('DD/MM/YYYY')}
          </p>
          <p className="adLine">
            <b>Published On -</b>{' '}
            {moment(item.publishedOn).format('DD/MM/YYYY')}
          </p>
          <p className="adLine">
            <b>Tags -</b> {item.tags ? item.tags.join(',') : ''}
          </p>
        </div>
      </div>
    </div>
  )
}

export default YoutubeAd
