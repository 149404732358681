import React, { useState } from "react";
import styles from "./AdImageAnalyzer.module.css";
import { Header } from "../../styles/styles";
import UplaodIcon from "./upload.svg";
const Page = () => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };
  return (
    <div className={styles.adImageAnalyzer}>
      <Header>Ad Image Analyzer</Header>
      <p className={styles.heading}>
        Upload an image and draw regions to analyze patterns and cognitive
        metrics
      </p>
      <div className={styles.uploadContainer}>
        <div className={styles.uploadBox}>
          <input
            type="file"
            id="fileInput"
            className={styles.fileInput}
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/gif"
          />
          <label htmlFor="fileInput" className={styles.label}>
            <img
              src={UplaodIcon}
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.filename}>
              {fileName ? fileName : "Click to upload or drag and drop"}
            </p>
            <p className={styles.fileType}>PNG, JPG or GIF (MAX. 800x400px)</p>
          </label>
        </div>
      </div>
      <div className={styles.heatmapbtn}>
        <button>Generate Heat Map</button>
      </div>
    </div>
  );
};

export default Page;
