import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";

export const sortOptions = [
  {
    key: "1",
    value: "latest",
    text: "Latest",
  },
  {
    key: "2",
    value: "oldest",
    text: "Oldest",
  },
  {
    key: "3",
    value: "relevant",
    text: "Relevant",
  },
];

const AdsSpySortDropdown = (props) => {
  const { defaultValue, onChange } = props;
  console.log("default", defaultValue)
  const [value, setValue] = useState(sortOptions[0]);

  useEffect(() => {
    if (Object.keys(defaultValue).length !== 0) {
      setValue(defaultValue);
    }
  }, [props]);

  const onClickMenu = (item) => {
    setValue(item);
    onChange(item);
  };

  return (
    <div className="currency-wrapper text-center m-3 ">
      <div className="">
        <label className="currency-label">Sort By</label>
        <Dropdown
          overlay={
            <Menu>
              {sortOptions.map((item, key) => (
                <Menu.Item key={key} onClick={() => onClickMenu(item)}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {item.text}
                  </div>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomLeft"
          arrow
          className="currency-dropdown"
          AdsSpyLocationDropdown
        >
          <Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {value.text}
              <div
                className="mx-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <DownOutlined style={{ float: "right", marginTop: "6px" }} />
              </div>
            </div>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default AdsSpySortDropdown;
