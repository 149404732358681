import React, { useEffect } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  HOMEPAGE_HEADER,
  HOMEPAGE_SUB_HEADER,
  PAGE_HEAD_TITLE,
} from "../../constants";

const { Title } = Typography;

const Button = styled.button`
  border: none;
  margin-top: 20px;
  background-color: #175bcb;
  border-radius: 10px;
  color: white;
  font-weight: semi-bold;
  padding: 10px 20px;
  font-size: 15px;
`;

const Container = styled.div`
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-repeat: repeat-y;
  padding-bottom: 50px;
`;

const Header = styled.h1`
  color: rgba(0, 109, 255, 0.8);
  margin-top: 2rem;
  text-shadow: 1px 4px 4px #fcfcfc, 0 0 0 rgba(0, 0, 0, 1), 1px 4px 4px #fcfcfc;
  margin-bottom: 0rem;
  font-size: 48px;
  font-weight: 650;
`;

const Main = () => {
  useEffect(() => {
    document.title = `Dashboard ${PAGE_HEAD_TITLE}`;
  }, []);

  return (
    <Container>
      <Header>{HOMEPAGE_HEADER} </Header>
      <Title level={3} style={{ margin: "15px 0" }}>
        {HOMEPAGE_SUB_HEADER}
      </Title>

      {/* <div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/34531/c323584b-c04c-4bf8-84c4-f27fc0aa969c?autoplay=true" loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
       */}
      <div className="video-wrapper-dashboard">
        <iframe
          title="BoostWithHMA"
          src={`https://iframe.mediadelivery.net/embed/34531/c323584b-c04c-4bf8-84c4-f27fc0aa969c?autoplay=false`}
          loading={"lazy"}
          style={{
            borderRadius: "10px",
            boxShadow: "rgba(125, 120, 120, 0.75) 10px 5px 45px 4px",
            margin: "0 auto",
            border: "none",
            height: "100%",
            width: "100%",
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </div>
      <Link to="/plans">
        <Button style={{ marginTop: "2rem" }}>Subscribe Now</Button>
      </Link>
    </Container>
  );
};

export default Main;
