import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copy from '../../styles/icons/copy.png'

const ClipboardCopy = (props) => {
    const [isCopied, setIsCopied] = useState(false);

    const textCopiedHandler = () => {
        setIsCopied(true);
        const timeout = setTimeout(() => {
            setIsCopied(false);
            clearTimeout(timeout);
        }, 5000)
    }

    return (
        <CopyToClipboard text={props.text}
            onCopy={() => textCopiedHandler(1)}>
            <div className="right-align">
                <span className="clipboard-icon">
                    <img src={Copy} alt='copy' /> {isCopied ? <span className="color-green">Copied</span> : 'Copy to clipboard'}
                </span>
            </div>
        </CopyToClipboard>
    );
}

export default ClipboardCopy;
