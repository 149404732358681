import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Button, Modal, notification } from 'antd'
import axios from 'axios'
import { Timestamp, doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useState } from 'react'
import { FIREBASE_URL_STRIPE_CREATE_SUBSCRIPTION } from '../../constants'
import { Database } from '../../firebase'
import { locationOptions } from '../locationDropdown'
import CardInput from './CardInput'
import './stripe.css'

const PaymentComponent = (props) => {
  const {
    authUser,
    isModalVisible,
    setIsModalVisible,
    planSelected,
    planMonthlyYearly,
    currencySelected,
  } = props

  const stripe = useStripe()
  const elements = useElements()

  // const strSplit = authUser.displayName.split(' ');

  const [isPayClicked, setIsPayClicked] = useState(false)
  // const [error, setError] = useState('');
  // const [paymentUser, setPaymentUser] = useState({
  //     firstName: { value: strSplit[0], isDirty: false },
  //     lastName: { value: strSplit[1] ? strSplit[1] : '', isDirty: false },
  //     zipCode: { value: '', isDirty: false },
  //     // country: { value: '', isDirty: false },
  //     cardNumber: { value: '', isDirty: false },
  //     expiry: { value: '', isDirty: false },
  //     cvc: { value: '', isDirty: false },
  // });

  const purchasePlan = async () => {
    // console.log("isPayClicked clicked")

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: authUser.email,
      },
    })

    if (result.error) {
      // console.log(result.error.message);
    } else {
      const paymentMethod = result.paymentMethod.id

      // const userData = { ...paymentUser };

      // setPaymentUser(userData);

      setIsPayClicked(true)

      const plan = { ...planSelected }
      // console.log("plan:", plan)
      let item = planMonthlyYearly === 'MONTHLY' ? plan.monthly : plan.yearly
      // let currencyPrice = currencySelected === "INR" ? item.priceINR : item.priceUSD;
      const countrySelected = locationOptions.find(
        (item) => item.currencyCode === currencySelected,
      )

      const paymentObj = {
        customerUid: authUser.uid,
        customerName: authUser.displayName,
        // customerEmail: "customer21@example.com", //authUser.email,
        customerEmail: authUser.email,
        planId: plan.planId,
        planMonthlyYearly: planMonthlyYearly,
        countryCode: countrySelected.countryCode,
        currencyCode: countrySelected.currencyCode,
        paymentMethod: paymentMethod,
      }

      // console.log("paymentObj:", paymentObj)

      const data = await axios
        .post(FIREBASE_URL_STRIPE_CREATE_SUBSCRIPTION, paymentObj, {
          headers: { Accept: 'application/json' },
        })
        .then((response) => {
          // console.log("response:", response)
          if (response.status === 200) {
            // notification['success']({ message: 'Your payment was successful' });
            return response.data
          } else {
            return response
          }
        })
        .catch((error) => {
          // notification['error']({ message: 'Something went wrong with payment, Please try again later!', });
          // console.log("error:", error);
          // console.log("error.response:", error.response);
          notification['error']({
            message:
              error &&
              error.response &&
              error.response.data &&
              error.response.data.error
                ? error.response.data.error
                : 'Something went wrong!',
          })
        })

      if (data) {
        const { clientSecretIntent, statusIntent } = data

        // console.log("data:", data);

        // let completePayment = false;

        if (statusIntent === 'requires_action') {
          stripe.confirmCardPayment(clientSecretIntent).then(function (result) {
            if (result.error) {
              console.log('There was an issue!')
              // console.log(result.error);
              notification['error']({ message: result.error.message })
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            } else {
              console.log('You got the money!')
              // completePayment = "paid";
              paymentComplete(
                data,
                plan,
                item,
                countrySelected,
                planMonthlyYearly,
              )
              // Show a success message to your customer
            }
          })
        } else {
          console.log('You got the money!')
          // completePayment = "paid";
          paymentComplete(data, plan, item, countrySelected, planMonthlyYearly)
          // No additional information was needed
          // Show a success message to your customer
        }
      }
    }
  }

  const paymentComplete = async (
    data,
    plan,
    item,
    countrySelected,
    planMonthlyYearly,
  ) => {
    // console.log("data:", data);
    // console.log("plan:", plan);
    // console.log("item:", item);
    // console.log("countrySelected:", countrySelected);
    // console.log("planMonthlyYearly:", planMonthlyYearly);

    const userRef = doc(Database, 'users', authUser.uid)
    const expiryData = moment().add(item.validityDays, 'days')
    await updateDoc(userRef, {
      updatedDate: Timestamp.fromDate(moment().toDate()),
      subscriptionPlan: {
        price: data.price,
        planId: plan.planId,
        planMonthlyYearly: planMonthlyYearly,
        validityDays: item.validityDays,
        quotaAiRequest: item.quotaAiRequest,
        quotaGoogleKwResearch: item.quotaGoogleKwResearch,
        quotaKwResearch: item.quotaKwResearch,
        quotaBingKwResearch: item.quotaBingKwResearch,
        quotaSpyAdsKw: item.quotaSpyAdsKw,
        purchaseDate: Timestamp.fromDate(moment().toDate()),
        expiryDate: Timestamp.fromDate(expiryData.toDate()),
        stripeCustomerID: data.stripeCustomerID,
        stripeSubscriptionID: data.stripeSubscriptionID,
        hostedInvoiceUrl: data.hostedInvoiceUrl,
        invoicePdf: data.invoicePdf,
        // paymentStatus: data.paymentStatus, // "paid", "open"
        paymentStatus: 'paid', // "paid", "open"
        webHook: false,
        // zipCode: paymentUser.zipCode.value,
        country: countrySelected.countryCode,
        currencyCode: countrySelected.currencyCode,
      },
    })

    // if (data.paymentStatus !== "paid") {
    //     notification['success']({ message: 'You will be redirected to the 3DS secure payment page in a moment', });
    //     setTimeout(() => {
    //         // window.location.reload();
    //         window.location.href = data.hostedInvoiceUrl
    //     }, 2000)
    // } else {
    notification['success']({
      message: 'You have purchased a plan! The page will refresh in a moment',
    })
    setTimeout(() => {
      window.location.reload()
    }, 2000)
    // }

    // setIsModalVisible(false);
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // const handleInputForm = (data, field) => {
  //     const upData = { ...paymentUser };
  //     upData[field] = { value: data, isDirty: true };
  //     setPaymentUser(upData);
  //     setError('');
  // }

  // console.log("paymentUser:", paymentUser)
  // const defaultCountryValue = locationOptions.find(item => item.currencyCode === currencySelected).countryCode;

  return (
    <div className="">
      <Modal
        title="Make Payment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="pay-modal"
      >
        <div style={{ padding: '10px 30px 30px 30px' }}>
          <div style={{ margin: '20px 0 30px 0' }}>
            <CardInput />
          </div>

          {/* <span className="payment-input-err">{error}</span> */}

          {/* {!paymentUser.cardNumber.value || !paymentUser.expiry.value || !paymentUser.cvc.value
                    ?
                    <Button className="primary-button" disabled={true} block>
                        Pay Now
                    </Button>
                    : */}
          <Button
            className="primary-button"
            disabled={isPayClicked}
            onClick={purchasePlan}
            block
          >
            {isPayClicked ? 'Processing..' : 'Pay Now'}
          </Button>
          {/* <Button className="primary-button" onClick={purchasePlan} block>
                    {'Pay Now'}
                </Button> */}
          {/* } */}
          {/* <Button className="primary-button" disabled={isPayClicked} onClick={purchasePlan} block>
                    {isPayClicked ? 'Processing..' : 'Pay Now'}
                </Button> */}
        </div>
      </Modal>
    </div>
  )
}

export default PaymentComponent
