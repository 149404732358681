import React from "react";
import "./style.css";

const AmazonListingItem = props => {

    const { item } = props;

    return (
        <div className="card h-100">
            <div className="card-body">

                <img src={item.image_url} alt="pro" style={{ height: '300px' }} />

                <h5 className="card-title mt-3 mb-2">{item.title}</h5>
                <p className="adLine mb-2">{item.price_from}</p>
                <p className="adLine">URL - <a href={item.url} target="_blank" rel="noreferrer">Click here</a></p>
                <p className="adLine">Domain - {item.domain}</p>
                <p className="adLine">Rank - {item.rank_absolute}</p>
                <p className="adLine">Amazon Choice - {item.is_amazon_choice ? "YES" : "N/A"}</p>
                <p className="adLine">Best Seller - {item.is_best_seller ? "YES" : "N/A"}</p>
            </div>
        </div>
    )
}

export default AmazonListingItem;
