// import React, { useState } from "react";
// import BootstrapTable from "react-bootstrap-table-next";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import logo from "../../../styles/images/facebooklogo.svg";
// import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
// import "../components/TableWithTrends.css";
// import { calculatePercentageChange } from "../../../utils/Helpers";

import BootstrapTable from "react-bootstrap-table-next";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import { calculatePercentageChange } from "../../../utils/Helpers";
import { useState } from "react";

// // CustomTooltip component remains the same as in your code...
// const CustomTooltip = ({ children, tooltipText, date }) => {
//     const [isVisible, setIsVisible] = useState(false);
  
//     return (
//       <span
//         style={{ position: "relative", cursor: "pointer" }}
//         onMouseEnter={() => setIsVisible(true)}
//         onMouseLeave={() => setIsVisible(false)}
//       >
//         {children}
//         {isVisible && (
//           <div
//             style={{
//               position: "absolute",
//               top: "20px",
//               left: "-60",
//               backgroundColor: "#fff",
//               border: "1px solid #ddd",
//               borderRadius: "8px",
//               padding: "8px",
//               boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
//               whiteSpace: "nowrap",
//               fontSize: "12px",
//               zIndex: 10,
//             }}
//           >
          
//               {/* Tooltip Text with border */}
//               <div
//                 style={{
//                   borderBottom: "1px solid #ddd",
//                   borderRadius: "4px",
//                   padding: "4px",
//                   marginRight: "4px", // Space between the columns
//                   flex: 1, // Ensures this column takes up available space
//                 }}
//                 dangerouslySetInnerHTML={{ __html: tooltipText }}
//               />
                
//               {/* Date Field with border */}
//               <div
//                 style={{
//                   padding: "4px",
//                   flex: 0, // Keeps the date field size fixed
//                 }}
//               >
//                 Compare To : {date}
//               </div>
            
//           </div>
//         )}
//       </span>
//     );
//   };

//   function formatDates(...dateStrings) {
//     return dateStrings.map(dateString => {
//       const date = new Date(dateString);
//       const day = String(date.getDate()).padStart(2, "0");
//       const month = date.toLocaleString("en-IN", { month: "short" });
//       const year = date.getFullYear();
  
//       return `${day}-${month}-${year}`;
//     }).join(' ');
//   }



// // DeviceTable component modified to use sample data and correct field names
// const CampaginTable = ({ date,data,prvdata }) => {
//   // Formatters for trend indicators
//   const combinedData = data.map((currentItem) => {
//     // Find matching item in prevdata by `impression_device`
//     const previousItem = prvdata.find(
//       (prevItem) => prevItem.impression_device === currentItem.impression_device
//     );
  
//     // Current values
//     const currentSpend = parseFloat(currentItem.spend) || 0;
//     const currentLeads = parseFloat(
//       currentItem.actions?.find((action) => action.action_type === "lead")?.value
//     ) || 0;
//     const currentUniqueClicks = parseFloat(currentItem.unique_clicks) || 0;
//     const costPerLead = currentLeads > 0
//       ? (currentSpend / currentLeads).toFixed(2)
//       : 0;

//       const CPM = currentItem ? parseFloat(currentItem.cpm) : 0

//     // Previous values
//     const previousSpend = previousItem ? parseFloat(previousItem.spend) : 0;
//     const previousCpm = previousItem ? parseFloat(previousItem.cpm) : 0;
//     const previousLeads = previousItem
//       ? parseFloat(
//           previousItem.actions?.find((action) => action.action_type === "lead")
//             ?.value
//         ) || 0
//       : 0;
//     const previousUniqueClicks = previousItem
//       ? parseFloat(previousItem.unique_clicks)
//       : 0;
//     const costPrevLeads =
//       previousLeads > 0 ? (previousSpend / previousLeads).toFixed(2) : 0;
//         const prevDate = previousItem?.date_start
//         const prevEnd = previousItem?.date_stop
  
//     return {
//       ...currentItem,
//       previousDate : formatDates(
//         prevDate,
//         prevEnd
//       ),
//       previousamountSpent: previousSpend?.toFixed(2),
//       previouscpm :  previousCpm?.toFixed(2),
//       previousleads: previousLeads,
//       previousuniqueClick: previousUniqueClicks,
//       previouscostperleads: costPrevLeads,
//       amountSpent: currentSpend?.toFixed(2),
//       leads: currentLeads,
//       costperleads: costPerLead,
//       cpm : CPM?.toFixed(2),
//       uniqueClick: currentUniqueClicks,
//       reachChange: calculatePercentageChange(
//         parseFloat(currentItem.cpm),
//         parseFloat(previousItem?.cpm || 0)
//       ),
//       amountSpentChange: calculatePercentageChange(currentSpend, previousSpend),
//       cpmChange : calculatePercentageChange(CPM, previousCpm),
//       leadsChange: calculatePercentageChange(currentLeads, previousLeads),
//       costperleads:
//       currentLeads > 0 ? (currentSpend / currentLeads).toFixed(2) : 0,
//       uniqueClickChange: calculatePercentageChange(
//         currentUniqueClicks,
//         previousUniqueClicks
//       ),
//       costperleadsChange: calculatePercentageChange(costPerLead, costPrevLeads),

//     };
//   });
//   const createDynamicFormatter = (label, valueKey) => (cell, row) => {
//     const previousValue = row[`previous${valueKey}`];
//     const value = row[valueKey];
//     const changeValue = row[`${valueKey}Change`] || 0;
//     const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${
//       changeValue > 0 ? "+" : ""
//     }${changeValue.toFixed(2)}%`;

//     return (
//       <span>
//         ₹ {value}{" "}
//         <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
//           <div
//             style={{
//               display: "inline-flex",
//               alignItems: "center",
//               padding: "4px 10px",
//               borderRadius: "10px",
//               backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
//               color: changeValue > 0 ? "#3C763D" : "#A94442",
//             }}
//           >
//             {changeValue > 0 ? (
//               <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
//             ) : (
//               <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
//             )}
//             <span style={{ fontWeight: "400", fontSize: "12px" }}>
//               {Math.abs(changeValue).toFixed(2)}%
//             </span>
//           </div>
//         </CustomTooltip>
//       </span>
//     );
//   };

//   const ValueFormatter = (label, valueKey) => (cell, row) => {
//     const previousValue = row[`previous${valueKey}`];
//     const value = row[valueKey];
//     const changeValue = row[`${valueKey}Change`] || 0;
//     const tooltipText = `${label}: <strong>${previousValue}</strong> | ${
//       changeValue > 0 ? "+" : ""
//     }${changeValue.toFixed(2)}%`;

//     return (
//       <span>
//         {value}{" "}
//         <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
//           <div
//             style={{
//               display: "inline-flex",
//               alignItems: "center",
//               padding: "4px 10px",
//               borderRadius: "10px",
//               backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
//               color: changeValue > 0 ? "#3C763D" : "#A94442",
//             }}
//           >
//             {changeValue > 0 ? (
//               <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
//             ) : (
//               <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
//             )}
//             <span style={{ fontWeight: "400", fontSize: "12px" }}>
//               {Math.abs(changeValue).toFixed(2)}%
//             </span>
//           </div>
//         </CustomTooltip>
//       </span>
//     );
//   };

//   const columns = [
//     {
//       dataField: "campaign_name",
//       text: "Campaign Name",
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//     {
//       dataField: "amountSpent",
//       text: "Amount Spent",
//       formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//     {
//       dataField: "cpm",
//       text: "CPM",
//       formatter: createDynamicFormatter("CPM", "cpm"),
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//     {
//       dataField: "uniqueClick",
//       text: "Unique Clicks",
//       formatter: ValueFormatter("Unique Clicks", "uniqueClick"),
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//     {
//       dataField: "leads",
//       text: "Leads (All)",
//       formatter: ValueFormatter("Leads", "leads"),
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//     {
//       dataField: "costPerLead",
//       text: "Cost Per Lead (All)",
//       formatter: createDynamicFormatter("Cost Per Lead", "costPerLead"),
//       headerStyle: { fontWeight: "600", fontSize: "13px" },
//     },
//   ];

//   return (
//     <div className="table-card">
//       <h5 className="card-title">
//         <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
//         Performance Analysis for {date} by Campaign Name
//       </h5>
//       <p className="text-muted">
//         Amount Spent, CPM, Unique Clicks, Leads (All), Cost Per Lead (All)
//       </p>
//       <div className="table-scroll-wrapper">
//         <BootstrapTable
//           keyField="campaignName"
//           data={combinedData}
//           columns={columns}
//           bordered={false}
//           hover
//           condensed
//           rowStyle={{ border: "none !important" }}
//           classes="custom-table"
//         />
//       </div>
//     </div>
//   );
// };

// export default CampaginTable;


const CustomTooltip = ({ children, tooltipText, date }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    return (
      <span
        style={{ position: "relative", cursor: "pointer" }}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
        {isVisible && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: "-60",
              backgroundColor: "#fff",
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
              whiteSpace: "nowrap",
              fontSize: "12px",
              zIndex: 10,
            }}
          >
          
              {/* Tooltip Text with border */}
              <div
                style={{
                  borderBottom: "1px solid #ddd",
                  borderRadius: "4px",
                  padding: "4px",
                  marginRight: "4px", // Space between the columns
                  flex: 1, // Ensures this column takes up available space
                }}
                dangerouslySetInnerHTML={{ __html: tooltipText }}
              />
                
              {/* Date Field with border */}
              <div
                style={{
                  padding: "4px",
                  flex: 0, // Keeps the date field size fixed
                }}
              >
                Compare To : {date}
              </div>
            
          </div>
        )}
      </span>
    );
  };

  function formatDates(...dateStrings) {
    return dateStrings.map(dateString => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("en-IN", { month: "short" });
      const year = date.getFullYear();
  
      return `${day}-${month}-${year}`;
    }).join(' ');
  }

const CampaginTable = ({ date, data, prvdata }) => {
    // Formatters for trend indicators
    const combinedData = data.map((currentItem) => {
      // Find matching item in prvdata by `campaign_name`
      const previousItem = prvdata.find(
        (prevItem) => prevItem.id === currentItem.id
      );
  
      // If there's no previous item, return the current item with no changes
      if (!previousItem) {
        return {
          ...currentItem,
          previousDate: "No Previous Data",
          amountSpentChange: 0,
          cpmChange: 0,
          leadsChange: 0,
          costperleadsChange: 0,
          uniqueClickChange: 0,
          costPerLeadChange: 0,
          amountSpent: currentItem?.spend,
          cpm: currentItem.cpm,
          leads: currentItem.actions?.find(action => action.action_type === "lead")?.value || 0,
          uniqueClick: currentItem.unique_clicks || 0,
          costPerLead: currentItem?.spend / (currentItem.actions?.find(action => action.action_type === "lead")?.value || 1),
        };
      }
  
      // Current values
      const currentSpend = parseFloat(currentItem?.spend) || 0;
      const currentLeads = parseFloat(
        currentItem?.actions?.find((action) => action.action_type === "lead")?.value
      ) || 0;
      const currentUniqueClicks = parseFloat(currentItem.unique_clicks) || 0;
      const costPerLead = currentLeads > 0
        ? (currentSpend / currentLeads).toFixed(2)
        : 0;
      const CPM = currentItem?.cpm ? parseFloat(currentItem?.cpm) : 0;
  
      // Previous values
      const previousSpend = previousItem ? parseFloat(previousItem.spend) : 0;
      const previousCpm = previousItem ? parseFloat(previousItem.cpm) : 0;
      const previousLeads = previousItem
        ? parseFloat(
            previousItem.actions?.find((action) => action?.action_type === "lead")
              ?.value
          ) || 0
        : 0;
      const previousUniqueClicks = previousItem
        ? parseFloat(previousItem.unique_clicks)
        : 0;
      const costPrevLeads =
        previousLeads > 0 ? (previousSpend / previousLeads).toFixed(2) : 0;
      const prevDate = previousItem?.date_start;
      const prevEnd = previousItem?.date_stop;
  
      return {
        ...currentItem,
        previousDate: formatDates(prevDate, prevEnd),
        previousamountSpent: previousSpend?.toFixed(2),
        previouscpm: previousCpm?.toFixed(2),
        previousleads: previousLeads,
        previousuniqueClick: previousUniqueClicks,
        previouscostperleads: costPrevLeads,
        amountSpent: currentSpend?.toFixed(2),
        leads: currentLeads,
        costperleads: costPerLead,
        cpm: CPM?.toFixed(2),
        uniqueClick: currentUniqueClicks,
        reachChange: calculatePercentageChange(
          parseFloat(currentItem.cpm),
          parseFloat(previousItem?.cpm || 0)
        ),
        amountSpentChange: calculatePercentageChange(currentSpend, previousSpend),
        cpmChange: calculatePercentageChange(CPM, previousCpm),
        leadsChange: calculatePercentageChange(currentLeads, previousLeads),
        costperleadsChange: calculatePercentageChange(costPerLead, costPrevLeads),
        uniqueClickChange: calculatePercentageChange(
          currentUniqueClicks,
          previousUniqueClicks
        ),
      };
    });

    console.log("combinedData",combinedData)
  
    const createDynamicFormatter = (label, valueKey) => (cell, row) => {
      const previousValue = row[`previous${valueKey}`];
      const value = row[valueKey];
      const changeValue = row[`${valueKey}Change`] || 0;
  
      // No previous data, do not show trend
      if (previousValue === "No Previous Data") {
        return <span>₹ {value}</span>;
      }
  
      const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${changeValue > 0 ? "+" : ""}${changeValue.toFixed(2)}%`;
  
      return (
        <span>
          ₹ {value}{" "}
          <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "4px 10px",
                borderRadius: "10px",
                backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
                color: changeValue > 0 ? "#3C763D" : "#A94442",
              }}
            >
              {changeValue > 0 ? (
                <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
              ) : (
                <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
              )}
              <span style={{ fontWeight: "400", fontSize: "12px" }}>
                {Math.abs(changeValue).toFixed(2)}%
              </span>
            </div>
          </CustomTooltip>
        </span>
      );
    };
  
    const ValueFormatter = (label, valueKey) => (cell, row) => {
      const previousValue = row[`previous${valueKey}`];
      const value = row[valueKey];
      const changeValue = row[`${valueKey}Change`] || 0;
  
      // No previous data, do not show trend
      if (previousValue === "No Previous Data") {
        return <span>{value}</span>;
      }
  
      const tooltipText = `${label}: <strong>${previousValue}</strong> | ${changeValue > 0 ? "+" : ""}${changeValue.toFixed(2)}%`;
  
      return (
        <span>
          {value}{" "}
          <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "4px 10px",
                borderRadius: "10px",
                backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
                color: changeValue > 0 ? "#3C763D" : "#A94442",
              }}
            >
              {changeValue > 0 ? (
                <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
              ) : (
                <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
              )}
              <span style={{ fontWeight: "400", fontSize: "12px" }}>
                {Math.abs(changeValue).toFixed(2)}%
              </span>
            </div>
          </CustomTooltip>
        </span>
      );
    };
  
    const columns = [
      {
        dataField: "campaign_name",
        text: "Campaign Name",
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
      {
        dataField: "amountSpent",
        text: "Amount Spent",
        formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
      {
        dataField: "cpm",
        text: "CPM",
        formatter: createDynamicFormatter("CPM", "cpm"),
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
      {
        dataField: "uniqueClick",
        text: "Unique Clicks",
        formatter: ValueFormatter("Unique Clicks", "uniqueClick"),
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
      {
        dataField: "leads",
        text: "Leads (All)",
        formatter: ValueFormatter("Leads", "leads"),
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
      {
        dataField: "costperleads",
        text: "Cost Per Lead (All)",
        formatter: createDynamicFormatter("Cost Per Lead", "costperleads"),
        headerStyle: { fontWeight: "600", fontSize: "13px" },
      },
    ];
  
    return (
      <div className="table-card">
        <h5 className="card-title">
          <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
          Performance Analysis for {date} by Campaign Name
        </h5>
        <p className="text-muted">
          Amount Spent, CPM, Unique Clicks, Leads (All), Cost Per Lead (All)
        </p>
        <div className="table-scroll-wrapper">
          <BootstrapTable
            keyField="campaign_name"
            data={combinedData}
            columns={columns}
            bordered={false}
            hover
            condensed
            rowStyle={{ border: "none !important" }}
            classes="custom-table"
            noDataIndication={() => <div>No data available</div>}
          />
        </div>
      </div>
    );
  };
  
  export default CampaginTable;
  