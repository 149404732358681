import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Input, notification, Space, Button } from "antd";
import { LockOutlined, LogoutOutlined, MailOutlined } from "@ant-design/icons";
import { PAGE_HEAD_TITLE } from "../../constants";
import logo from "../../styles/images/logo.svg";
import hmaicon from "../../styles/images/hmaicon.png";
import "./auth.css";

const Login = (props) => {
  const history = useHistory();
  // const provider = new GoogleAuthProvider();

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [formError, setFormError] = useState("");

  useEffect(() => {
    document.title = `Login ${PAGE_HEAD_TITLE}`;
    document.body.classList.add("bodyoverflowunset");
    return () => {
      document.body.classList.remove("bodyoverflowunset");
    };
  }, []);

  const handleLogin = () => {
    setFormError("");
    let errorMessage = "";

    /* eslint-disable-next-line */
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (userEmail === "") {
      errorMessage = "Please enter your email!";
    } else if (regex.test(userEmail) === false) {
      errorMessage = "Please enter valid email!";
    } else if (userPassword === "") {
      errorMessage = "Please enter password!";
    }

    if (errorMessage) {
      setFormError(errorMessage);
    } else {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, userEmail, userPassword)
        .then((userCredential) => {
          var { refreshToken, uid } = userCredential.user;
          localStorage.setItem("token", refreshToken);
          localStorage.setItem("uid", uid);
          history.push("/dashboard");
          openNotificationWithIcon("success", "Welcome to Boost With HMA");
        })
        .catch((error) => {
          var errorCode = error.code;
          openNotificationWithIcon("error", errorCode);
          errorMessage = error.message;
          // setFormError(errorMessage);
          // console.log('errorCode', errorCode)
          // console.log('errorMessage', errorMessage)
        });
    }
  };

  // const handleGoogleLogin = () => {
  //     const auth = getAuth();
  //     signInWithPopup(auth, provider)
  //         .then(async (result) => {
  //             // this is handled on app.js - onAUthStateChange
  //             // This gives you a Google Access Token. You can use it to access the Google API.
  //             // const credential = GoogleAuthProvider.credentialFromResult(result);
  //             // console.log('credential', credential)
  //             // const token = credential.accessToken;
  //             // The signed-in user info.
  //             // const user = result.user;
  //             // console.log('user', user)

  //             // const docRef = doc(Database, "users", user.uid);
  //             // const docSnap = await getDoc(docRef);

  //             // if (docSnap.exists()) {
  //             //     await updateDoc(docRef, {
  //             //         name: user.displayName,
  //             //         updatedDate: Timestamp.fromDate(moment().toDate()),
  //             //     });
  //             //     console.log("update user.displayName:", user.displayName)
  //             // } else {
  //             //     console.log("create user.displayName:", user.displayName)
  //             //     await setDoc(doc(Database, "users", user.uid), {
  //             //         email: user.email,
  //             //         name: user.displayName,
  //             //         createdDate: Timestamp.fromDate(moment().toDate()),
  //             //     });
  //             // }
  //             history.push('/dashboard')
  //         }).catch((error) => {
  //             // Handle Errors here.
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // The email of the user's account used.
  //             const email = error.email;
  //             // The AuthCredential type that was used.
  //             const credential = GoogleAuthProvider.credentialFromError(error);
  //             console.log('errorCode', errorCode)
  //             console.log('errorMessage', errorMessage)
  //             console.log('email', email)
  //             console.log('credential', credential)
  //             openNotificationWithIcon('error', errorCode)
  //         });
  // }

  // const handleFacebookLogin = () => {
  //     const provider = new FacebookAuthProvider();

  //     const auth = getAuth();
  //     signInWithPopup(auth, provider)
  //         .then((result) => {
  //             // The signed-in user info.
  //             // const user = result.user;

  //             // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //             const credential = FacebookAuthProvider.credentialFromResult(result);
  //             const accessToken = credential.accessToken;

  //             console.log("accessToken:", accessToken)

  //             // ...
  //         })
  //         .catch((error) => {
  //             // Handle Errors here.
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // The email of the user's account used.
  //             // const email = error.email;
  //             // The AuthCredential type that was used.
  //             const credential = FacebookAuthProvider.credentialFromError(error);
  //             console.log("errorCode:", errorCode)
  //             console.log("errorMessage:", errorMessage)
  //             console.log("credential:", credential)
  //             // ...
  //         });
  // }

  const openNotificationWithIcon = (type, msg) => {
    if (msg === "auth/user-not-found") {
      notification[type]({
        message: "Email address does not exist with any account!",
      });
      setFormError("Email address does not exist with any account!");
    } else if (msg === "auth/wrong-password") {
      notification[type]({
        message: "Please enter correct password!",
      });
      setFormError("Please enter correct password!");
    } else if (msg === "auth/popup-closed-by-user") {
      notification[type]({
        message: "Login cancelled!",
      });
      setFormError("Login cancelled!");
    } else {
      type === "error" && setFormError(msg);
      notification[type]({
        message: msg,
      });
    }
  };

  return (
    <div className="main-component-signup">
      <img src={logo} alt="Boost with HMA" className="top-logo-signup-img" />
      <div className="auth-container">
        {/* form with logo and text */}
        <div className="text-center mb-4">
          <img src={hmaicon} alt="Boost with HMA" />
        </div>
        <div className="signup-content">
          <form
            id="signup-form"
            className="signup-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <h2>Welcome Back 👋</h2>
            <p>#1 Performance marketing tool</p>
            <span className="invalid-feedback d-block">{formError}&nbsp;</span>
            <div className="form-group form-input-field">
              <Input
                type="text"
                className="form-input"
                placeholder="Please enter your email address"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                prefix={
                  <MailOutlined style={{ fontSize: 20, marginRight: 10 }} />
                }
              />
            </div>
            <div className="form-group form-input-field">
              <Input
                type="password"
                className="form-input"
                placeholder="Please enter password"
                value={userPassword}
                prefix={
                  <LockOutlined style={{ fontSize: 20, marginRight: 10 }} />
                }
                onChange={(e) => setUserPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </div>
            <div className="form-group login-options">
              <div className="row align-items-center small">
                <div className="col-sm-12 text-end text-semibold fw-600">
                  <Link to="/reset-password" className="forget-link">
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </div>
            <div className="form-group text-center mb-0 ">
              <Button size="large" onClick={handleLogin} type="primary w-100">
                Login <LogoutOutlined style={{ verticalAlign: "middle" }} />
              </Button>
            </div>
          </form>
          <div className="text-center mt-4">
            Don’t have an Account?{" "}
            <Link to="/register" className="text-info">
              Register
            </Link>
          </div>
        </div>
        
      </div>
      <footer className="footer-content">
        © 2024 All Rights Reserved. HMA TECH & MARKETING SOLUTIONS LLC |{" "}
        <a
          href="https://www.marketingwithhma.com"
          target="_blank"
          rel="noreferrer"
        >
          www.marketingwithhma.com
        </a>
        | Privacy Policy | Terms & Conditions
      </footer>
    </div>
  );
};

export default Login;
