import axios from 'axios'
import {
  FIREBASE_URL_CUR_CONVERTOR,
  FIREBASE_URL_KW_IDEAS,
  FIREBASE_URL_KW_RANKED,
  FIREBASE_URL_KW_RELATED_AND_SUGGESTED,
  firebaseURL,
} from '../constants'

/**
 * Get Google/Yuotube Lab KeyWords
 */

export const getKeywordResearch = async (data, searchType) => {
  const currencyCode = data.currency_code
  const currencySymbol = data.currency_symbol

  let currencyConversionData = {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
  }

  if (currencyCode !== 'USD') {
    const conversionData = await axios
      .post(
        FIREBASE_URL_CUR_CONVERTOR,
        {
          currencyCode: currencyCode,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
      .then((response) => {
        // console.log(response.data);
        return response.data
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error);
      })
    // console.log("conversionData:", conversionData)
    if (conversionData) {
      currencyConversionData = {
        ...currencyConversionData,
        ...conversionData,
      }
    }
  }

  let resultKw
  resultKw = await axios
    .post(
      FIREBASE_URL_KW_RELATED_AND_SUGGESTED,
      {
        searchType: searchType,
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      // console.log("response:", response)
      return response.data
    })
    .catch((error) => {
      // console.log(error);
    })

  let resultKw2
  if (searchType === 'RELATED_KEYWORDS') {
    resultKw2 = await axios
      .post(
        FIREBASE_URL_KW_IDEAS,
        {
          data: data,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
      .then((response) => {
        // console.log("response:", response)
        return response.data
      })
      .catch((error) => {
        // console.log(error);
      })
  }

  let apidata = []
  if (resultKw) {
    if (searchType === 'RELATED_KEYWORDS') {
      apidata = structuredSchemaRelatedKeywords(
        resultKw,
        currencyConversionData,
      )
    } else {
      apidata = structuredSchemaKeywordSuggestions(
        resultKw,
        currencyConversionData,
      )
    }
  }
  if (resultKw2) {
    let apidata2 = structuredSchemaKeywordIdeas(
      resultKw2,
      currencyConversionData,
    )
    // console.log("apidata2:", apidata2);
    apidata = apidata.concat(apidata2)
  }
  // console.log("apidata:", apidata)

  return apidata
}

export const getGoogleKeywordSearch = async (data, searchType) => {
  const currencyCode = data.currency_code
  const currencySymbol = data.currency_symbol

  let currencyConversionData = {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
  }

  if (currencyCode !== 'USD') {
    const conversionData = await axios
      .post(
        FIREBASE_URL_CUR_CONVERTOR,
        {
          currencyCode: currencyCode,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
      .then((response) => {
        // console.log(response.data);
        return response.data
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error);
      })
    // console.log("conversionData:", conversionData)
    if (conversionData) {
      currencyConversionData = {
        ...currencyConversionData,
        ...conversionData,
      }
    }
  }

  let resultKw
  resultKw = await axios
    .post(
      FIREBASE_URL_KW_RELATED_AND_SUGGESTED,
      {
        searchType: 'GOOGLE_ADS_PLANNER',
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      // console.log(error);
    })

  let apidata = []
  if (resultKw) {
    apidata = structuredGoogleAdsPlannerData(
      resultKw,
      currencyConversionData,
      searchType,
    )
  }

  return apidata
}

export const getBingKeywordSearch = async (data, searchType) => {
  const currencyCode = data.currency_code
  const currencySymbol = data.currency_symbol

  let currencyConversionData = {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
  }

  if (currencyCode !== 'USD') {
    const conversionData = await axios
      .post(
        FIREBASE_URL_CUR_CONVERTOR,
        {
          currencyCode: currencyCode,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
      .then((response) => {
        // console.log(response.data);
        return response.data
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error);
      })
    if (conversionData) {
      currencyConversionData = {
        ...currencyConversionData,
        ...conversionData,
      }
    }
  }

  let resultKw
  resultKw = await axios
    .post(
      FIREBASE_URL_KW_RELATED_AND_SUGGESTED,
      {
        searchType: 'BING_ADS_PLANNER',
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      // console.log(error);
    })

  let apidata = []
  if (resultKw) {
    apidata = structuredGoogleAdsPlannerData(
      resultKw,
      currencyConversionData,
      searchType,
    )
  }

  return apidata
}

export const getRankedKeywords = async (data) => {
  const currencyCode = data.currency_code
  const currencySymbol = data.currency_symbol

  let currencyConversionData = {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
  }

  if (currencyCode !== 'USD') {
    const conversionData = await axios
      .post(
        FIREBASE_URL_CUR_CONVERTOR,
        {
          currencyCode: currencyCode,
        },
        {
          headers: { Accept: 'application/json' },
        },
      )
      .then((response) => {
        // console.log(response.data);
        return response.data
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error);
      })
    // console.log("conversionData:", conversionData)
    if (conversionData) {
      currencyConversionData = {
        ...currencyConversionData,
        ...conversionData,
      }
    }
  }

  const resultKw = await axios
    .post(
      FIREBASE_URL_KW_RANKED,
      {
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      // console.log("response:", response)
      return response.data
    })
    .catch((error) => {
      // console.log(error);
    })

  let apidata = []
  if (resultKw) {
    apidata = structuredSchemaKeywordRanked(resultKw, currencyConversionData)
  }

  return apidata
}

export const getSpyShoppingAdsTaskId = async (data) => {
  const currencyCode = data.currency_code
  const currencySymbol = data.currency_symbol

  let currencyConversionData = {
    currencyCode: currencyCode,
    currencySymbol: currencySymbol,
  }

  // if (currencyCode !== "USD") {
  //     const conversionData = await axios.post(FIREBASE_URL_CUR_CONVERTOR, {
  //         currencyCode: currencyCode
  //     }, {
  //         headers: { 'Accept': 'application/json' }
  //     }).then(response => {
  //         // console.log(response.data);
  //         return response.data;
  //     }).catch((error) => {
  //         console.log(error.response.data);
  //         // console.log(error);
  //     });
  //     // console.log("conversionData:", conversionData)
  //     if (conversionData) {
  //         currencyConversionData = {
  //             ...currencyConversionData,
  //             ...conversionData
  //         }
  //     }
  // }

  const resultKw = await axios
    .post(
      firebaseURL.SPY_SHOPPING_ADS_TASK_ID,
      {
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      // console.log("response:", response)
      return response.data
    })
    .catch((error) => {
      // console.log(error);
    })

  // let apidata = [];
  // if (resultKw) {
  //     apidata = structuredSchemaKeywordRanked(resultKw, currencyConversionData)
  // }
  let apidata = {
    taskId: resultKw,
    currencyConversionData,
  }
  // console.log("getSpyShoppingAdsTaskId:", apidata)

  return apidata
}

export const getSpyShoppingAdsTaskData = async (
  data,
  currencyConversionData,
) => {
  // const taskId = data.id;

  const resultKw = await axios
    .post(
      firebaseURL.SPY_SHOPPING_ADS_TASK_DATA,
      {
        data: data,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => {
      // console.log("response:", response)
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })

  let apidata = []
  if (resultKw && resultKw.status_code === 20000 && resultKw.result) {
    // console.log("currencyConversionData:", currencyConversionData)

    let fdata = structuredSchemaSpyShoppingAds(
      resultKw.result,
      currencyConversionData,
    )
    apidata = {
      result: fdata,
      status_code: resultKw.status_code,
      status_message: resultKw.status_message,
    }
    // let apidata = resultKw;
    // console.log("getSpyShoppingAdsTaskData:", apidata)
  } else {
    apidata = resultKw
  }

  return apidata
}

export const structuredSchemaRelatedKeywords = (
  response,
  currencyConversionData,
) => {
  const data = response.map((ele) => {
    return {
      keyword: ele.keyword_data.keyword,
      searchVolume: ele.keyword_data.keyword_info.search_volume,
      monthlySearches: ele.keyword_data.keyword_info.monthly_searches,
      cpc: ele.keyword_data.keyword_info.cpc,
      competition: ele.keyword_data.keyword_info.competition,
    }
  })

  const outputFormat = formatData(data, currencyConversionData)

  return outputFormat
}

export const structuredSchemaKeywordIdeas = (
  response,
  currencyConversionData,
) => {
  const data = response.map((ele) => {
    return {
      keyword: ele.keyword,
      searchVolume: ele.keyword_info.search_volume,
      monthlySearches: ele.keyword_info.monthly_searches,
      cpc: ele.keyword_info.cpc,
      competition: ele.keyword_info.competition,
    }
  })

  const outputFormat = formatData(data, currencyConversionData)

  return outputFormat
}

export const structuredSchemaKeywordSuggestions = (
  response,
  currencyConversionData,
) => {
  const data = response.map((ele) => {
    return {
      keyword: ele.keyword,
      searchVolume: ele.keyword_info.search_volume,
      monthlySearches: ele.keyword_info.monthly_searches,
      cpc: ele.keyword_info.cpc,
      competition: ele.keyword_info.competition,
    }
  })

  const outputFormat = formatData(data, currencyConversionData)

  return outputFormat
}

export const structuredGoogleAdsPlannerData = (
  response,
  currencyConversionData,
  type,
) => {
  let data
  //   if (type === 'SEARCH_VOLUME' || type === 'KEYWORDS_SITE') {
  data = response.map((ele) => {
    return {
      keyword: ele.keyword,
      searchVolume: ele.search_volume,
      monthlySearches: ele.monthly_searches ? ele.monthly_searches : [],
      cpc: ele.cpc,
      competition: ele.competition,
    }
  })
  //   }

  const outputFormat = formatData(data, currencyConversionData)

  return outputFormat
}

const formatData = (input, currencyConversionData) => {
  let conversionRate
  if (currencyConversionData.currencyCode !== 'USD') {
    if (currencyConversionData.result) {
      conversionRate =
        currencyConversionData.result[currencyConversionData.currencyCode]
    }
  }

  const output = input.map((ele) => {
    const monthlySearches = []
    const monthlySearchesData = []
    ele.monthlySearches.map((item) => {
      monthlySearches.push(item.search_volume)
      // monthlySearchesData.push([`${item.month}/${item.year}`, item.search_volume]);
      monthlySearchesData.push({
        datetime: `${item.month}/${item.year}`,
        search_volume: item.search_volume,
      })
      return item
    })

    const competition = Math.floor(ele.competition * 100)

    let cpc = ele.cpc
    if (cpc) {
      if (conversionRate) {
        // const conversionRate = currencyConversionData.result[currencyConversionData.currencyCode];
        // console.log('conversionRate:', conversionRate);
        cpc = cpc * conversionRate
        cpc = cpc.toFixed(2)
        cpc = `${currencyConversionData.currencySymbol} ${cpc}`
      } else {
        cpc = cpc.toFixed(2)
        cpc = `$ ${cpc}`
      }
    } else {
      cpc = '-'
    }

    return {
      keyword: ele.keyword,
      searchVolume: ele.searchVolume,
      monthlySearches: monthlySearches,
      monthlySearchesData: monthlySearchesData.reverse(),
      cpc: cpc,
      competition: competition ? competition : 1,
    }
  })
  return output
}

export const structuredSchemaKeywordRanked = (
  response,
  currencyConversionData,
) => {
  const data = response.map((ele) => {
    return {
      keyword: ele.keyword_data.keyword,
      position: ele.keyword_data.impressions_info.ad_position_average,
      cpc: ele.keyword_data.keyword_info.cpc,
      searchVolume: ele.keyword_data.keyword_info.search_volume,
      url: ele.keyword_data.serp_info.check_url,
      traffic: ele.keyword_data.impressions_info.daily_impressions_average,
      monthlySearches: ele.keyword_data.keyword_info.monthly_searches,
      serpItem: ele.ranked_serp_element.serp_item,
      // competition: ele.keyword_info.competition,
    }
  })

  // const outputFormat = formatData(data, currencyConversionData);
  let conversionRate
  if (currencyConversionData.currencyCode !== 'USD') {
    if (currencyConversionData.result) {
      conversionRate =
        currencyConversionData.result[currencyConversionData.currencyCode]
    }
  }

  const output = data.map((ele) => {
    const monthlySearches = []
    const monthlySearchesData = []
    ele.monthlySearches.map((item) => {
      monthlySearches.push(item.search_volume)
      // monthlySearchesData.push([`${item.month}/${item.year}`, item.search_volume]);
      monthlySearchesData.push({
        datetime: `${item.month}/${item.year}`,
        search_volume: item.search_volume,
      })
      return item
    })

    // const competition = Math.floor(ele.competition * 100);

    let cpc = ele.cpc
    if (cpc) {
      if (conversionRate) {
        // const conversionRate = currencyConversionData.result[currencyConversionData.currencyCode];
        // console.log('conversionRate:', conversionRate);
        cpc = cpc * conversionRate
        cpc = cpc.toFixed(2)
        cpc = `${currencyConversionData.currencySymbol} ${cpc}`
      } else {
        cpc = cpc.toFixed(2)
        cpc = `$ ${cpc}`
      }
    } else {
      cpc = '-'
    }

    return {
      keyword: ele.keyword,
      searchVolume: ele.searchVolume,
      monthlySearches: monthlySearches,
      monthlySearchesData: monthlySearchesData.reverse(),
      cpc: cpc,
      position: ele.position ? ele.position.toFixed(2) : '-',
      url: ele.url,
      traffic: ele.traffic
        ? `${currencyConversionData.currencySymbol} ${ele.traffic.toFixed(2)}`
        : '-',
      serpItem: {
        url: ele.serpItem.url,
        title: ele.serpItem.title,
        description: ele.serpItem.description,
        domain: ele.serpItem.domain,
      },
      // competition: competition ? competition : 1
    }
  })
  return output
}

export const structuredSchemaSpyShoppingAds = (
  response,
  currencyConversionData,
) => {
  response = response.filter(
    (ele) =>
      ele.type === 'google_shopping_paid' ||
      ele.type === 'google_shopping_serp',
  )

  const data = response.map((ele) => {
    return {
      product_images: ele.product_images,
      title: ele.title,
      currency: ele.currency,
      price: ele.price,
      url: ele.url,
      description: ele.description,
      type: ele.type,
    }
  })

  const output = data.map((ele) => {
    let price = ele.price
    if (price) {
      price = price.toFixed(2)
      price = `${currencyConversionData.currencySymbol} ${price}`
    } else {
      price = '-'
    }

    return {
      product_images: ele.product_images,
      title: ele.title,
      currency: ele.currency,
      price: price,
      url: ele.url,
      description: ele.description,
      type: ele.type,
    }
  })
  return output
}
