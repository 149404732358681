import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Row, Col, Statistic } from 'antd';
import AppContext from '../../../context/AppContext';
import { collection, query, getDocs, where } from "firebase/firestore";
import { Database } from '../../../firebase';
import { PAGE_HEAD_TITLE } from "../../../constants";

const AdminDashboard = (props) => {

    // const [users, setUsers] = useState([]);
    const [userSatistics, setUserStatistics] = useState({
        totalUsers: 0, newUsers: 0, freeUsers: 0, bronzeUsers: 0, silverUsers: 0, goldUsers: 0, grossPurchase: 0, last30DayPuchase: 0, last7DayPuchase: 0
    });
    const { setLoadingFullScreen } = useContext(AppContext);

    useEffect(() => {
        document.title = `Admin Dashboard ${PAGE_HEAD_TITLE}`;
        (async function () {
            await fetchUsers();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUsers = async () => {
        setLoadingFullScreen(true);
        const q = query(collection(Database, "users"), where('email', '!=', 'srikar712@gmail.com'));
        const querySnapshot = await getDocs(q);
        const last30DaysDate = moment().subtract(30, 'days');
        const last7DaysDate = moment().subtract(7, 'days');
        let usersData = [];

        let totalUsers = 0, newUsers = 0, freeUsers = 0, bronzeUsers = 0, silverUsers = 0, goldUsers = 0, grossPurchase = 0, last30DayPuchase = 0, last7DayPuchase = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            // const p = new Promise()
            let item = doc.data();

            const dateCreated = moment(item.createdDate.toDate()).format('DD-MM-YYYY');
            // const accountType = item.subscriptionPlan && item.subscriptionPlan.planId ? item.subscriptionPlan.planId : "FREE";
            let accountType = "FREE";
            if (item.subscriptionPlan && item.subscriptionPlan.planId) {
                if (item.subscriptionPlan.planId === "ADMIN") {
                    accountType = item.subscriptionPlan.planId;
                }
                else if (item.subscriptionPlan.paymentStatus && item.subscriptionPlan.paymentStatus === "paid") {
                    accountType = item.subscriptionPlan.planId;
                } else {
                    accountType = "FREE";
                }
            }
            const price = item.subscriptionPlan && item.subscriptionPlan.price ? item.subscriptionPlan.price : 0;

            totalUsers++;
            grossPurchase = grossPurchase + price;

            const isAfter30Days = moment(item.createdDate.toDate()).isAfter(last30DaysDate);
            if (isAfter30Days) {
                newUsers++;
                last30DayPuchase = last30DayPuchase + price;
            };
            const isAfter7Days = moment(item.createdDate.toDate()).isAfter(last7DaysDate);
            if (isAfter7Days) {
                last7DayPuchase = last7DayPuchase + price;
            };

            if (accountType === "FREE") { freeUsers++; }
            if (accountType === "BRONZE") { bronzeUsers++; }
            if (accountType === "SILVER") { silverUsers++; }
            if (accountType === "GOLD") { goldUsers++; }

            // item.id = doc.id;
            usersData.push({
                name: item.name,
                email: item.email,
                createdDate: dateCreated,
                key: doc.id,
                id: doc.id,
                accountType: accountType,
                price: price,
            });
        });

        const dataState = {
            totalUsers, newUsers, freeUsers, bronzeUsers, silverUsers, goldUsers, grossPurchase, last30DayPuchase, last7DayPuchase
        }
        setUserStatistics(dataState);
        // setUsers(usersData);
        setLoadingFullScreen(false);
        // console.log("usersData:", JSON.stringify(usersData));
    }

    return (
        <div className="content-wrapper">
            <Row gutter={16}>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-1">
                        <Statistic title="Gross Purchase" value={`${userSatistics.grossPurchase}$`} />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col suffix-small color-bg-2">
                        <Statistic title="Months Purchase" value={`${userSatistics.last30DayPuchase}$`} suffix="/ in last 30 days" />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col suffix-small color-bg-3">
                        <Statistic title="Week Purchase" value={`${userSatistics.last7DayPuchase}$`} suffix="/ in last 7 days" />
                    </div>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '30px' }}>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-1">
                        <Statistic title="Total Users" value={userSatistics.totalUsers} />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col suffix-small color-bg-2">
                        <Statistic title="New Users" value={userSatistics.newUsers} suffix="/ in last 30 days" />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-3">
                        <Statistic title="Free Plan Users" value={userSatistics.freeUsers} />
                    </div>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '30px' }}>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-4">
                        <Statistic title="Bronze Users" value={userSatistics.bronzeUsers} />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-5">
                        <Statistic title="Silver Users" value={userSatistics.silverUsers} />
                    </div>
                </Col>
                <Col span={8} className='stat-box'>
                    <div className="stat-box-wrapper-col color-bg-6">
                        <Statistic title="Gold Users" value={userSatistics.goldUsers} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AdminDashboard;
