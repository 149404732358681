import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <span
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ position: "relative", cursor: "pointer" }}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            bottom: "120%", // Adjust the positioning based on your needs
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#333",
            color: "#fff",
            padding: "8px 12px",
            borderRadius: "6px",
            fontSize: "14px",
            textAlign: "center",
            whiteSpace: "nowrap",
            zIndex: 10,
            opacity: showTooltip ? 1 : 0,
            visibility: showTooltip ? "visible" : "hidden",
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          {text}
          <div
            style={{
              position: "absolute",
              bottom: "-5px",
              left: "50%",
              transform: "translateX(-50%)",
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "5px solid #333",
            }}
          />
        </div>
      )}
    </span>
  );
};

export default Tooltip;
