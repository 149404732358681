import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import ReactTagInput from "@pathofdev/react-tag-input";
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import { shuffle, createSlicesFromArray } from '../../utils/Helpers';
import './style.css';

const CreateLayersModal = (props) => {
    const { open, selectedInterests, setClose } = props;
    const [numberOfLayers, setNumberOfLayers] = useState(1);
    const [tags, setTags] = useState(selectedInterests);
    // const [tags2, setTags2] = useState([]);
    const [layerTags, setLayerTags] = useState({});

    useEffect(() => {
        setTags(selectedInterests);
        setLayerTags({ layer_1: selectedInterests });
    }, [selectedInterests])

    const updateLayerCount = () => {
        const newCount = numberOfLayers + 1;
        const layerTagsState = layerTags;
        const newData = {
            ...layerTagsState,
            [`${'layer_' + newCount}`]: []
        }
        if (newCount < 6) {
            setNumberOfLayers(newCount)
            setLayerTags(newData)
        }
    }

    // const setInputTags = (newTags, key) => {
    //     const newArray = newTags.map(tag => {
    //         const findIndex = selectedInterests.filter(item => item.name === tag);
    //         if (findIndex.length) {
    //             return findIndex[0];
    //         } else {
    //             return { key: tag, name: tag, audienceSizeLowerBound: 0, audienceSizeUpperBound: 0, topic: '-' };
    //         }
    //     })

    //     // layerTags[key] = newArray;
    //     // console.log("layerTags:", layerTags)
    //     // console.log("newTags:", newTags)
    //     setLayerTags(layerTags);
    // }

    const sortRandomly = () => {
        const tagsJoin = [...tags];
        // Array.prototype.push.apply(tagsJoin, tags2);
        const shuffledArray = shuffle(tagsJoin);
        const output = createSlicesFromArray(shuffledArray, numberOfLayers);
        setLayerTags(output);
    }

    const sortAudioence = () => {
        const tagsJoin = [...tags];
        // Array.prototype.push.apply(tagsJoin, tags2);
        const sortedArray = tagsJoin.sort((a, b) => (a.audienceSizeUpperBound < b.audienceSizeUpperBound) ? 1 : -1);
        const output = createSlicesFromArray(sortedArray, numberOfLayers);
        setLayerTags(output)
    }

    const renderLayerTextarea = () => {
        const output = [];
        let key = 1;
        for (key in layerTags) {
            output.push(<React.Fragment>
                <ReactTagInput
                    tags={layerTags[key].map(item => item.name)}
                    placeholder="&nbsp;"
                    editable={false}
                    // onChange={(newTags) => setInputTags(newTags, key)}
                    onChange={(newTags) => { }}
                />
                <ClipboardCopy text={layerTags[key].map(item => item.name).join(", ")} />
            </React.Fragment>);
        }
        return output;
    }

    return (
        <Modal
            className="save-collection-modal"
            closable
            visible={open}
            onCancel={() => setClose(false)}
            footer={null}
            centered
            width={700}
        >
            <div className="modal_header">
                Create Interests Layers
            </div>
            <div className="modal_content">
                {numberOfLayers > 1 &&
                    <h6>
                        <strong>Distribute interests : &nbsp;
                            <span className="layer-link" onClick={() => sortRandomly()}>randomly</span> or by <span className="layer-link" onClick={() => sortAudioence()}>audience size</span>
                        </strong>
                    </h6>
                }

                {renderLayerTextarea()}

                {numberOfLayers < 5 && <div><span className="add-layer-btn" onClick={() => updateLayerCount()}>+ Add Layer</span></div>}
            </div>
        </Modal>
    );
}

export default CreateLayersModal;
