import React from "react";
import { withRouter } from "react-router-dom";
import { humanReadableNumber } from "../../utils/Helpers";
import "../keywordSearch/style.css";

const YoutubeAds = (props) => {

  const { data: adsDetails, handleGoBack } = props;

  return (
    <React.Fragment>
      <div className="content-wrapper">

        <div>
          <span className="goback" onClick={() => handleGoBack()}> Go Back</span>
        </div>
        {adsDetails &&
          <div>
            <div className="flex-div" >
              <div className="col card-1" >
                <div style={{ width: "640px", height: "360px" }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${adsDetails.result.elastic._source.videoId}`}
                    width="100%"
                    height="100%"
                    title="youtube video"
                  ></iframe>
                </div>
                <div className="like-view">
                  <p>{humanReadableNumber(adsDetails.result.views)} Views</p>
                  <p>{humanReadableNumber(adsDetails.result.likes)} Likes</p>
                </div>
              </div>
              <div className="col card-1">
                <div className="box-1">
                  <div className="title">Title : </div>
                  <div className="description">{adsDetails.result.title}</div>
                </div>
                <div className="box-1">
                  <div className="title">Channel : </div>
                  <div className="description">{adsDetails.result.channel}</div>
                </div>
                <div className="box-1">
                  <div className="title">Content : </div>
                  <div className="description">{adsDetails.result.content}</div>
                </div>
              </div>
            </div>
            <div className="flex-div mt-5">
              <div className="col-md-6 card-1">
                <div className="main-title">Tags</div>
                {adsDetails.result.tags.map((tagesitem, index) => {
                  return <span className="tag-cust" key={`url-${index}`}>{tagesitem}</span>
                })}
              </div>
              <div className="col-md-6 card-1">

                <div className="main-title">Countries</div>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th>Country</th>
                      <th>Count</th>
                    </tr>
                    {adsDetails.result.countryTableData.map((countryitem, index) => {
                      return <tr key={`url-${index}`}>
                        <td>{countryitem.country}</td>
                        <td>{countryitem.count}</td>
                      </tr>
                    })}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment >
  );
}

export default withRouter(YoutubeAds);
