import { Button, Popconfirm, notification } from 'antd'
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Link } from 'react-router-dom'
import { PAGE_HEAD_TITLE } from '../../constants'
import { Database } from '../../firebase'
import { Header } from '../../styles/styles'
import UserContext from '../../userContext'
import './style.css'

const ListGoogleCollections = (props) => {
  const authUser = useContext(UserContext)

  const [collections, setCollections] = useState([])

  const columns = [
    {
      dataField: 'searchType',
      text: 'Type',
    },
    {
      dataField: 'collectionName',
      text: 'Collection',
    },
    {
      dataField: 'createdDate',
      text: 'Date',
    },
    {
      dataField: 'view',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <Button type="link">
              <Link to={'/google-search-collection/' + row.view}>View</Link>
            </Button>
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteCollection(row.view)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </React.Fragment>
        )
      },
    },
  ]

  useEffect(() => {
    document.title = `Keyword Search Collections ${PAGE_HEAD_TITLE}`
    ;(async function () {
      await fetchCollections()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCollections = async () => {
    // setCollections([]);
    const { uid } = authUser
    if (uid) {
      const q = query(
        collection(Database, 'keywordSearchCollection'),
        where('userId', '==', uid),
        where('type', '==', 'GOOGLE_SEARCH'),
      )
      const querySnapshot = await getDocs(q)

      setCollections(
        querySnapshot.docs.map((doc) => {
          const data = doc.data()
          const dateCreated = moment(data.createdDate.toDate()).format(
            'DD-MM-YYYY',
          )
          return {
            searchType: data.searchType,
            collectionName: data.collectionName,
            createdDate: dateCreated,
            view: doc.id,
          }
        }),
      )
    }
  }

  const deleteCollection = async (id) => {
    await deleteDoc(doc(Database, 'keywordSearchCollection', id))
    const updatedData = collections.filter((item) => item.view !== id)
    setCollections(updatedData)
    notification['success']({
      message: 'Collection has been deleted.',
    })
  }

  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page !== 'string',
    )
    if (pages.length > 1) {
      return (
        <div className="text-center">
          {pageWithoutIndication.map((p, index) => {
            return (
              <button
                key={index}
                className={
                  p.active ? 'btn paginations active' : 'btn paginations '
                }
                actives={p.active}
                onClick={() => onPageChange(p.page)}
              >
                {p.page}
              </button>
            )
          })}
        </div>
      )
    } else {
      return false
    }
  }

  const options = {
    pageListRenderer,
    hideSizePerPage: true,
  }

  return (
    <div className="content-wrapper">
      <Header>Your Collections</Header>
      <div className="cust-table">
        {collections.length > 0 && (
          <BootstrapTable
            keyField="view"
            data={collections}
            columns={columns}
            headerColumn
            bordered={false}
            pagination={paginationFactory(options)}
          />
        )}
      </div>
    </div>
  )
}

export default ListGoogleCollections
