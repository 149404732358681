import React from 'react';
import { Drawer } from "antd";
import "./NotificationBar.css";

const NotificationBar = (props) => {

    const onClose = () => {
        props.setVisible(false);
    };

    return (
        <Drawer title="Notifications" size="large" placement="right" onClose={onClose} open={props.visible} >
            {props.notifications.length ?
                props.notifications.map((item, index) => {
                    return <div className="notification" key={`notification-idx-${index}`}>
                        <div className="notification-desc-block">
                            <h6 className="notification-title">{item.title}</h6>
                            <p className="notification-desc">{item.description}</p>
                            <p className="notification-subtitle">{item.createdDate}</p>
                        </div>
                    </div>
                })
                :
                'No notifications'
            }
        </Drawer>
    );
}


export default NotificationBar;