import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Button, Input, Popover } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import BunnyPlayer from '../BunnyPlayer';

import { Flex, Title, BlueBackground, CalcText, WhiteBack, Padding } from '../../styles/PageStyle';
import LocationDropdown, { locationOptions } from "../locationDropdown";
import { formatNumber, subscribeToExport } from '../../utils/Helpers';

export default function LeadsRoasMaximizer() {

    const authUser = useContext(UserContext);

    const { Text } = Typography;
    const [locationData, setLocationData] = useState(locationOptions[6])

    const [FacebookAdsSpend, SetFacebookAdsSpend] = useState('')
    const [TotalLeads, SetTotalLeads] = useState('')

    const [CostPerRaw, SetCostPerRaw] = useState('')

    const [ActualLead, SetActualLead] = useState('')

    const [EmailSequence, SetEmailSequence] = useState('')
    const [EmailSequenceTotal, SetEmailSequenceTotal] = useState('')

    const [UpSellTake, SetUpSellTake] = useState('')
    const [UpSellTakeTotal, SetUpSellTakeTotal] = useState('')

    const [AverageRevenue, SetAverageRevenue] = useState('')
    const [GrossRevenue, SetGrossRevenue] = useState('')

    const [ProductCost, SetProductCost] = useState('')
    // const [ProductCostTotal, SetProductCostTotal] = useState('')

    const [AverageCostsCampaign, SetAverageCostsCampaign] = useState('')

    const [GrossProfit, SetGrossProfit] = useState('')

    const [GrossProfitCampaign, SetGrossProfitCampaign] = useState('')

    const [ReturnonAd, SetReturnonAd] = useState('')
    const [ReturnonAdCampaign, SetReturnonAdCampaign] = useState('')

    const [ReturnonInvestment, SetReturnonInvestment] = useState('')
    const [ReturnonInvestmentCampaign, SetReturnonInvestmentCampaign] = useState('')

    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        document.title = `ROAS Maximizer ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {
        if (FacebookAdsSpend !== '' && TotalLeads !== '') {
            var divizon = FacebookAdsSpend / TotalLeads;
            SetCostPerRaw(divizon.toFixed(2))
        }
    }, [FacebookAdsSpend, TotalLeads]); //eslint-disable-line

    const [ActualLeadTotal, SetActualLeadTotal] = useState('')
    useEffect(() => {
        if (TotalLeads !== '' && ActualLead !== '') {
            var divizon = TotalLeads * ActualLead / 100;
            SetActualLeadTotal(divizon.toFixed(0))
        }
    }, [TotalLeads, ActualLead]); //eslint-disable-line

    useEffect(() => {
        if (ActualLeadTotal !== '' && EmailSequence !== ' ') {
            SetEmailSequenceTotal(ActualLeadTotal * EmailSequence / 100)
        }
    }, [ActualLeadTotal, EmailSequence]); //eslint-disable-line

    useEffect(() => {
        if (UpSellTake !== '') {
            SetUpSellTakeTotal(UpSellTake * EmailSequenceTotal / 100)
        }
    }, [UpSellTake]); //eslint-disable-line
    useEffect(() => {
        SetGrossRevenue(AverageRevenue)
    }, [AverageRevenue]); //eslint-disable-line

    const [GrossRevenueCampaign, SetGrossRevenueCampaign] = useState('')
    useEffect(() => {
        SetGrossRevenueCampaign(UpSellTakeTotal * GrossRevenue)
    }, [UpSellTakeTotal, GrossRevenue]); //eslint-disable-line

    const [Agency_Freelancers, SetAgency_Freelancers] = useState('')
    const [AverageCosts, SetAverageCosts] = useState('')

    var TotalSum = Number(FacebookAdsSpend) + Number(Agency_Freelancers)
    var totalDivison = TotalSum / UpSellTakeTotal
    var GrossRevMultiplyProdCost = GrossRevenue * ProductCost / 100
    var result = Math.round(totalDivison + GrossRevMultiplyProdCost);
    useEffect(() => {
        SetAverageCosts(result)
    }, [FacebookAdsSpend, TotalLeads, UpSellTakeTotal, GrossRevenue, ProductCost]); //eslint-disable-line

    var avregecost = GrossRevenueCampaign * ProductCost / 1e2
    var avregcostwithfee = Number(avregecost) + Number(Agency_Freelancers) + Number(FacebookAdsSpend)
    useEffect(() => {
        SetAverageCostsCampaign(Math.round(avregcostwithfee))
    }, [FacebookAdsSpend, GrossRevenueCampaign, ProductCost, Agency_Freelancers]); //eslint-disable-line

    useEffect(() => {
        SetGrossProfit(GrossRevenue - AverageCosts)
    }, [GrossRevenue, AverageCosts]); //eslint-disable-line

    useEffect(() => {
        SetGrossProfitCampaign(GrossRevenueCampaign - AverageCostsCampaign)
    }, [GrossRevenueCampaign, AverageCostsCampaign]); //eslint-disable-line

    // GrossRevenueCampaign
    // Agency_Freelancers
    // FacebookAdsSpend

    useEffect(() => {
        let result = (GrossRevenueCampaign - Agency_Freelancers) - FacebookAdsSpend;
        let FinalRes = result / FacebookAdsSpend;
        SetReturnonAd(FinalRes * 1e2)
        SetReturnonAdCampaign(FinalRes * 1e2)
    }, [GrossRevenueCampaign, Agency_Freelancers, FacebookAdsSpend]); //eslint-disable-line

    useEffect(() => {
        let result = GrossProfitCampaign / AverageCostsCampaign;
        let Final = result * 100
        SetReturnonInvestment(Final.toFixed(2));
        SetReturnonInvestmentCampaign(Final.toFixed(2))
    }, [GrossProfit, AverageCostsCampaign]); //eslint-disable-line

    useEffect(() => {
        const currencySymbol = locationData.currencySymbol;
        const data = [
            ["Boost With HMA"],
            [],
            ["Leads ROAS Maximizer", "", `Currency - ${currencySymbol}`],
            [],
            ["Leads Ads Metrics"],
            ["Ads Spend Per Month", "", `${currencySymbol} ${formatNumber(FacebookAdsSpend)}`],
            ["Total Leads", "", `${currencySymbol} ${formatNumber(TotalLeads)}`],
            ["Cost Per Raw Inquiry/Lead", "", `${currencySymbol} ${formatNumber(CostPerRaw)}`],

            ["Conversion Metrics", "", "Total Conversions"],
            ["Qualified Lead Percentage", `% ${formatNumber(ActualLead)}`, `${formatNumber(ActualLeadTotal)}`],
            ["Email Sequence Completion Rate", `% ${formatNumber(EmailSequence)}`, `${formatNumber(EmailSequenceTotal)}`],
            ["Up-Sell Take Rate", `% ${formatNumber(UpSellTake)}`, `${formatNumber(UpSellTakeTotal)}`],
            ["Average Revenue Per Sale", `${currencySymbol} ${formatNumber(AverageRevenue)}`],
            ["Product Cost", `% ${formatNumber(ProductCost)}`],
            ["Agency/ Freelancers / Tools Fees", `${currencySymbol} ${formatNumber(Agency_Freelancers)}`],

            ["Actual Leads Results", "Per Sale", "Whole Campaign"],
            ["Gross Revenue", `${currencySymbol} ${formatNumber(GrossRevenue)}`, `${currencySymbol} ${formatNumber(GrossRevenueCampaign)}`],
            ["Average Costs", `${currencySymbol} ${formatNumber(AverageCosts)}`, `${currencySymbol} ${formatNumber(AverageCostsCampaign)}`],
            ["Gross Profit", `${currencySymbol} ${formatNumber(GrossProfit)}`, `${currencySymbol} ${formatNumber(GrossProfitCampaign)}`],
            ["Return on Ad Spend (ROAS)", `% ${formatNumber(ReturnonAd)}`, `% ${formatNumber(ReturnonAdCampaign)}`],
            ["Return on Investment (ROI)", `% ${formatNumber(ReturnonInvestment)}`, `% ${formatNumber(ReturnonInvestmentCampaign)}`],
        ];
        setCsvData(data);
    }, [locationData, FacebookAdsSpend, TotalLeads, CostPerRaw, ActualLead, ActualLeadTotal, EmailSequence, EmailSequenceTotal, UpSellTake, UpSellTakeTotal, AverageRevenue, ProductCost, Agency_Freelancers, GrossRevenue, GrossRevenueCampaign, AverageCosts, AverageCostsCampaign, GrossProfit, GrossProfitCampaign, ReturnonAd, ReturnonAdCampaign, ReturnonInvestment, ReturnonInvestmentCampaign,]);

    const downloadPdfDocument = () => {
        // const downloadPdfDocument = (rootElementId) => {
        // const input = document.getElementById(rootElementId);
        setDownloadingPdf(true);
        const input = document.getElementById('divToDownload');
        // html2canvas(input, { scale: 0.81 })
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "mm", "a4");
                // const width = pdf.internal.pageSize.getWidth() - 14;
                // const height = pdf.internal.pageSize.getHeight();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 14;

                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + 5;
                pdf.text("Boost With HMA", 5, 10);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight);
                pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight, "a", "FAST");

                pdf.save("download.pdf", { returnPromise: true })
                    .then(() => {
                        setDownloadingPdf(false);
                    });
            })
    }

    var Loss = { backgroundColor: '#EB5757' }
    var Profit = { backgroundColor: '#8DD1B6' }
    const currencySymbol = locationData.currencySymbol;
    /*********End Secound Calc**********/

    return (
        <Row justify="center" className='facg-main-wrapper' style={{ marginTop: '30px' }}>
            <BunnyPlayer type="LEADS_ROAS_MAXIMIZER" />
            <Col span={24} style={{ marginTop: "2rem", marginBottom: '6rem' }}>
                <Row justify="center">
                    <Col span={20} id="divToDownload">
                        <Row justify="space-between" style={{ marginBottom: 16, alignItems: 'center' }}>
                            <Col>
                                <Text level={5} style={{ fontWeight: "550", fontSize: 18 }}>
                                    Leads ROAS Maximizer
                                </Text>
                            </Col>
                            <Col>
                                <Flex style={{ justifyContent: 'flex-end' }}>
                                    <LocationDropdown defaultValue={locationData} onChange={(value) => { setLocationData(value) }} />
                                </Flex>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <BlueBackground>
                                    <Row justify="space-between">
                                        <Col span={6}>
                                            <Title>Leads Ads Metrics</Title>
                                        </Col>
                                        <Col span={6}>
                                        </Col>
                                        <Col span={6}>

                                        </Col>
                                    </Row>

                                </BlueBackground>
                            </Col>
                            <Col span={24}> <WhiteBack>
                                <Padding> <Row justify="space-between">
                                    <Col span={6}>
                                        <CalcText>Ads Spend Per Month</CalcText>
                                    </Col>
                                    <Col span={6}>
                                        <Input
                                            value={FacebookAdsSpend}
                                            prefix={currencySymbol}
                                            type="number"
                                            onChange={(e) => SetFacebookAdsSpend(e.target.value)}
                                        />
                                    </Col>
                                </Row> </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col span={6}>
                                        <CalcText>Total Leads</CalcText>
                                    </Col>
                                    <Col span={6}>
                                        <Input
                                            value={TotalLeads}
                                            type="number"
                                            onChange={(e) => SetTotalLeads(e.target.value)}
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding> <Row justify="space-between">
                                    <Col span={6}>
                                        <CalcText>Cost Per Raw Inquiry/Lead</CalcText>
                                    </Col>
                                    <Col span={6}>
                                        <Input
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            type="number"
                                            prefix={currencySymbol}
                                            value={CostPerRaw}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                            </WhiteBack> </Col>
                            <Col span={24}>
                                <BlueBackground>
                                    <Row justify="space-between">
                                        <Col span={6}>
                                            <Title>Conversion Metrics</Title>
                                        </Col>
                                        <Col span={6}>
                                        </Col>
                                        <Col span={6}>
                                            <Title>Total Conversions</Title>
                                        </Col>
                                    </Row>
                                </BlueBackground>
                            </Col>
                            <Col span={24}><WhiteBack>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Qualified Lead Percentage</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            value={ActualLead}
                                            onChange={(e) => SetActualLead(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="text"
                                            // prefix={'%'}
                                            value={ActualLeadTotal > 0 ? ActualLeadTotal + ' Leads' : ''}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Email Sequence Completion Rate</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            value={EmailSequence}
                                            onChange={(e) => SetEmailSequence(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="text"
                                            // prefix={'%'}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={EmailSequenceTotal > 0 ? EmailSequenceTotal + ' Completions ' : ''}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Up-Sell Take Rate</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            value={UpSellTake}
                                            onChange={(e) => SetUpSellTake(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="text"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={UpSellTakeTotal > 0 ? UpSellTakeTotal + ' Sales' : ''}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle" justify='space-between'>
                                    <Col span={6}><CalcText>Average Revenue Per Sale</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AverageRevenue}
                                            onChange={(e) => SetAverageRevenue(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={6} align="left"></Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Product Cost</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            value={ProductCost}
                                            onChange={(e) => SetProductCost(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        {/* <Input
                                            type="number"
                                            prefix={'%'}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ProductCostTotal}
                                        /> */}
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Agency/ Freelancers / Tools Fees</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={Agency_Freelancers}
                                            onChange={(e) => SetAgency_Freelancers(e.target.value)}
                                        />
                                    </Col>
                                </Row></Padding>
                            </WhiteBack></Col>
                            <Col span={24}><BlueBackground><Row align="middle">
                                <Col span={12}>
                                    <Title>Actual Leads Results</Title>
                                </Col>
                                <Col span={6} align="center">
                                    <Title>Per Sale</Title>
                                </Col>
                                <Col span={6} align="center">
                                    <Title>Whole Campaign</Title>
                                </Col>
                            </Row></BlueBackground></Col>
                            <Col span={24}><WhiteBack>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Gross Revenue</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={GrossRevenue}
                                            disabled
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={GrossRevenueCampaign}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Average Costs</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            prefix={currencySymbol}
                                            value={AverageCosts}
                                            disabled
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={AverageCostsCampaign}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Gross Profit</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={GrossProfit}
                                            prefix={currencySymbol}
                                            disabled
                                        />

                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={GrossProfitCampaign}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                {/*********************************/}
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Return on Ad Spend (ROAS)</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            style={ReturnonAd < 0 ? Loss : Profit}
                                            value={ReturnonAd}
                                            prefix={'%'}
                                            disabled
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="number"
                                            style={ReturnonAdCampaign < 0 ? Loss : Profit}
                                            value={ReturnonAdCampaign}
                                            prefix={'%'}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                <Padding><Row align="middle">
                                    <Col span={6}><CalcText>Return on Investment (ROI)</CalcText></Col>
                                    <Col offset={6} span={6} align="left">
                                        <Input
                                            type="number"
                                            style={ReturnonInvestment < 0 ? Loss : Profit}
                                            prefix={'%'}
                                            value={ReturnonInvestment}
                                            disabled
                                        />
                                    </Col>
                                    <Col span={6} align="left">
                                        <Input
                                            type="number"
                                            style={ReturnonInvestmentCampaign < 0 ? Loss : Profit}
                                            prefix={'%'}
                                            value={ReturnonInvestmentCampaign}
                                            disabled
                                        />
                                    </Col>
                                </Row></Padding>
                                {/*********************************/}
                            </WhiteBack></Col>
                        </Row>
                    </Col>
                </Row>
                {
                    authUser.accountType === "FREE" ?
                        <Row justify="center" style={{ marginTop: '2rem' }}>
                            <Popover content={subscribeToExport} className="">
                                <Button className="primary-button not-allowed-button">Export as PDF</Button>
                            </Popover>

                            <Popover content={subscribeToExport} className="" >
                                <Button className="primary-button not-allowed-button" style={{ marginLeft: '20px' }}>Export as CSV</Button>
                            </Popover>
                        </Row>
                        :
                        <Row justify="center">
                            <Button className="primary-button mt-2rem" onClick={downloadPdfDocument} disabled={downloadingPdf}>{downloadingPdf ? 'Exporting...' : 'Export as PDF'}</Button>
                            <CSVLink
                                className="csv-download-button primary-button mt-2rem"
                                data={csvData}
                                onClick={() => {
                                    // console.log("You click the link");
                                    if (downloadingCsv) {
                                        return false;
                                    } else {
                                        setDownloadingCsv(true);
                                        const timeout = setTimeout(() => {
                                            setDownloadingCsv(false);
                                            clearTimeout(timeout);
                                        }, 3000)
                                    }
                                }}
                                filename={"fb-roas-maximizer.csv"}
                            >
                                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
                            </CSVLink>
                        </Row>
                }
            </Col>
        </Row>
    );
}
