import React, { useState } from 'react'
import './index.css'

const RadioButton = ({ options, value, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleChange = (e) => {
    setSelectedValue(e.target.value)
    onChange(e.target.value)
  }
  return (
    <div className="radio_button_div m-3">
      {options.map((option) => (
        <label key={option} className="radio_label">
          <input
            type="radio"
            className="radio_button"
            value={option}
            checked={selectedValue === option}
            onChange={handleChange}
          />
          {option}
        </label>
      ))}
    </div>
  )
}

export default RadioButton
