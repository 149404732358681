import axios from 'axios';
import firebase from 'firebase/compat/app';
import { google_ads_api_token } from '../constants';

export const googleAdsApiService = async (redirect_uri) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const email = firebase.auth().currentUser?.uid;

  try {
      const response = await axios.post(
          google_ads_api_token,  // Ensure this is correctly defined somewhere
          {
              code: code,
              email: email,
              redirect_uri: redirect_uri,
          },
          {
              headers: { Accept: 'application/json' },
          }
      );
      return response.data; // Directly return the data from the response object
  } catch (error) {
      console.error(error);
      throw error; // Optionally rethrow the error
  }
}
