import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Statistic,
  notification,
} from 'antd'
import { getAuth, updatePassword, updateProfile } from 'firebase/auth'
import { Timestamp, doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Database } from '../../firebase'
import { Header } from '../../styles/styles'
import { dateDisplayFormat, getUserAccountPlan } from '../../utils/Helpers'
import { locationOptions } from '../locationDropdown'

const UserMainComponent = (props) => {
  // const user = props.user;
  const authUser = props.authUser

  // console.log("user:", user)
  // console.log("authUser:", authUser)

  const [editUserName, setEditUserName] = useState(false)
  const [userName, setUserName] = useState('')
  const [user, setUser] = useState(null)

  const [editPassword, setEditPassword] = useState(false)

  useEffect(() => {
    // console.log("useEffect 1")
    // console.log("")
    setUser(props.user)
    setUserName(props.user ? props.user.name : '')
  }, [props.user, authUser])

  const saveUserName = async () => {
    if (!userName) {
      notification['error']({ message: 'Please enter user name!' })
    } else {
      const userRef = doc(Database, 'users', user.uid)
      await updateDoc(userRef, {
        name: userName,
        updatedDate: Timestamp.fromDate(moment().toDate()),
      })

      const auth = getAuth()
      updateProfile(auth.currentUser, {
        displayName: userName,
      })
        .then(() => {
          console.log('Profile updated!')
        })
        .catch((error) => {
          console.log('Profile update error!')
        })

      notification['success']({ message: 'User name has been updated!' })
      setEditUserName(false)
      setUser((user) => ({ ...user, name: userName }))
      // console.log("Document updated");
    }
  }

  const handleNameChange = (e) => {
    setUserName(e.target.value)
  }

  const onFinish = async (values) => {
    const auth = getAuth()
    const userUp = auth.currentUser
    updatePassword(userUp, values.password)
      .then(() => {
        notification['success']({ message: 'Password has been updated!' })
        setEditPassword(false)
      })
      .catch((error) => {
        const errorCode = error.code
        console.log('Error:', error)
        if (errorCode === 'auth/requires-recent-login') {
          notification['error']({
            message: 'Please log in again before retrying this request!',
          })
        } else {
          notification['error']({ message: 'An error ocurred!' })
        }
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleQuotaStyle = (value, maxLimit) => {
    const yellowLimit = (maxLimit * 90) / 100

    let className
    if (value >= maxLimit) {
      className = 'red-zone-reached'
    } else if (value >= yellowLimit) {
      className = 'yellow-zone-reached'
    } else {
      className = 'green-zone-reached'
    }
    return className
  }

  let usedGKwQ = 0,
    usedBKwQ = 0,
    usedKwQ = 0,
    usedSpyQ = 0,
    usedAIQ = 0
  let maxGKwQ = 0,
    maxBKwQ = 0,
    maxKwQ = 0,
    maxSpyQ = 0,
    maxAIQ = 0
  // const accountType = user && user.subscriptionPlan ? user.subscriptionPlan.planId : "FREE";
  let accountType = 'FREE'
  let currency = ''
  if (user && user.subscriptionPlan && user.subscriptionPlan.planId) {
    if (user.subscriptionPlan.planId === 'ADMIN') {
      accountType = user.subscriptionPlan.planId
    } else if (
      user.subscriptionPlan.paymentStatus &&
      user.subscriptionPlan.paymentStatus === 'paid'
    ) {
      accountType = user.subscriptionPlan.planId
    } else {
      accountType = 'FREE'
    }
  }
  if (user && user.quotaUsed) {
    const qtUsed = user.quotaUsed
    usedGKwQ = qtUsed.quotaGoogleKwResearch ? qtUsed.quotaGoogleKwResearch : 0
    usedBKwQ = qtUsed.quotaBingKwResearch ? qtUsed.quotaBingKwResearch : 0
    usedKwQ = qtUsed.quotaKwResearch ? qtUsed.quotaKwResearch : 0
    usedSpyQ = qtUsed.quotaSpyAdsKw ? qtUsed.quotaSpyAdsKw : 0
    usedAIQ = qtUsed.quotaAiRequest ? qtUsed.quotaAiRequest : 0
  }

  if (user && user.subscriptionPlan) {
    const subPlan = user.subscriptionPlan
    maxGKwQ = subPlan.quotaGoogleKwResearch ? subPlan.quotaGoogleKwResearch : 0
    maxBKwQ = subPlan.quotaBingKwResearch ? subPlan.quotaBingKwResearch : 0
    maxKwQ = subPlan.quotaKwResearch ? subPlan.quotaKwResearch : 0
    maxSpyQ = subPlan.quotaSpyAdsKw ? subPlan.quotaSpyAdsKw : 0
    maxAIQ = subPlan.quotaAiRequest ? subPlan.quotaAiRequest : 0
  }

  if (user && user.subscriptionPlan) {
    const subPlan = user.subscriptionPlan
    let currencyData = locationOptions.find(
      (item) => item.currencyCode === subPlan.currencyCode,
    )
    if (currencyData) {
      currency = currencyData.currencySymbol
    }
  }

  const userPlanCheckIfExpired = getUserAccountPlan(user)

  return (
    <div className="content-wrapper">
      <div className="collection-header">
        <Header>User Data</Header>
      </div>

      {userPlanCheckIfExpired === 'EXPIRED' ? (
        <div>
          <Alert
            message="Your plan is expired!"
            showIcon
            type="error"
            action={
              <Space direction="vertical">
                <Link
                  to="/plans"
                  style={{
                    color: '#ff4d4f',
                    background: '#fff',
                    height: '24px',
                    padding: '0px 7px',
                    fontSize: '14px',
                    borderRadius: '2px',
                    lineHeight: '1.5715',
                    position: 'relative',
                    display: 'inline-block',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    border: '1px solid #ff4d4f',
                  }}
                >
                  Renew Your Plan
                </Link>
              </Space>
            }
          />
          <br />
          <br />
        </div>
      ) : null}

      {user &&
      user.subscriptionPlan &&
      user.subscriptionPlan.planId !== 'ADMIN' &&
      user.subscriptionPlan.paymentStatus !== 'paid' ? (
        <div>
          <Alert
            message="Payment is Pending, You can pay now."
            showIcon
            type="error"
            action={
              <Space direction="vertical">
                <a
                  href={user.subscriptionPlan.hostedInvoiceUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#ff4d4f',
                    background: '#fff',
                    height: '24px',
                    padding: '0px 7px',
                    fontSize: '14px',
                    borderRadius: '2px',
                    lineHeight: '1.5715',
                    position: 'relative',
                    display: 'inline-block',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    border: '1px solid #ff4d4f',
                  }}
                >
                  Pay Now
                </a>
              </Space>
            }
          />
          <br />
          <br />
        </div>
      ) : null}

      <div className="stat-box-wrapper">
        <Row gutter={24} justify="space-between">
          <Col span={4} className="stat-box">
            <Statistic
              title="Keywords Research Quota"
              className={handleQuotaStyle(usedKwQ, maxKwQ)}
              value={usedKwQ > maxKwQ ? maxKwQ : usedKwQ}
              suffix={`/ ${maxKwQ}`}
            />
          </Col>
          <Col span={4} className="stat-box">
            <Statistic
              title="Google Ads Planner Quota"
              className={handleQuotaStyle(usedGKwQ, maxGKwQ)}
              value={usedGKwQ > maxGKwQ ? maxGKwQ : usedGKwQ}
              suffix={`/ ${maxGKwQ}`}
            />
          </Col>
          <Col span={4} className="stat-box">
            <Statistic
              title="Bing Ads Planner Quota"
              className={handleQuotaStyle(usedBKwQ, maxBKwQ)}
              value={usedBKwQ > maxBKwQ ? maxBKwQ : usedBKwQ}
              suffix={`/ ${maxBKwQ}`}
            />
          </Col>
          <Col span={4} className="stat-box">
            <Statistic
              title="Spy Ads Quota"
              className={handleQuotaStyle(usedSpyQ, maxSpyQ)}
              value={usedSpyQ > maxSpyQ ? maxSpyQ : usedSpyQ}
              suffix={`/ ${maxSpyQ}`}
            />
          </Col>
          <Col span={4} className="stat-box">
            <Statistic
              title="Ai Quota"
              className={handleQuotaStyle(usedAIQ, maxAIQ)}
              value={usedAIQ > maxAIQ ? maxAIQ : usedAIQ}
              suffix={`/ ${maxAIQ}`}
            />
          </Col>
        </Row>
      </div>

      <div className="stat-box-wrapper" style={{ marginTop: '50px' }}>
        <Header style={{ textAlign: 'left', fontSize: '15px' }}>
          More Details
        </Header>
        <div className="span-color">
          <p>
            User Name: <span>{user ? user.name : ''}</span>
          </p>
          <p>
            Email: <span>{user ? user.email : ''}</span>
          </p>
          <p>
            Signup Date:{' '}
            <span>{user ? dateDisplayFormat(user.createdDate) : '-'}</span>
          </p>
          <br />
          <p>
            Plan Name:{' '}
            <span>
              {user && user.subscriptionPlan
                ? user.subscriptionPlan.planId
                : 'FREE'}
            </span>
          </p>

          {user &&
          user.subscriptionPlan &&
          user.subscriptionPlan.planId !== 'ADMIN' ? (
            <React.Fragment>
              <p>
                Price:{' '}
                <span>{`${user.subscriptionPlan.price} ${currency}`}</span>
              </p>
              <p>
                Payment Status:{' '}
                <span>{`${
                  user.subscriptionPlan.paymentStatus === 'paid'
                    ? 'PAID'
                    : 'UNPAID'
                }`}</span>
                {user.subscriptionPlan.paymentStatus !== 'paid' ? (
                  <a
                    href={user.subscriptionPlan.hostedInvoiceUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ marginLeft: '10px', fontSize: '12px' }}
                  >
                    ( Pay Now )
                  </a>
                ) : null}
              </p>
              <p>
                Duration: <span>{user.subscriptionPlan.planMonthlyYearly}</span>
              </p>
              <p>
                Purchase Date:{' '}
                <span>
                  {dateDisplayFormat(user.subscriptionPlan.purchaseDate)}
                </span>
              </p>
              <p>
                Expiry Date:{' '}
                <span>
                  {dateDisplayFormat(user.subscriptionPlan.expiryDate)}
                </span>
              </p>
              {/* <p>Stripe Customer ID: <span>{user.subscriptionPlan.stripeCustomerID ? user.subscriptionPlan.stripeCustomerID : '-'}</span></p> */}
              {/* <p>Stripe Subscription ID: <span>{user.subscriptionPlan.stripeSubscriptionID ? user.subscriptionPlan.stripeSubscriptionID : '-'}</span></p> */}
            </React.Fragment>
          ) : null}
        </div>
      </div>

      {authUser ? (
        <div style={{ marginTop: '40px' }}>
          <Button
            className="primary-button"
            onClick={() => setEditUserName((value) => !value)}
            style={{}}
          >
            Change Name
          </Button>
          <Button
            className="primary-button"
            onClick={() => setEditPassword((value) => !value)}
            style={{ marginLeft: '20px' }}
          >
            Change Password
          </Button>
          <Button className="primary-button" style={{ marginLeft: '20px' }}>
            <Link to="/plans">Check Plans</Link>
          </Button>
        </div>
      ) : null}

      {authUser && editUserName ? (
        <div className="stat-box-wrapper" style={{ marginTop: '30px' }}>
          <Row gutter={16} style={{ marginTop: '10px' }}>
            <Col span={6} style={{ textAlign: 'right' }}>
              <span style={{ color: '#ff4d4f' }}>*</span> Name :
            </Col>
            <Col span={18}>
              <Input
                placeholder="Please enter name"
                value={userName}
                onChange={handleNameChange}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ margin: '30px 0 0 0' }}>
            <Col span={46} offset={6}>
              <Button
                className="primary-button"
                htmlType="submit"
                onClick={saveUserName}
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}

      {authUser && editPassword ? (
        <div className="stat-box-wrapper" style={{ marginTop: '30px' }}>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Re-enter Password"
              name="passwordConfirm"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!',
                      ),
                    )
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{ offset: 6, span: 18 }}
              style={{ marginBottom: 0 }}
            >
              <Button className="primary-button" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : null}
    </div>
  )
}

export default UserMainComponent
