import React, { useState, useEffect, Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Row, Input, notification, Popover } from "antd";
import { StarFilled } from "@ant-design/icons";
import BootstrapTable from "react-bootstrap-table-next";
import AppContext from "../../context/AppContext";
import CollectionModal from "./collectionModal";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import UserContext from "../../userContext";
import { PAGE_HEAD_TITLE } from "../../constants";
import { getFacebookHiddenInterstApi } from "../../services/facebookApiService";
import BunnyPlayer from "../BunnyPlayer";
import { Database } from "../../firebase";
import { pleaseSubscribe } from "../../utils/Helpers";
import { Button } from "../../styles/styles";
import "./style.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Header } from "../../styles/styles";
const { Search } = Input;

const columns = [
  {
    dataField: "name",
    text: "Interests",
  },
  {
    dataField: "audienceSizeLowerBound",
    text: "Audience Size - Lower Bound",
    sort: true,
  },
  {
    dataField: "audienceSizeUpperBound",
    text: "Audience Size - Upper Bound",
    sort: true,
  },
  {
    dataField: "topic",
    text: "Path",
  },
];

const FindFbInterests = (props) => {
  const authUser = useContext(UserContext);
  const { setLoadingFullScreen } = useContext(AppContext);
  const { uid, accountType } = authUser;

  const [openModal, setOpenModal] = useState(false);
  const [itemsListData, setItemsListData] = useState([]);
  const [inputKey, setInputKey] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    document.title = `Facebook Hidden Interests ${PAGE_HEAD_TITLE}`;
  }, []);

  const openClickModal = () => {
    if (itemsListData.length > 0) {
      setOpenModal(true);
    } else {
      notification["error"]({ message: "Please select keywords to save!" });
    }
  };

  const closeClickModal = () => {
    setOpenModal(false);
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setItemsListData([...itemsListData, row]);
    } else {
      const interests = [...itemsListData];
      const itemIndex = interests.indexOf(row);
      interests.splice(itemIndex, 1);
      setItemsListData(interests);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      let key;
      let interests = [];
      for (key in rows) {
        interests.push(rows[key]);
      }
      setItemsListData(rows);
    } else {
      setItemsListData([]);
    }
  };

  const handleSaveCollection = async (collectionName) => {
    setLoadingFullScreen(true);

    const payload = {
      userId: uid,
      collectionName: collectionName,
      createdDate: Timestamp.fromDate(moment().toDate()),
      interests: JSON.stringify(itemsListData),
    };

    const docRef = await addDoc(
      collection(Database, "fbHiddenInterestsCollection"),
      payload
    );

    // console.log("Document written with ID: ", docRef.id);
    if (docRef.id) {
      props.history.push("/facebook-hidden-interests-collection");
    } else {
      alert("SOMETHING WENT WRONG");
    }
    setLoadingFullScreen(false);
  };

  const findInterests = async (e) => {
    if (inputKey) {
      setLoadingFullScreen(true);
      const data = await getFacebookHiddenInterstApi(inputKey);
      if (data) {
        setSearchData(data);
      }
      setLoadingFullScreen(false);
    }
  };

  const selectRow = {
    mode: "checkbox",
    selectionRenderer: (props) => (
      <>
        <input
          type={props.mode}
          defaultChecked={props.checked}
          onChange={() => {}}
          hidden
        />
        <StarFilled
          style={{
            color: props.checked ? "#e7ec0e" : "#175BCB",
            cursor: "pointer",
          }}
        />
      </>
    ),
    selectionHeaderRenderer: (props) => (
      <div style={{ width: "20%" }}>
        <input
          type={props.mode}
          defaultChecked={props.checked}
          onChange={() => {}}
          hidden
        />
        <StarFilled
          style={{ color: props.checked ? "#e7ec0e" : "", cursor: "pointer" }}
        />
      </div>
    ),
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  return (
    <Fragment>
      {openModal ? (
        <CollectionModal
          open={openModal}
          closeClickModal={closeClickModal}
          onSubmit={handleSaveCollection}
        />
      ) : (
        ""
      )}
      {/* <Spin spinning={loading} tip="Loading..." size="large"> */}
      <div className="content-wrapper">
        <Header>Facebook Hidden Interests</Header>
        <BunnyPlayer type="FACEBOOK__HIDDEN_INTERESTS" />
        {/* <Row
          className="searchfbInterest"
          style={{ margin: "50px auto 20px", marginTop: "20px" }}
        >
          <Search
            placeholder="Enter Facebook Interest"
            enterButton="Search"
            size="large"
            value={inputKey}
            onChange={(e) => setInputKey(e.target.value)}
            onSearch={findInterests}
          />
        </Row> */}
        <Row className="searchfbInterest">
          <div className="search-input-container">
            <input
              type="text"
              enterButton="Search"
              className="text-input"
              placeholder="Enter Facebook Interest"
              name="search"
              size="large"
              value={inputKey}
              onChange={(e) => setInputKey(e.target.value)}
              onSearch={findInterests}
            />
            {/* {
                            accountType === "FREE" ?
                                <Popover content={pleaseSubscribe} className="">
                                    <button type="submit" className="primary-button search-button not-allowed-button">Search</button>
                                </Popover>
                                : */}
            <button
              type="submit"
              onClick={findInterests}
              className="primary-button search-button"
            >
              Search
            </button>
            <button className=" view-my-collection-button">
              <Link to="/facebook-hidden-interests-collection">
                View Collection
              </Link>
            </button>
            {/* } */}
          </div>
        </Row>
        {searchData && searchData.length > 0 && (
          <>
            <Row className="save_collection mb-4">
              <h2 className="explore-title">
                Explore {searchData.length} interests related to "{inputKey}"
              </h2>

              {accountType === "FREE" ? (
                <Popover content={pleaseSubscribe} className="">
                  <Button
                    type="primary"
                    className="primary-button not-allowed-button"
                  >
                    Save
                  </Button>
                </Popover>
              ) : (
                <Button type="primary" onClick={openClickModal}>
                  Save
                </Button>
              )}
            </Row>
            <div className="cust-table">
              <BootstrapTable
                keyField="name"
                data={searchData}
                columns={columns}
                selectRow={selectRow}
                bordered={false}
              />
            </div>
          </>
        )}
      </div>
      {/* </Spin> */}
    </Fragment>
  );
};

export default withRouter(FindFbInterests);
