import React from 'react';
import { Pie } from '@ant-design/charts';

const ColumnPieChart = ({ deviceData }) => {
  // Data transformation
  const chartData = deviceData.rows.map(row => ({
    type: row.dimensionValues[0].value,
    value: parseInt(row.metricValues[0].value, 10),
  }));

  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
    legend: {
      position: 'bottom',
      layout: 'horizontal',
      itemName: {
        style: {
          fontSize: 12,
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <Pie {...config} />
    </div>
  );
};

export default ColumnPieChart;
