import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./shortSidebar.css";
import DashboardIcon from "../../styles/icons/sidebar/dashboardIcon.svg";
import MenuIcon from "../../styles/icons/sidebar/menuIcon.svg";
import FacebookHiddenInterest from "../../styles/icons/sidebar/fabookHiddenInterest.svg";
import SearchIcon from "../../styles/icons/sidebar/searchIcon.svg";
import GoogleAds from "../../styles/icons/sidebar/googleAds.svg";
import BingAds from "../../styles/icons/sidebar/bingAds.svg";
import SpyAds from "../../styles/icons/sidebar/spy.svg";
import AiAds from "../../styles/icons/sidebar/ai-ads.svg";
import Ecommerce from "../../styles/icons/sidebar/ecommerce.svg";
import Leads from "../../styles/icons/sidebar/leads.svg";
import PerformanceIcon from "../../styles/icons/sidebar/performanceIcon.svg";
import Workshops from "../../styles/icons/sidebar/workshop.svg";
import QuickLinks from "../../styles/icons/sidebar/quickLinks.svg";
import RightArrow from "../../styles/icons/sidebar/right1.svg";
import Plus from "../../styles/icons/sidebar/plusIcon.svg";
import heatmapanalyzer from "../../styles/icons/sidebar/heatmapanalyzer.svg";

const ShortSidebar = ({ setSidebarOpen }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showSubDropdown, setShowSubDropdown] = useState(false);
  const toggleDropdown = (dropdownName, event) => {
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    setShowSubDropdown(false);
    // Calculate dropdown position
    setDropdownPosition({ top: top + height, left });
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  const toggleSubDropdown = () => {
    setShowSubDropdown(!showSubDropdown);
  };
  const handleNavigation = (path) => {
    setActiveDropdown(null); // Close the dropdown
    setShowSubDropdown(false); // Close sub-dropdown
  };
  return (
    <div className="shortSidebar">
      {/* Menu */}
      <img
        src={RightArrow}
        alt="Expand Sidebar"
        className="shortSidebar_arrow-icon"
        onClick={() => setSidebarOpen(true)}
      />
      <div className="shortSidebarTopIcons">
        {/* Dashboard */}
        <div>
          <div>
            <Link to="/dashboard" className="tooltip-container">
              <img src={DashboardIcon} alt="dashboard" />
              <span className="tooltip-text">Dashboard</span>
            </Link>
          </div>
        </div>
        {/* Facebook Hidden Interests */}
        <div>
          <div
            onClick={(event) => toggleDropdown("facebookHiddenInterest", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={FacebookHiddenInterest} alt="Facebook Hidden Interests" />
            <span className="tooltip-text ">Facebook Hidden Interests</span>
          </div>
        </div>
        {/* Keywords Search */}
        <div>
          <div
            onClick={(event) => toggleDropdown("keywordsSearch", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={SearchIcon} alt="Keywords Search" />
            <span className="tooltip-text">Keywords Search</span>
          </div>
        </div>
        {/* Google Ads Planner */}
        <div>
          <div
            onClick={(event) => toggleDropdown("googleAdsPlanner", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={GoogleAds} alt="Google Ads Planner" />
            <span className="tooltip-text">Google Ads Planner</span>
          </div>
        </div>
        {/* Bing ads planner  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("bingAdsPlanner", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={BingAds} alt="Bing ads planner" />
            <span className="tooltip-text">Bing ads planner</span>
          </div>
        </div>
        {/* Ad Image Analyzer  */}
        <div>
          <Link
            to="/ad-image-analyzer"
            className="tooltip-container"
            onClick={handleNavigation}
          >
            <img src={heatmapanalyzer} alt="workshps" />
            <span className="tooltip-text">Ad Image Analyzer</span>
          </Link>
        </div>
        {/* spy competitors  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("spyCompetitors", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={SpyAds} alt="spy competitors" />
            <span className="tooltip-text">Spy Competitors</span>
          </div>
        </div>
        {/* ai ads gen  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("aiAdsGenerator", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={AiAds} alt="Ai Ads Generator" />
            <span className="tooltip-text">Ai Ads Generator</span>
          </div>
        </div>
        {/* ecommerce Calculator  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("ecomCalculator", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={Ecommerce} alt="ecommerce Calculator" />
            <span className="tooltip-text">Ecommerce Calculator</span>
          </div>
        </div>
        {/* leads calculator  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("leadsCalculator", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img src={Leads} alt="Leads calculator" />
            <span className="tooltip-text">Leads calculator</span>
          </div>
        </div>
        {/* Performance grader  */}
        <div>
          <div
            onClick={(event) => toggleDropdown("performanceGrader", event)}
            style={{ cursor: "pointer" }}
            className="tooltip-container"
          >
            <img
              src={PerformanceIcon}
              alt="Performance grader"
              className="tooltip-container"
            />
            <span className="tooltip-text">Performance grader</span>
          </div>
        </div>
      </div>

      <div className="shortSidebarButtonIcons">
        {/* workshps  */}
        <div>
          <Link
            to="/dashboard"
            className="tooltip-container"
            onClick={handleNavigation}
          >
            <img src={Workshops} alt="workshps" />
            <span className="tooltip-text">Workshops</span>
          </Link>
        </div>
        {/* QuickLinks  / */}
        <div>
          <Link
            to="/dashboard"
            className="tooltip-container"
            onClick={handleNavigation}
          >
            <img src={QuickLinks} alt="QuickLinks" />
            <span className="tooltip-text">Quick Links</span>
          </Link>
        </div>
      </div>

      {/* Dropdown Options */}
      {activeDropdown === "facebookHiddenInterest" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          {/* <div className="dropdown-arrow"></div> */}
          <Link
            to="/facebook-hidden-interests"
            onClick={handleNavigation}
            className="dropdown-option-sidebar"
          >
            Find Interest
          </Link>
          <Link
            onClick={handleNavigation}
            to="/facebook-hidden-interests-collection"
            className="dropdown-option-sidebar"
          >
            Collections
          </Link>
        </div>
      )}
      {activeDropdown === "keywordsSearch" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div>
          <Link
            onClick={handleNavigation}
            to="/related-keywords-search"
            className="dropdown-option-sidebar"
          >
            Related Keywords
          </Link>
          <Link
            onClick={handleNavigation}
            to="/keyword-suggestions-search"
            className="dropdown-option-sidebar"
          >
            Keyword Suggestions
          </Link>
          <Link
            onClick={handleNavigation}
            to="/keywords-search-collection"
            className="dropdown-option-sidebar"
          >
            Collections
          </Link>
        </div>
      )}
      {activeDropdown === "googleAdsPlanner" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            onClick={handleNavigation}
            to="/google-ads-search-volume"
            className="dropdown-option-sidebar"
          >
            Search Volume
          </Link>
          <Link
            to="/google-ads-keywords-site"
            onClick={handleNavigation}
            className="dropdown-option-sidebar"
          >
            Keyword for Site
          </Link>
          <Link
            onClick={handleNavigation}
            to="/google-ads-keywords-for-keywords"
            className="dropdown-option-sidebar"
          >
            Keyword for Keywords
          </Link>
          <Link
            onClick={handleNavigation}
            to="/google-search-collection"
            className="dropdown-option-sidebar"
          >
            Collections
          </Link>
        </div>
      )}
      {activeDropdown === "bingAdsPlanner" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            onClick={handleNavigation}
            to="/bing-ads-search-volume"
            className="dropdown-option-sidebar"
          >
            Search Volume
          </Link>
          <Link
            onClick={handleNavigation}
            to="/bing-ads-keywords-site"
            className="dropdown-option-sidebar"
          >
            Keyword for Site
          </Link>
          <Link
            to="/bing-ads-keywords-for-keywords"
            onClick={handleNavigation}
            className="dropdown-option-sidebar"
          >
            Keyword for Keywords
          </Link>
          <Link
            to="/bing-search-collection"
            onClick={handleNavigation}
            className="dropdown-option-sidebar"
          >
            Collections
          </Link>
        </div>
      )}
      {activeDropdown === "spyCompetitors" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            to="/search-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Search Ads
          </Link>
          <Link
            to="/google-shopping-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Shopping
          </Link>
          <Link
            to="/amazon-listing"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Amazon Listings
          </Link>
          <Link
            to="/youtube-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Youtube Ads
          </Link>
          <Link
            to="/google-ecom-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Ecom Ads
          </Link>
          <Link
            to="/google-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Ads
          </Link>
          <Link
            to="/display-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Display Ads
          </Link>
          {/* <Link
            to="/flipkart-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            FliKart Ads
          </Link> */}
          <Link
            to="/reddit-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Reddit Ads
          </Link>
          <Link
            to="/linkedin-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Linkedin Ads
          </Link>
          <Link
            to="/amazon-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Amazon Ads
          </Link>
          <Link
            to="/facebook-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Facebook Ads
          </Link>
          <Link
            to="/bing-ecom-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Bing Ecom Ads
          </Link>
          <Link
            to="/bing-ads"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Bing Ads
          </Link>
        </div>
      )}
      {activeDropdown === "aiAdsGenerator" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div>
          <Link
            to="/google-ai-ads-generation"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Ads
          </Link>
          <div
            className="dropdown-option-sidebar"
            onClick={toggleSubDropdown}
            style={{ cursor: "pointer" }}
          >
            Facebook Ads
            {/* <img src={Plus} alt="icon" /> */}
            {/* Sub-dropdown for Facebook Ads */}
            {showSubDropdown && (
              <div className="sub-dropdown-sidebar">
                <Link
                  to="/facebook-ai-ads-standard"
                  className="dropdown-option-sidebar"
                  onClick={handleNavigation}
                >
                  Standard
                </Link>
                <Link
                  to="/facebook-ai-ads-advanced"
                  className="dropdown-option-sidebar"
                  onClick={handleNavigation}
                >
                  Advanced
                </Link>
                <Link
                  to="/facebook-ai-ads-headline"
                  className="dropdown-option-sidebar"
                  onClick={handleNavigation}
                >
                  Headline
                </Link>
              </div>
            )}
          </div>
          <Link
            to="/ai-ads-collection"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Collections
          </Link>
        </div>
      )}
      {activeDropdown === "ecomCalculator" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            to="/e-commerce-roas-maximizer"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            ROAS Maximized
          </Link>
          <Link
            to="/e-commerce-roas-analysis"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            ROAS Analysis
          </Link>
        </div>
      )}
      {activeDropdown === "leadsCalculator" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            to="/leads-campaign-goals"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Campaign Goals
          </Link>
          <Link
            to="/leads-roas-maximizer"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            ROAS Maximizer
          </Link>
          <Link
            to="/leads-roas-analysis"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            ROAS Analysis
          </Link>
        </div>
      )}
      {activeDropdown === "performanceGrader" && (
        <div
          className="dropdown-options-sidebar"
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
        >
          <div className="dropdown-arrow"></div> {/* Arrow Indicator */}
          <Link
            to="/leads-facebook-grader"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Facebook Grader
          </Link>
          <Link
            to="/leads-google-grader"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Grader
          </Link>
          {/* <Link
            to="/leads-google-analytics"
            className="dropdown-option-sidebar"
            onClick={handleNavigation}
          >
            Google Analytics
          </Link> */}
        </div>
      )}
    </div>
  );
};

export default ShortSidebar;
