import React from "react";
import "./FacebookGradel.css";
import { Header } from "../../styles/styles";
import FacebookB2b from "./FacebookB2b";
import FacebookEcom from "./FacebookEcom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import EcomBox from "./e-commerce box.png";
import B2BBox from "./B2B box.png";
const Facebookgradel = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const option = queryParams.get("option");

  const history = useHistory();
  const handleClickOption = (value) => {
    history.push(`/leads-facebook-grader?option=${value}`);
  };
  return (
    <>
      {option === null && (
        <div className="meta-card-top-container">
          <Header className="meta-card-header-name">Choose Your Goal</Header>
          <div className="meta-card-center">
            <div onClick={() => handleClickOption("ecommerce")}>
              <img src={EcomBox} alt="ecomm" />
            </div>
            <div onClick={() => handleClickOption("b2b")}>
              <img src={B2BBox} alt="ecomm" />
            </div>
          </div>
        </div>
      )}
      {option === "b2b" && <FacebookB2b />}
      {option === "ecommerce" && <FacebookEcom />}
    </>
  );
};

export default Facebookgradel;
