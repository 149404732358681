import { StarFilled, StarOutlined } from '@ant-design/icons'
import moment from 'moment'
import React from 'react'

const BingAd = (props) => {
  const { item, index, handleAddToFav, handleRemoveFromFav } = props

  return (
    <div
      key={`url-${index}`}
      className="col-sm-4 mt-2"
      style={{ paddingLeft: '8px', paddingRight: '0px' }}
    >
      <div className="card h-100">
        <div className="">
          <div className="star-posi">
            {item.isFav || !handleAddToFav ? (
              <StarFilled
                style={{ cursor: 'pointer', color: '#175bcb' }}
                onClick={() => handleRemoveFromFav(item)}
              />
            ) : (
              <StarOutlined
                style={{ cursor: 'pointer', color: '#175bcb' }}
                onClick={() => handleAddToFav(item)}
              />
            )}
          </div>
          {/* {item.image && */}

          {/* <img
            src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${item.id}`}
            onError={({ currentTarget }) => {
              currentTarget.onError = null // prevents looping
              currentTarget.src = NoImage
            }}
            alt={'banner'}
            style={{
              margin: '12px auto 5px',
              display: 'block',
              maxHeight: '150px',
            }}
          /> */}
          {/* } */}
          <h5 className="card-title mt-3 mb-2">{item.title}</h5>
          <p className="adLine">
            <b>URL -</b> <a href={item.url}>Click here</a>
          </p>
          <p className="adLine">
            <b>Content -</b> {item.content}
          </p>
          <p className="adLine">
            <b>Last Seen -</b> {moment(item.lastSeen).format('DD/MM/YYYY')}
          </p>
          {/* <p className="adLine">Likes - {item.likes}</p>  */}
          <p className="adLine">
            <b>City -</b> {item.city}
          </p>
          <p className="adLine">
            <b>Country -</b>{' '}
            {item.country instanceof Array
              ? item.country.join(', ').replace(', LOCATION NOT FOUND', '')
              : item.country}
          </p>
        </div>
      </div>
    </div>
  )
}

export default BingAd
