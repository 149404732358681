import React from "react";
import { Carousel } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import "./style.css";

const GoogleShoppingListItem = props => {

    const { item } = props;

    const onChange = (a, b, c) => {
        // console.log(a, b, c);
    }

    return (
        <div className="card h-100">
            <div className="card-body">
                {item.product_images && item.product_images.length && (
                    item.product_images && item.product_images.length > 1 ?
                        <Carousel
                            autoplay
                            dots={{ className: "class-dot" }}
                            afterChange={onChange}
                        >
                            {item.product_images.map((p, idx) => {
                                return (
                                    <img
                                        key={`${item.title}-${idx}`}
                                        src={p}
                                        alt="pro"
                                        style={{ height: "300px" }}
                                    />
                                )
                            })}
                        </Carousel>
                        :
                        <img src={item.product_images[0]} alt="pro" style={{ height: '300px' }} />
                )}

                <h5 className="card-title mt-3 mb-2">{item.title}</h5>
                <p className="adLine mb-2" style={{ fontSize: '14px' }}>{item.price}</p>
                <p className="adLine" style={{ fontSize: '14px' }}>{item.description}</p>
                <p className="adLine" style={{ fontSize: '14px' }}>{item.type}</p>
                <a href={item.url} target="_blank" rel="noreferrer" style={{ fontSize: '14px' }}>
                    View Details
                    <span style={{ position: 'relative', left: '10px', top: '-2px' }}>
                        <LinkOutlined />
                    </span>
                </a>
            </div>
        </div>
    )
}

export default GoogleShoppingListItem;
