import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore, Timestamp } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDML2Kwv6T08PPtvnEXILE7nf-dZFvpIUA",
    authDomain: "boost-with-hma-6dbda.firebaseapp.com",
    databaseURL: "https://boost-with-hma-6dbda-default-rtdb.firebaseio.com",
    projectId: "boost-with-hma-6dbda",
    storageBucket: "boost-with-hma-6dbda.appspot.com",
    messagingSenderId: "125927670553",
    appId: "1:125927670553:web:6c45182e44863770fe3b88",
    measurementId: "G-NJ7DRDSJ0L"
};

if(firebase.app.length){
    firebase.initializeApp(firebaseConfig);
}

export const Auth = firebase.auth();
export const Database = getFirestore();
export const FirebaseTimestamp = Timestamp;