import { CaretDownFilled, LeftOutlined } from '@ant-design/icons'
import { Button, Row } from 'antd'
import { doc, getDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
import { CSVLink } from 'react-csv'
import { Link, useParams } from 'react-router-dom'
import { Sparklines, SparklinesBars } from 'react-sparklines'
import { PAGE_HEAD_TITLE } from '../../constants'
import { Database } from '../../firebase'
import UrlLinks from '../../styles/icons/redirect.png'
import UserContext from '../../userContext'
import { humanReadableNumber } from '../../utils/Helpers'
import { getKeywordCompetitionTag } from './keywordSearch'
import './style.css'

const ViewCollection = (props) => {
  const authUser = useContext(UserContext)
  const { id: collectionId } = useParams()

  const [collectionData, setCollectionData] = useState(null)
  const [flag, setflag] = useState(false)
  const [flag2, setflag2] = useState(false)
  const [downloadingCsv, setDownloadingCsv] = useState(false)
  const [csvData, setCsvData] = useState([])

  useEffect(() => {
    document.title = `Keyword Search Collection ${PAGE_HEAD_TITLE}`
  }, [])

  useEffect(() => {
    ;(async function () {
      await getUserCollectionItems()
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (collectionData) {
      const data = [
        ['Keyword Export'],
        ['Keyword', ':', collectionData.collectionName],
        [],
        ['Keyword', 'Monthly Search Volume', 'CPC', 'Competition'],
        [],
      ]
      collectionData.keywords.map((item) => {
        data.push([
          item.keyword,
          humanReadableNumber(item.searchVolume),
          item.cpc,
          item.competition,
        ])
        return item
      })
      setCsvData(data)
    }
  }, [collectionData])

  const getUserCollectionItems = async () => {
    var { uid } = authUser
    if (uid) {
      const docRef = doc(Database, 'keywordSearchCollection', collectionId)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const data = await docSnap.data()
        data.keywords = JSON.parse(data.keywords)
        // console.log("data:", data);
        setCollectionData(data)
      } else {
        console.log('No such document!')
      }
    }
  }

  const sortDataOnTheBasisOfMonVol = () => {
    const data = { ...collectionData }
    if (data.keywords.length) {
      if (!flag) {
        data.keywords = data.keywords.sort(
          (a, b) => a.searchVolume - b.searchVolume,
        )
        setCollectionData(data)
      } else {
        data.keywords = data.keywords.reverse()
        setCollectionData(data)
      }
    }
    setflag(!flag)
  }

  const sortDataOnTheBasisOfCpc = () => {
    const data = { ...collectionData }
    if (data.keywords.length) {
      if (!flag2) {
        data.keywords = data.keywords.sort(
          (a, b) => a.cpc.substring(2) - b.cpc.substring(2),
        )
        setCollectionData(data)
      } else {
        data.keywords = data.keywords.reverse()
        setCollectionData(data)
      }
    }
    setflag2(!flag2)
  }

  // console.log("collectionData:", collectionData)

  return (
    <div className="content-wrapper">
      <div className="collection-header">
        <Button
          title="Back"
          type="link"
          icon={
            <LeftOutlined style={{ verticalAlign: 'middle', marginRight: 5 }} />
          }
        >
          <Link
            style={{ verticalAlign: 'middle', fontWeight: 600 }}
            to="/keywords-search-collection"
          >
            Go Back
          </Link>
        </Button>
        {collectionData ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Row
              className="mb-1"
              style={{ float: 'right', display: 'inline-block' }}
            >
              <h2 className="explore-title">
                {collectionData.collectionName} (
                {collectionData.keywords.length})
              </h2>
            </Row>
            <Row style={{ display: 'block' }}>
              <CSVLink
                className="csv-download-button primary-button"
                data={csvData}
                onClick={() => {
                  // console.log("You click the link");
                  if (downloadingCsv) {
                    return false
                  } else {
                    setDownloadingCsv(true)
                    const timeout = setTimeout(() => {
                      setDownloadingCsv(false)
                      clearTimeout(timeout)
                    }, 3000)
                  }
                }}
                filename={'keyword-collection-export.csv'}
              >
                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
              </CSVLink>
            </Row>
          </div>
        ) : null}
      </div>

      <div className="cust-table">
        <Row className="fb_interest_list_page">
          {collectionData ? (
            <div className="fb_hidden_list display-table">
              <div
                className="table-tr ant-list-header"
                style={{ backgroundColor: '#175bcb' }}
              >
                <div className="table-td">Keywords</div>
                <div className="table-td" style={{ width: '180px' }}>
                  Monthly Search Volume
                  <CaretDownFilled onClick={sortDataOnTheBasisOfMonVol} />
                </div>
                <div className="table-td">
                  CPC
                  <CaretDownFilled onClick={sortDataOnTheBasisOfCpc} />
                </div>
                <div className="table-td">Competition</div>
                <div className="table-td">
                  Trends <br />
                  {'(Latest <- Oldest)'}
                </div>
                <div className="table-td">See on YT</div>
              </div>

              {collectionData.keywords.map((item, index) => {
                return (
                  <div key={index} className="table-tr">
                    <div className="table-td">{item.keyword}</div>
                    <div className="table-td">
                      {humanReadableNumber(item.searchVolume)}
                    </div>
                    <div className="table-td">{item.cpc}</div>
                    <div className="table-td">
                      {getKeywordCompetitionTag(item.competition)}
                    </div>
                    <div className="table-td">
                      <div style={{ width: '100px' }}>
                        <Sparklines
                          width={100}
                          height={25}
                          data={item.monthlySearches}
                        >
                          <SparklinesBars
                            style={{ fill: '#00bdcc', fillOpacity: '.5' }}
                          />
                        </Sparklines>
                      </div>
                    </div>
                    <div className="table-td">
                      <a
                        style={{ textAlign: 'center', display: 'block' }}
                        href={`https://www.youtube.com/results?search_query=${item.keyword}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={UrlLinks} alt="#" className="youtube_ref" />
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}
        </Row>
      </div>
    </div>
  )
}

export default ViewCollection
