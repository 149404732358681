import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Typography, Button, Input, Popover } from "antd";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { CSVLink } from "react-csv";
import UserContext from "../../userContext";
import { Flex, Title, BlueBackground, CalcTextA, WhiteBack, Padding } from '../../styles/PageStyle'
import { formatNumber, nFormatter, subscribeToExport } from '../../utils/Helpers';
import { PAGE_HEAD_TITLE } from "../../constants";
import LocationDropdown, { locationOptions } from "../locationDropdown";
import BunnyPlayer from '../BunnyPlayer';


const { Text } = Typography;

const RoasAnalysis = (props) => {

    const authUser = useContext(UserContext);

    //#region State
    const [locationData, setLocationData] = useState(locationOptions[6])
    // Facebook Ads Spend Per Month
    const [ExpectedAdsSpend1, setExpectedAdsSpend1] = useState(null)
    const [ExpectedAdsSpend2, setExpectedAdsSpend2] = useState(null)
    const [ExpectedAdsSpend3, setExpectedAdsSpend3] = useState(null)

    // Expected Revenue (Per Month)
    const [ExpectedRevenue1, setExpectedRevenue1] = useState(null)
    const [ExpectedRevenue2, setExpectedRevenue2] = useState(null)
    const [ExpectedRevenue3, setExpectedRevenue3] = useState(null)

    // Average Order Value (Per Transactions)
    const [AverageOrder1, setAverageOrder1] = useState(null)
    const [AverageOrder2, setAverageOrder2] = useState(null)
    const [AverageOrder3, setAverageOrder3] = useState(null)

    // Payment Gateway Fees (Per Transactions)
    const [PaymentGatewayFees1, setPaymentGatewayFees1] = useState(null)
    const [PaymentGatewayFees2, setPaymentGatewayFees2] = useState(null)
    const [PaymentGatewayFees3, setPaymentGatewayFees3] = useState(null)

    // Shipping Cost
    const [ShippingCost1, setShippingCost1] = useState(null);
    const [ShippingCost2, setShippingCost2] = useState(null);
    const [ShippingCost3, setShippingCost3] = useState(null);

    // Fulfillment Fees (Per Transactions)
    const [FulfillmentFees1, setFulfillmentFees1] = useState(null)
    const [FulfillmentFees2, setFulfillmentFees2] = useState(null)
    const [FulfillmentFees3, setFulfillmentFees3] = useState(null)

    // Cost of Good or Service (Per Transactions)
    const [CostofGoodOrService1, setCostofGoodOrService1] = useState(null)
    const [CostofGoodOrService2, setCostofGoodOrService2] = useState(null)
    const [CostofGoodOrService3, setCostofGoodOrService3] = useState(null)

    // Agency/ Freelancers / Tools Fees (Per Month)
    const [AgencyFreelancers1, setAgencyFreelancers1] = useState(null)
    const [AgencyFreelancers2, setAgencyFreelancers2] = useState(null)
    const [AgencyFreelancers3, setAgencyFreelancers3] = useState(null)

    // Revenue Identified for the Month
    const [RevenueIdentified1, setRevenueIdentified1] = useState(null)
    const [RevenueIdentified2, setRevenueIdentified2] = useState(null)
    const [RevenueIdentified3, setRevenueIdentified3] = useState(null)

    // ADs Budget Identified for the Month
    const [ADsBudgetIdentified1, setADsBudgetIdentified1] = useState(null);
    const [ADsBudgetIdentified2, setADsBudgetIdentified2] = useState(null);
    const [ADsBudgetIdentified3, setADsBudgetIdentified3] = useState(null);

    // Expected Sale in a Month
    const [ExpectedSale1, setExpectedSale1] = useState(null);
    const [ExpectedSale2, setExpectedSale2] = useState(null);
    const [ExpectedSale3, setExpectedSale3] = useState(null);

    // Credit Card Fees
    const [CreditCardFees1, setCreditCardFees1] = useState(null);
    const [CreditCardFees2, setCreditCardFees2] = useState(null);
    const [CreditCardFees3, setCreditCardFees3] = useState(null);

    // Shipping Cost
    const [ShippingCostAll1, setShippingCostAll1] = useState(null);
    const [ShippingCostAll2, setShippingCostAll2] = useState(null);
    const [ShippingCostAll3, setShippingCostAll3] = useState(null);

    // Fulfillment Fees
    const [FulfillmentAllFees1, setFulfillmentAllFees1] = useState(null);
    const [FulfillmentAllFees2, setFulfillmentAllFees2] = useState(null);
    const [FulfillmentAllFees3, setFulfillmentAllFees3] = useState(null);

    // Cost of Goods
    const [CostofGoodsSold1, setCostofGoodsSold1] = useState(null);
    const [CostofGoodsSold2, setCostofGoodsSold2] = useState(null);
    const [CostofGoodsSold3, setCostofGoodsSold3] = useState(null);

    // Agency/ Freelancers / Tools Fees --2
    const [AgencyFreelancersAll1, setAgencyFreelancersAll1] = useState(null);
    const [AgencyFreelancersAll2, setAgencyFreelancersAll2] = useState(null);
    const [AgencyFreelancersAll3, setAgencyFreelancersAll3] = useState(null);

    // Gross Profit
    const [GrossProfit1, setGrossProfit1] = useState(null);
    const [GrossProfit2, setGrossProfit2] = useState(null);
    const [GrossProfit3, setGrossProfit3] = useState(null);

    // Net Profit
    const [NetProfit1, setNetProfit1] = useState(null);
    const [NetProfit2, setNetProfit2] = useState(null);
    const [NetProfit3, setNetProfit3] = useState(null);

    // Adjusted Cost of Goods
    const [AdjustedCostOfGoods1, setAdjustedCostOfGoods1] = useState(null);
    const [AdjustedCostOfGoods2, setAdjustedCostOfGoods2] = useState(null);
    const [AdjustedCostOfGoods3, setAdjustedCostOfGoods3] = useState(null);

    // Return on Ad Spend (ROAS)
    const [ReturnOnAdSpend1, setReturnOnAdSpend1] = useState(null);
    const [ReturnOnAdSpend2, setReturnOnAdSpend2] = useState(null);
    const [ReturnOnAdSpend3, setReturnOnAdSpend3] = useState(null);

    // Adjusted Cost of Goods
    const [CostPerAcquisition1, setCostPerAcquisition1] = useState(null);
    const [CostPerAcquisition2, setCostPerAcquisition2] = useState(null);
    const [CostPerAcquisition3, setCostPerAcquisition3] = useState(null);

    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const [csvData, setCsvData] = useState([]);
    //#endregion

    var Loss = { backgroundColor: '#EB5757' }
    var Profit = { backgroundColor: '#8DD1B6' }

    //#region UseEffects
    useEffect(() => {
        document.title = `ROAS Analysis ${PAGE_HEAD_TITLE}`;
    }, []);

    useEffect(() => {

        const currencySymbol = locationData.currencySymbol;

        const budget1 = nFormatter(ExpectedAdsSpend1) || '2K';
        const budget2 = nFormatter(ExpectedAdsSpend2) || '5K';
        const budget3 = nFormatter(ExpectedAdsSpend3) || '10K';
        const data = [
            ["Boost With HMA"],
            [],
            ["ROAS Analysis", "", "", `Currency - ${currencySymbol}`],
            [],
            ["Ecommerce Ads Metrics", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],
            ["Expected Ads Spend Level (Per Month)", `${currencySymbol} ${formatNumber(ExpectedAdsSpend1)}`, `${currencySymbol} ${formatNumber(ExpectedAdsSpend2)}`, `${currencySymbol} ${formatNumber(ExpectedAdsSpend3)}`],
            ["Expected Revenue (Per Month)", `${currencySymbol} ${formatNumber(ExpectedRevenue1)}`, `${currencySymbol} ${formatNumber(ExpectedRevenue2)}`, `${currencySymbol} ${formatNumber(ExpectedRevenue3)}`],
            ["Average Order Value (Per Transactions)", `${currencySymbol} ${formatNumber(AverageOrder1)}`, `${currencySymbol} ${formatNumber(AverageOrder2)}`, `${currencySymbol} ${formatNumber(AverageOrder3)}`],
            ["Payment Gateway Fees (Per Transactions)", `% ${formatNumber(PaymentGatewayFees1)}`, `% ${formatNumber(PaymentGatewayFees2)}`, `% ${formatNumber(PaymentGatewayFees3)}`],
            ["Shipping Cost (Per Transaction)", `${currencySymbol} ${formatNumber(ShippingCost1)}`, `${currencySymbol} ${formatNumber(ShippingCost2)}`, `${currencySymbol} ${formatNumber(ShippingCost3)}`],
            ["Fulfillment Fees (Per Transactions)", `${currencySymbol} ${formatNumber(FulfillmentFees1)}`, `${currencySymbol} ${formatNumber(FulfillmentFees2)}`, `${currencySymbol} ${formatNumber(FulfillmentFees3)}`],
            ["Cost of Good or Service (Per Transactions)", `${currencySymbol} ${formatNumber(CostofGoodOrService1)}`, `${currencySymbol} ${formatNumber(CostofGoodOrService2)}`, `${currencySymbol} ${formatNumber(CostofGoodOrService3)}`],
            ["Agency/ Freelancers / Tools Fees (Per Month)", `${currencySymbol} ${formatNumber(AgencyFreelancers1)}`, `${currencySymbol} ${formatNumber(AgencyFreelancers2)}`, `${currencySymbol} ${formatNumber(AgencyFreelancers3)}`],

            [],
            ["Expected Calculations Monthly", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],

            ["Revenue Identified for the Month", `${currencySymbol} ${formatNumber(RevenueIdentified1)}`, `${currencySymbol} ${formatNumber(RevenueIdentified2)}`, `${currencySymbol} ${formatNumber(RevenueIdentified3)}`],
            ["ADs Budget Identified for the Month", `${currencySymbol} ${formatNumber(ADsBudgetIdentified1)}`, `${currencySymbol} ${formatNumber(ADsBudgetIdentified2)}`, `${currencySymbol} ${formatNumber(ADsBudgetIdentified3)}`],
            ["Expected Sales in a Month", `${formatNumber(ExpectedSale1)}`, `${formatNumber(ExpectedSale2)}`, `${formatNumber(ExpectedSale3)}`],
            ["Credit Card Fees", `${currencySymbol} ${formatNumber(CreditCardFees1)}`, `${currencySymbol} ${formatNumber(CreditCardFees2)}`, `${currencySymbol} ${formatNumber(CreditCardFees3)}`],
            ["Shipping Cost", `${currencySymbol} ${formatNumber(ShippingCostAll1)}`, `${currencySymbol} ${formatNumber(ShippingCostAll2)}`, `${currencySymbol} ${formatNumber(ShippingCostAll3)}`],
            ["Fulfillment Fees", `${currencySymbol} ${formatNumber(FulfillmentAllFees1)}`, `${currencySymbol} ${formatNumber(FulfillmentAllFees2)}`, `${currencySymbol} ${formatNumber(FulfillmentAllFees3)}`],
            ["Cost of Goods", `${currencySymbol} ${formatNumber(CostofGoodsSold1)}`, `${currencySymbol} ${formatNumber(CostofGoodsSold2)}`, `${currencySymbol} ${formatNumber(CostofGoodsSold3)}`],
            ["Agency/ Freelancers / Tools Fees", `${currencySymbol} ${formatNumber(AgencyFreelancersAll1)}`, `${currencySymbol} ${formatNumber(AgencyFreelancersAll2)}`, `${currencySymbol} ${formatNumber(AgencyFreelancersAll3)}`],

            [],
            ["Actual Ads Results", `${budget1} Budget`, `${budget2} Budget`, `${budget3} Budget`],

            ["Gross Profit", `${currencySymbol} ${formatNumber(GrossProfit1)}`, `${currencySymbol} ${formatNumber(GrossProfit2)}`, `${currencySymbol} ${formatNumber(GrossProfit3)}`],
            ["Net Profit", `${currencySymbol} ${formatNumber(NetProfit1)}`, `${currencySymbol} ${formatNumber(NetProfit2)}`, `${currencySymbol} ${formatNumber(NetProfit3)}`],
            ["Adjusted Cost of Goods", `${currencySymbol} ${formatNumber(AdjustedCostOfGoods1)}`, `${currencySymbol} ${formatNumber(AdjustedCostOfGoods2)}`, `${currencySymbol} ${formatNumber(AdjustedCostOfGoods3)}`],
            ["Return on Ad Spend (ROAS)", `% ${formatNumber(ReturnOnAdSpend1)}`, `% ${formatNumber(ReturnOnAdSpend2)}`, `% ${formatNumber(ReturnOnAdSpend3)}`],
            ["Cost Per Acquisition (CPA)", `% ${formatNumber(CostPerAcquisition1)}`, `% ${formatNumber(CostPerAcquisition2)}`, `% ${formatNumber(CostPerAcquisition3)}`],
        ];
        setCsvData(data);
    }, [
        locationData,
        ExpectedAdsSpend1, ExpectedAdsSpend2, ExpectedAdsSpend3,
        ExpectedRevenue1, ExpectedRevenue2, ExpectedRevenue3,
        AverageOrder1, AverageOrder2, AverageOrder3,
        PaymentGatewayFees1, PaymentGatewayFees2, PaymentGatewayFees3,
        ShippingCost1, ShippingCost2, ShippingCost3,
        FulfillmentFees1, FulfillmentFees2, FulfillmentFees3,
        CostofGoodOrService1, CostofGoodOrService2, CostofGoodOrService3,
        AgencyFreelancers1, AgencyFreelancers2, AgencyFreelancers3,
        RevenueIdentified1, RevenueIdentified2, RevenueIdentified3,
        ADsBudgetIdentified1, ADsBudgetIdentified2, ADsBudgetIdentified3,
        ExpectedSale1, ExpectedSale2, ExpectedSale3,
        CreditCardFees1, CreditCardFees2, CreditCardFees3,
        ShippingCostAll1, ShippingCostAll2, ShippingCostAll3,
        FulfillmentAllFees1, FulfillmentAllFees2, FulfillmentAllFees3,
        CostofGoodsSold1, CostofGoodsSold2, CostofGoodsSold3,
        AgencyFreelancersAll1, AgencyFreelancersAll2, AgencyFreelancersAll3,
        GrossProfit1, GrossProfit2, GrossProfit3,
        NetProfit1, NetProfit2, NetProfit3,
        AdjustedCostOfGoods1, AdjustedCostOfGoods2, AdjustedCostOfGoods3,
        ReturnOnAdSpend1, ReturnOnAdSpend2, ReturnOnAdSpend3,
        CostPerAcquisition1, CostPerAcquisition2, CostPerAcquisition3,
    ])

    // Shipping Cost (Per Transaction) feild pending to add

    const handleExpectedAdsSpend = (value, column) => {
        if (column === 1) {
            if (value <= 1e9) setExpectedAdsSpend1(value);
            setExpectedAdsSpend1(value);
            setADsBudgetIdentified1(value);
            handleReturnOnAdSpend(RevenueIdentified1, value, column);
        } else if (column === 2) {
            if (value <= 1e9) setExpectedAdsSpend2(value);
            setExpectedAdsSpend2(value);
            setADsBudgetIdentified2(value);
            handleReturnOnAdSpend(RevenueIdentified2, value, column);
        } else if (column === 3) {
            if (value <= 1e9) setExpectedAdsSpend3(value);
            setExpectedAdsSpend3(value);
            setADsBudgetIdentified3(value);
            handleReturnOnAdSpend(RevenueIdentified3, value, column);
        }
    }

    const handleExpectedRevenue = (value, column) => {
        if (column === 1) {
            setExpectedRevenue1(value);
            setRevenueIdentified1(value);
            handleExpectedSale(AverageOrder1, value, column);
            handleCreditCardFees(value, PaymentGatewayFees1, column);
            handleGrossProfit(value, CostofGoodsSold1, column);
            handleReturnOnAdSpend(value, ADsBudgetIdentified1, column);
        } else if (column === 2) {
            setExpectedRevenue2(value);
            setRevenueIdentified2(value);
            handleExpectedSale(AverageOrder2, value, column);
            handleCreditCardFees(value, PaymentGatewayFees2, column);
            handleGrossProfit(value, CostofGoodsSold2, column);
            handleReturnOnAdSpend(value, ADsBudgetIdentified2, column);
        } else if (column === 3) {
            setExpectedRevenue3(value);
            setRevenueIdentified3(value);
            handleExpectedSale(AverageOrder3, value, column);
            handleCreditCardFees(value, PaymentGatewayFees3, column);
            handleGrossProfit(value, CostofGoodsSold3, column);
            handleReturnOnAdSpend(value, ADsBudgetIdentified3, column);
        }
    }

    const handleAverageOrder = (value, column) => {
        if (column === 1) {
            setAverageOrder1(value);
            handleExpectedSale(value, RevenueIdentified1, column);
            handleCostPerAcquisition(value, ReturnOnAdSpend1, column);
        } else if (column === 2) {
            setAverageOrder2(value);
            handleExpectedSale(value, RevenueIdentified2, column);
            handleCostPerAcquisition(value, ReturnOnAdSpend2, column);
        } else if (column === 3) {
            setAverageOrder3(value);
            handleExpectedSale(value, RevenueIdentified3, column);
            handleCostPerAcquisition(value, ReturnOnAdSpend3, column);
        }
    }

    const handlePaymentGatewayFees = (value, column) => {
        if (column === 1) {
            setPaymentGatewayFees1(value);
            handleCreditCardFees(RevenueIdentified1, value, column);
        } else if (column === 2) {
            setPaymentGatewayFees2(value);
            handleCreditCardFees(RevenueIdentified2, value, column);
        } else if (column === 3) {
            setPaymentGatewayFees3(value);
            handleCreditCardFees(RevenueIdentified3, value, column);
        }
    }

    const handleShippingCost = (value, column) => {
        if (column === 1) {
            setShippingCost1(value);
            handleShippingCostAll(ExpectedSale1, value, column);
        } else if (column === 2) {
            setShippingCost2(value);
            handleShippingCostAll(ExpectedSale2, value, column);
        } else if (column === 3) {
            setShippingCost3(value);
            handleShippingCostAll(ExpectedSale3, value, column);
        }
    }

    const handleFulfillmentFees = (value, column) => {
        if (column === 1) {
            setFulfillmentFees1(value);
            handleFulfillmentFeesAll(ExpectedSale1, value, column);
        } else if (column === 2) {
            setFulfillmentFees2(value);
            handleFulfillmentFeesAll(ExpectedSale2, value, column);
        } else if (column === 3) {
            setFulfillmentFees3(value);
            handleFulfillmentFeesAll(ExpectedSale3, value, column);
        }
    }

    const handleCostofGoodOrServiceOrService = (value, column) => {
        if (column === 1) {
            setCostofGoodOrService1(value);
            handleCostOfGoodsSold(ExpectedSale1, value, column);
        } else if (column === 2) {
            setCostofGoodOrService2(value);
            handleCostOfGoodsSold(ExpectedSale2, value, column);
        } else if (column === 3) {
            setCostofGoodOrService3(value);
            handleCostOfGoodsSold(ExpectedSale3, value, column);
        }
    }

    const handleAgencyFreelancersToolsFees = (value, column) => {
        if (column === 1) {
            setAgencyFreelancers1(value);
            setAgencyFreelancersAll1(value);
        } else if (column === 2) {
            setAgencyFreelancers2(value);
            setAgencyFreelancersAll2(value);
        } else if (column === 3) {
            setAgencyFreelancers3(value);
            setAgencyFreelancersAll3(value);
        }
    }

    const handleExpectedSale = (averageOrder, revenueIdentified, column) => {
        const expectedSale = averageOrder ? (revenueIdentified / averageOrder).toFixed(2) : 0;
        if (column === 1) {
            setExpectedSale1(expectedSale);
            handleShippingCostAll(expectedSale, ShippingCost1, column);
            handleFulfillmentFeesAll(expectedSale, FulfillmentFees1, column);
            handleCostOfGoodsSold(expectedSale, CostofGoodOrService1, column);
        } else if (column === 2) {
            setExpectedSale2(expectedSale);
            handleShippingCostAll(expectedSale, ShippingCost2, column);
            handleFulfillmentFeesAll(expectedSale, FulfillmentFees2, column);
            handleCostOfGoodsSold(expectedSale, CostofGoodOrService2, column);
        } else if (column === 3) {
            setExpectedSale3(expectedSale);
            handleShippingCostAll(expectedSale, ShippingCost3, column);
            handleFulfillmentFeesAll(expectedSale, FulfillmentFees3, column);
            handleCostOfGoodsSold(expectedSale, CostofGoodOrService3, column);
        }
    }

    const handleCreditCardFees = (revenueIdentified, paymentGateway, column) => {
        if (column === 1) {
            setCreditCardFees1(revenueIdentified * paymentGateway / 100);
        } else if (column === 2) {
            setCreditCardFees2(revenueIdentified * paymentGateway / 100);
        } else if (column === 3) {
            setCreditCardFees3(revenueIdentified * paymentGateway / 100);
        }
    }

    const handleShippingCostAll = (expectedSalesfor, shippingCost, column) => {
        if (column === 1) {
            setShippingCostAll1(expectedSalesfor * shippingCost);
        } else if (column === 2) {
            setShippingCostAll2(expectedSalesfor * shippingCost);
        } else if (column === 3) {
            setShippingCostAll3(expectedSalesfor * shippingCost);
        }
    }

    const handleFulfillmentFeesAll = (expectedSale, fulfillmentFees, column) => {
        if (column === 1) {
            setFulfillmentAllFees1(expectedSale * fulfillmentFees);
        } else if (column === 2) {
            setFulfillmentAllFees2(expectedSale * fulfillmentFees);
        } else if (column === 3) {
            setFulfillmentAllFees3(expectedSale * fulfillmentFees);
        }
    }

    const handleCostOfGoodsSold = (expectedSale, costofGoodOrService, column) => {
        const costofGoodsSold = expectedSale * costofGoodOrService;
        if (column === 1) {
            setCostofGoodsSold1(costofGoodsSold);
            handleGrossProfit(RevenueIdentified1, costofGoodsSold, column);
        } else if (column === 2) {
            setCostofGoodsSold2(costofGoodsSold);
            handleGrossProfit(RevenueIdentified2, costofGoodsSold, column);
        } else if (column === 3) {
            setCostofGoodsSold3(costofGoodsSold);
            handleGrossProfit(RevenueIdentified3, costofGoodsSold, column);
        }
    }

    const handleGrossProfit = (revenueIdentified, costofGoodsSold, column) => {
        if (column === 1) {
            setGrossProfit1(revenueIdentified - costofGoodsSold);
        } else if (column === 2) {
            setGrossProfit2(revenueIdentified - costofGoodsSold);
        } else if (column === 3) {
            setGrossProfit3(revenueIdentified - costofGoodsSold);
        }
    }

    useEffect(() => {
        // handleNetProfit
        setNetProfit1(GrossProfit1 - ADsBudgetIdentified1 - CreditCardFees1 - ShippingCostAll1 - FulfillmentAllFees1 - AgencyFreelancersAll1)
    }, [GrossProfit1, ADsBudgetIdentified1, CreditCardFees1, ShippingCostAll1, FulfillmentAllFees1, AgencyFreelancersAll1])

    useEffect(() => {
        // handleNetProfit
        setNetProfit2(GrossProfit2 - ADsBudgetIdentified2 - CreditCardFees2 - ShippingCostAll2 - FulfillmentAllFees2 - AgencyFreelancersAll2)
    }, [GrossProfit2, ADsBudgetIdentified2, CreditCardFees2, ShippingCostAll2, FulfillmentAllFees2, AgencyFreelancersAll2])

    useEffect(() => {
        // handleNetProfit
        setNetProfit3(GrossProfit3 - ADsBudgetIdentified3 - CreditCardFees3 - ShippingCostAll3 - FulfillmentAllFees3 - AgencyFreelancersAll3)
    }, [GrossProfit3, ADsBudgetIdentified3, CreditCardFees3, ShippingCostAll3, FulfillmentAllFees3, AgencyFreelancersAll3])

    useEffect(() => {
        // handleAdjustedCostOfGoods
        const adjustCost = Number(CreditCardFees1 + ShippingCostAll1 + FulfillmentAllFees1 + CostofGoodsSold1) + Number(AgencyFreelancersAll1)
        // console.log('adjustCost', adjustCost);
        const adjustcostsDivid = adjustCost / ExpectedSale1
        setAdjustedCostOfGoods1(adjustcostsDivid.toFixed(2))
    }, [CreditCardFees1, ShippingCostAll1, FulfillmentAllFees1, CostofGoodsSold1, AgencyFreelancersAll1, ExpectedSale1]) //eslint-disable-line

    useEffect(() => {
        // handleAdjustedCostOfGoods
        const adjustCost = Number(CreditCardFees2 + ShippingCostAll2 + FulfillmentAllFees2 + CostofGoodsSold2) + Number(AgencyFreelancersAll2)
        // console.log('adjustCost', adjustCost);
        const adjustcostsDivid = adjustCost / ExpectedSale2
        setAdjustedCostOfGoods2(adjustcostsDivid.toFixed(2))
    }, [CreditCardFees2, ShippingCostAll2, FulfillmentAllFees2, CostofGoodsSold2, AgencyFreelancersAll2, ExpectedSale2]) //eslint-disable-line

    useEffect(() => {
        // handleAdjustedCostOfGoods
        const adjustCost = Number(CreditCardFees3 + ShippingCostAll3 + FulfillmentAllFees3 + CostofGoodsSold3) + Number(AgencyFreelancersAll3)
        // console.log('adjustCost', adjustCost);
        const adjustcostsDivid = adjustCost / ExpectedSale3
        setAdjustedCostOfGoods3(adjustcostsDivid.toFixed(2))
    }, [CreditCardFees3, ShippingCostAll3, FulfillmentAllFees3, CostofGoodsSold3, AgencyFreelancersAll3, ExpectedSale3]) //eslint-disable-line

    const handleReturnOnAdSpend = (revenueIdentified, adsBudgetIdentified, column) => {
        const returnOnAdSpend = revenueIdentified / adsBudgetIdentified
        if (column === 1) {
            setReturnOnAdSpend1(returnOnAdSpend);
            handleCostPerAcquisition(AverageOrder1, returnOnAdSpend, column);
        } else if (column === 2) {
            setReturnOnAdSpend2(returnOnAdSpend);
            handleCostPerAcquisition(AverageOrder2, returnOnAdSpend, column);
        } else if (column === 3) {
            setReturnOnAdSpend3(returnOnAdSpend);
            handleCostPerAcquisition(AverageOrder1, returnOnAdSpend, column);
        }
    }

    const handleCostPerAcquisition = (averageOrder, returnOnAdSpend, column) => {
        const coa = averageOrder / returnOnAdSpend
        if (column === 1) {
            setCostPerAcquisition1(coa.toFixed(2));
        } else if (column === 2) {
            setCostPerAcquisition2(coa.toFixed(2));
        } else if (column === 3) {
            setCostPerAcquisition3(coa.toFixed(2));
        }
    }

    //#endregion

    const downloadPdfDocument = () => {
        // const downloadPdfDocument = (rootElementId) => {
        // const input = document.getElementById(rootElementId);
        setDownloadingPdf(true);
        const input = document.getElementById('divToDownload');
        // html2canvas(input, { scale: 0.81 })
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "mm", "a4");
                // const width = pdf.internal.pageSize.getWidth() - 14;
                // const height = pdf.internal.pageSize.getHeight();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth() - 14;

                const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + 5;
                pdf.text("Boost With HMA", 5, 10);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'JPEG', 5, 14);
                // pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight);
                pdf.addImage(imgData, 'PNG', 5, 14, pdfWidth, pdfHeight, "a", "FAST");

                pdf.save("download.pdf", { returnPromise: true })
                    .then(() => {
                        setDownloadingPdf(false);
                    });
            })
    }

    const currencySymbol = locationData.currencySymbol;

    return (
        <Row justify="center" className='facg-main-wrapper' style={{ marginTop: '30px' }}>
            <BunnyPlayer type="ECOM_ROAS_ANALYSIS" />
            <Col span={24} style={{ marginTop: "4rem", marginBottom: '6rem' }}>
                <Row justify="center">
                    <Col span={20} id="divToDownload">
                        <Row justify="space-between" style={{ marginBottom: 16, alignItems: 'center' }}>
                            <Col xs={12}>
                                <Text level={5} style={{ fontWeight: "550", fontSize: 18 }}>
                                    ROAS Analysis
                                </Text>
                            </Col>
                            <Col xs={12} >
                                <Flex style={{ justifyContent: 'flex-end' }}>
                                    <LocationDropdown defaultValue={locationData} onChange={(value) => { setLocationData(value) }} />
                                </Flex>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <Title>Ecommerce Ads Metrics</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ExpectedAdsSpend1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <Row>
                            <WhiteBack>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Expected Ads Spend Level (Per Month)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                value={ExpectedAdsSpend1}
                                                type="text"
                                                max={10}
                                                prefix={currencySymbol}
                                                onChange={(e) => handleExpectedAdsSpend(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                value={ExpectedAdsSpend2}
                                                max={10}
                                                type="text"
                                                prefix={currencySymbol}
                                                onChange={(e) => handleExpectedAdsSpend(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                value={ExpectedAdsSpend3}
                                                max={10}
                                                type="text"
                                                prefix={currencySymbol}
                                                onChange={(e) => handleExpectedAdsSpend(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Expected Revenue (Per Month)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={ExpectedRevenue1}
                                                onChange={(e) => handleExpectedRevenue(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={ExpectedRevenue2}
                                                onChange={(e) => handleExpectedRevenue(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={ExpectedRevenue3}
                                                onChange={(e) => handleExpectedRevenue(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Average Order Value (Per Transactions)</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AverageOrder1}
                                            onChange={(e) => handleAverageOrder(e.target.value, 1)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AverageOrder2}
                                            onChange={(e) => handleAverageOrder(e.target.value, 2)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AverageOrder3}
                                            onChange={(e) => handleAverageOrder(e.target.value, 3)}
                                        />
                                    </Col>
                                </Row>
                                </Padding>
                                <Padding> <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Payment Gateway Fees (Per Transactions)</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            value={PaymentGatewayFees1}
                                            onChange={(e) => handlePaymentGatewayFees(e.target.value, 1)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            value={PaymentGatewayFees2}
                                            onChange={(e) => handlePaymentGatewayFees(e.target.value, 2)}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            value={PaymentGatewayFees3}
                                            onChange={(e) => handlePaymentGatewayFees(e.target.value, 3)}
                                        />
                                    </Col>
                                </Row>
                                </Padding>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Shipping Cost (Per Transaction)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={ShippingCost1}
                                                onChange={(e) => handleShippingCost(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={ShippingCost2}
                                                onChange={(e) => handleShippingCost(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={ShippingCost3}
                                                onChange={(e) => handleShippingCost(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Fulfillment Fees (Per Transactions)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={FulfillmentFees1}
                                                onChange={(e) => handleFulfillmentFees(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={FulfillmentFees2}
                                                onChange={(e) => handleFulfillmentFees(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                type="number"
                                                prefix={currencySymbol}
                                                value={FulfillmentFees3}
                                                onChange={(e) => handleFulfillmentFees(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Cost of Good or Service (Per Transactions)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={CostofGoodOrService1}
                                                onChange={(e) => handleCostofGoodOrServiceOrService(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={CostofGoodOrService2}
                                                onChange={(e) => handleCostofGoodOrServiceOrService(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={CostofGoodOrService3}
                                                onChange={(e) => handleCostofGoodOrServiceOrService(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                                <Padding>
                                    <Row justify="space-between">
                                        <Col sm={10}>
                                            <CalcTextA>Agency/ Freelancers / Tools Fees (Per Month)</CalcTextA>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={AgencyFreelancers1}
                                                onChange={(e) => handleAgencyFreelancersToolsFees(e.target.value, 1)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={AgencyFreelancers2}
                                                onChange={(e) => handleAgencyFreelancersToolsFees(e.target.value, 2)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                prefix={currencySymbol}
                                                type="number"
                                                value={AgencyFreelancers3}
                                                onChange={(e) => handleAgencyFreelancersToolsFees(e.target.value, 3)}
                                            />
                                        </Col>
                                    </Row>
                                </Padding>
                            </WhiteBack>
                        </Row>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <Title>Expected Calculations Monthly</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ExpectedAdsSpend1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <WhiteBack>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Revenue Identified for the Month</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={RevenueIdentified1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={RevenueIdentified2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={RevenueIdentified3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>ADs Budget Identified for the Month</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ADsBudgetIdentified1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ADsBudgetIdentified2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ADsBudgetIdentified3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Expected Sales in a Month</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ExpectedSale1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ExpectedSale2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ExpectedSale3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Credit Card Fees</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CreditCardFees1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CreditCardFees2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CreditCardFees3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Shipping Cost</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ShippingCostAll1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ShippingCostAll2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={ShippingCostAll3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Fulfillment Fees</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={FulfillmentAllFees1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={FulfillmentAllFees2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={FulfillmentAllFees3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Cost of Goods</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CostofGoodsSold1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CostofGoodsSold2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={CostofGoodsSold3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Agency/ Freelancers / Tools Fees</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={AgencyFreelancersAll1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={AgencyFreelancersAll2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            value={AgencyFreelancersAll3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                        </WhiteBack>
                        <Row style={{ width: '100%' }}>
                            <BlueBackground>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <Title>Actual Ads Results</Title>
                                    </Col>
                                    <Col sm={4} style={{ maxWidth: 'auto' }}>
                                        <Title>{nFormatter(ExpectedAdsSpend1) || '2K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend2) || '5K'} Budget</Title>
                                    </Col>
                                    <Col sm={4}>
                                        <Title>{nFormatter(ExpectedAdsSpend3) || '10K'} Budget</Title>
                                    </Col>
                                </Row>
                            </BlueBackground>
                        </Row>
                        <WhiteBack>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Gross Profit</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={GrossProfit1}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={GrossProfit2}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={GrossProfit3}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Net Profit</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={NetProfit1}
                                            style={NetProfit1 < 0 ? Loss : Profit}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={NetProfit2}
                                            style={NetProfit2 < 0 ? Loss : Profit}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={NetProfit3}
                                            style={NetProfit3 < 0 ? Loss : Profit}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Adjusted Cost of Goods</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AdjustedCostOfGoods1}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AdjustedCostOfGoods2}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={currencySymbol}
                                            value={AdjustedCostOfGoods3}
                                            style={{ backgroundColor: '#F3F3F3' }}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Return on Ad Spend (ROAS)</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            prefix={'%'}
                                            style={ReturnOnAdSpend1 < 0 ? Loss : Profit}
                                            value={ReturnOnAdSpend1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            style={ReturnOnAdSpend2 < 0 ? Loss : Profit}
                                            value={ReturnOnAdSpend2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            style={ReturnOnAdSpend3 < 0 ? Loss : Profit}
                                            value={ReturnOnAdSpend3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                            <Padding>
                                <Row justify="space-between">
                                    <Col sm={10}>
                                        <CalcTextA>Cost Per Acquisition (CPA)</CalcTextA>
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            style={CostPerAcquisition1 < 0 ? Loss : Profit}
                                            value={CostPerAcquisition1}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            style={CostPerAcquisition2 < 0 ? Loss : Profit}
                                            value={CostPerAcquisition2}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Input
                                            prefix={'%'}
                                            type="number"
                                            style={CostPerAcquisition3 < 0 ? Loss : Profit}
                                            value={CostPerAcquisition3}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Padding>
                        </WhiteBack>
                    </Col>
                </Row>
                {
                    authUser.accountType === "FREE" ?
                        <Row justify="center" style={{ marginTop: '2rem' }}>
                            <Popover content={subscribeToExport} className="">
                                <Button className="primary-button not-allowed-button">Export as PDF</Button>
                            </Popover>

                            <Popover content={subscribeToExport} className="" >
                                <Button className="primary-button not-allowed-button" style={{ marginLeft: '20px' }}>Export as CSV</Button>
                            </Popover>
                        </Row>
                        :
                        <Row justify="center">
                            <Button className="primary-button mt-2rem" onClick={downloadPdfDocument} disabled={downloadingPdf}>{downloadingPdf ? 'Exporting...' : 'Export as PDF'}</Button>
                            <CSVLink
                                className="csv-download-button primary-button mt-2rem"
                                data={csvData}
                                onClick={() => {
                                    // console.log("You click the link");
                                    if (downloadingCsv) {
                                        return false;
                                    } else {
                                        setDownloadingCsv(true);
                                        const timeout = setTimeout(() => {
                                            setDownloadingCsv(false);
                                            clearTimeout(timeout);
                                        }, 3000)
                                    }
                                }}
                                filename={"roas-analysis.csv"}
                            >
                                {downloadingCsv ? 'Exporting...' : 'Export as CSV'}
                            </CSVLink>
                        </Row>
                }
            </Col>
        </Row>
    );
}

export default RoasAnalysis;
