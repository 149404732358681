import { useState, useContext } from "react";
import { Row, Col, Avatar, Dropdown, Menu, Badge } from "antd";
import { Link, useHistory } from "react-router-dom";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import logo from "../../styles/images/logo.svg";
import { BellFilled, BellOutlined, DownOutlined } from "@ant-design/icons";
import UserContext from "../../userContext";
import NotificationBar from "../NotificationBar/NotificationBar";
import { Auth, Database } from "../../firebase";
import "./Navbar.css";

const style = {
  // border:"2px solid red",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  padding: "10px 50px 10px 10px",
  backgroundColor: "white",
};

const NavBar = ({ notifications }) => {
  // console.log("notifications:", notifications);
  const user = useContext(UserContext);

  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const showDrawer = async () => {
    setVisible(true);

    if (notifications.unreadMessageArray.length) {
      const { uid } = user;

      // console.log("update_read")

      notifications.unreadMessageArray.map(async (item) => {
        const notifRef = doc(Database, "notifications", item);

        // Atomically add a new region to the "regions" array field.
        await updateDoc(notifRef, {
          readByUser: arrayUnion(uid),
        });
      });
      notifications.setUnreadCount(0);
      notifications.setUnreadMessageArray([]);
    }
  };

  const LogOut = () => {
    Auth.signOut();
    localStorage.removeItem("token");
    localStorage.removeItem("uid");
    history.push("/");
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="plans">
        <Link to="/plans">Subscription Plans</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <a href="#logout" onClick={LogOut}>
          Log Out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row style={style} justify="space-between">
      {/* notifications  */}
      {visible && (
        <NotificationBar
          visible={visible}
          setVisible={setVisible}
          notifications={notifications.notifications}
        />
      )}

      <Col span={16}>
        <Row>
          <Link to="/dashboard">
            <img src={logo} alt="Boost with HMA" className="logo" />
          </Link>
        </Row>
      </Col>
      <Col>
        <Row justify="end">
          <Col style={{ padding: "5px 10px" }}>
            <div
              style={{ paddingTop: notifications.unreadCount ? "10px" : "8px" }}
            >
              <span onClick={showDrawer} style={{ cursor: "pointer" }}>
                <Badge count={notifications.unreadCount} color="#175BCB">
                  {notifications.unreadCount ? (
                    <BellFilled style={{ fontSize: "20px" }} />
                  ) : (
                    <BellOutlined style={{ fontSize: "20px" }} />
                  )}
                </Badge>
              </span>
            </div>
          </Col>
          <Col style={{ padding: "10px" }}>
            <Dropdown overlay={userMenu} trigger={["click"]}>
              <a
                href="/#"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Avatar>
                  {user.displayName?.charAt(0) || user.email?.charAt(0)}
                </Avatar>
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {user.displayName || user.email}
                </span>
                <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NavBar;
