import moment from 'moment'

import { notification } from 'antd'
import { doc, getDoc, increment, updateDoc } from 'firebase/firestore'
import {
  FREE_KEYWORD_RESEARCH_QUOTA,
  FREE_KEYWORD_RESEARCH_QUOTA_BING,
  FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
} from '../constants'
import { Database } from '../firebase'
// type: KEYWORD_LABS, AI_REQUEST
export const checkQuotaValidation = async (type, user, data) => {
  const accountData = user.accountData
  let subscriptionPlan =
    accountData && accountData.subscriptionPlan
      ? accountData.subscriptionPlan
      : null
  let message = ''
  // const accountType = subscriptionPlan && subscriptionPlan.planId ? subscriptionPlan.planId : "FREE";
  let accountType = 'FREE'
  if (subscriptionPlan && subscriptionPlan.planId) {
    if (subscriptionPlan.planId === 'ADMIN') {
      accountType = subscriptionPlan.planId
    } else if (
      subscriptionPlan.paymentStatus &&
      subscriptionPlan.paymentStatus === 'paid'
    ) {
      accountType = subscriptionPlan.planId
    } else {
      accountType = 'FREE'
    }
  }

  // NO RESTRICTION FOR ADMIN
  if (accountType === 'ADMIN') {
    return { valid: true, message: '' }
    // } else if (accountType === "FREE") {
    //     // Restrict if FREE user
    //     message = "FREE plan does not have access to this feature!";
    //     notification['error']({ message });
    //     return { valid: false, message: message };
  } else {
    if (accountType !== 'FREE') {
      // Restrict if plan expired or no plan
      if (!subscriptionPlan) {
        message = 'Please purchase a subscription plan!'
        notification['error']({ message })
        return { valid: false, message: message }
      }

      const expiryDate = moment(subscriptionPlan.expiryDate.toDate())
      const isBefore = moment().isBefore(expiryDate)
      const isSame = moment().isSame(expiryDate)

      if (isBefore === false && isSame === false) {
        message = 'Your plan is expired!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    }

    let quotaUsed = {
      quotaAiRequest: 0,
      quotaKwResearch: 0,
      quotaGoogleKwResearch: 0,
      quotaBingKwResearch: 0,
      quotaSpyAdsKw: 0,
    }

    const docRef = doc(Database, 'users', user.uid)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const upUserData = docSnap.data()
      subscriptionPlan = upUserData.subscriptionPlan
        ? upUserData.subscriptionPlan
        : null
      if (upUserData.quotaUsed) {
        quotaUsed.quotaAiRequest = upUserData.quotaUsed.quotaAiRequest
          ? upUserData.quotaUsed.quotaAiRequest
          : 0
        quotaUsed.quotaKwResearch = upUserData.quotaUsed.quotaKwResearch
          ? upUserData.quotaUsed.quotaKwResearch
          : 0
        quotaUsed.quotaGoogleKwResearch = upUserData.quotaUsed
          .quotaGoogleKwResearch
          ? upUserData.quotaUsed.quotaGoogleKwResearch
          : 0
        quotaUsed.quotaBingKwResearch = upUserData.quotaUsed.quotaBingKwResearch
          ? upUserData.quotaUsed.quotaBingKwResearch
          : 0
        quotaUsed.quotaSpyAdsKw = upUserData.quotaUsed.quotaSpyAdsKw
          ? upUserData.quotaUsed.quotaSpyAdsKw
          : 0
      }
    } else {
      console.log('No such document!')
      message = 'Something wrong with user account!'
      notification['error']({ message })
      return { valid: false, message: message }
    }

    if (type === 'KEYWORD_LABS') {
      if (!subscriptionPlan.quotaKwResearch) {
        subscriptionPlan.quotaKwResearch = 1
      }

      if (subscriptionPlan.quotaKwResearch > quotaUsed.quotaKwResearch) {
        return { valid: true, message: message }
      } else {
        message = 'Keyword research request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else if (type === 'GOOGLE_ADS_PLANNER') {
      if (!subscriptionPlan.quotaGoogleKwResearch) {
        subscriptionPlan.quotaGoogleKwResearch = 1
      }
      if (
        subscriptionPlan.quotaGoogleKwResearch > quotaUsed.quotaGoogleKwResearch
      ) {
        return { valid: true, message: message }
      } else {
        message = 'Google ads planner request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else if (type === 'BING_ADS_PLANNER') {
      if (!subscriptionPlan.quotaBingKwResearch) {
        subscriptionPlan.quotaBingKwResearch = 1
      }

      if (
        subscriptionPlan.quotaBingKwResearch > quotaUsed.quotaBingKwResearch
      ) {
        return { valid: true, message: message }
      } else {
        message = 'Bing ads planner request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else if (type === 'SPY_ADS' || type === 'YOUTUBE_ADS') {
      if (subscriptionPlan.quotaSpyAdsKw > quotaUsed.quotaSpyAdsKw) {
        return { valid: true, message: message }
      } else {
        message = 'Spy ads request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else if (type === 'SHOPPING_ADS') {
      if (subscriptionPlan.quotaSpyAdsKw > quotaUsed.quotaSpyAdsKw) {
        return { valid: true, message: message }
      } else {
        message = 'Shopping ads request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else if (type === 'AI_REQUEST') {
      const prompt = data.prompt
      const promptCount = prompt.length

      if (
        subscriptionPlan.quotaAiRequest >
        quotaUsed.quotaAiRequest + promptCount
      ) {
        return { valid: true, message: message }
      } else {
        message = 'Ai request quota exceeded!'
        notification['error']({ message })
        return { valid: false, message: message }
      }
    } else {
      message = 'Invalid request type!'
      notification['error']({ message })
      return { valid: false, message: message }
    }
  }
}

export const updateRequestUserQuota = async (type, user, data) => {
  let updateCounter = {}
  let message = ''

  if (type === 'KEYWORD_LABS') {
    updateCounter = { 'quotaUsed.quotaKwResearch': increment(data.numResults) }
  } else if (type === 'GOOGLE_ADS_PLANNER') {
    updateCounter = {
      'quotaUsed.quotaGoogleKwResearch': increment(data.numResults),
    }
  } else if (type === 'BING_ADS_PLANNER') {
    updateCounter = {
      'quotaUsed.quotaBingKwResearch': increment(data.numResults),
    }
  } else if (type === 'SPY_ADS') {
    updateCounter = { 'quotaUsed.quotaSpyAdsKw': increment(25) }
  } else if (type === 'SHOPPING_ADS') {
    updateCounter = { 'quotaUsed.quotaSpyAdsKw': increment(data.numResults) }
  } else if (type === 'AI_REQUEST') {
    const prompt = data.prompt
    const promptCount = prompt.length
    updateCounter = { 'quotaUsed.quotaAiRequest': increment(promptCount) }
  } else if (type === 'YOUTUBE_ADS') {
    updateCounter = { 'quotaUsed.quotaSpyAdsKw': increment(data.numResults) }
  } else {
    message = 'Invalid request type!'
    notification['error']({ message })
    return { valid: false, message: message }
  }

  let subscriptionPlan
  const docRef = doc(Database, 'users', user.uid)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const upUserData = docSnap.data()
    subscriptionPlan = upUserData.subscriptionPlan
      ? upUserData.subscriptionPlan
      : null

    if (subscriptionPlan) {
      if (!subscriptionPlan.quotaKwResearch) {
        updateCounter['subscriptionPlan.quotaKwResearch'] = increment(
          FREE_KEYWORD_RESEARCH_QUOTA,
        )
      }
      if (!subscriptionPlan.quotaBingKwResearch) {
        updateCounter['subscriptionPlan.quotaBingKwResearch'] = increment(
          FREE_KEYWORD_RESEARCH_QUOTA_BING,
        )
      }
      if (!subscriptionPlan.quotaGoogleKwResearch) {
        updateCounter['subscriptionPlan.quotaGoogleKwResearch'] = increment(
          FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
        )
      }
    }
  }

  try {
    const updateRef = doc(Database, 'users', user.uid)
    await updateDoc(updateRef, updateCounter)
    return { valid: true, message: 'quota updated' }
  } catch (error) {
    message = 'Something went wrong with quota!'
    console.log(message)
    notification['error']({ message })
    return { valid: false, message: message }
  }
}
