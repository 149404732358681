import React from 'react';
import { Column } from '@ant-design/charts';

const ClusteredColumnChart = ({rawData}) => {

  // Transform the data
  const data = rawData.map(item => ({
    location: item.dimensionValues[0].value, 
    value: parseFloat(item.metricValues[0].value)
  }));

  const config = {
    data,
    xField: 'location',
    yField: 'value',
    color: ['#1890ff'], // Set your preferred color
    tooltip: {
      showMarkers: true,
      formatter: (datum) => ({
        name: 'Active Users',
        value: datum.value,
      }),
    },
    xAxis: {
      title: { text: 'Location' },
      tickCount: 5,
      label: {
        formatter: (text) => text,
      },
    },
    yAxis: {
      title: { text: 'Active Users' },
      tickCount: 5,
      label: {
        formatter: (text) => parseFloat(text),
      },
    },
    legend: {
      itemName: {
        formatter: (text) => text,
      },
    },
  };

  return <div style={{ width: '95%' }}><Column {...config} /></div>;
};

export default ClusteredColumnChart;
