import { Modal } from "antd";
import React, { useEffect, useState } from 'react';
import Play from "../../styles/icons/bunny-play.svg";
// import Loading from "../../styles/icons/loading-video.svg";
import { Header } from "../../styles/styles";

const BunnyPlayer = (props) => {

    const { type } = props;
    const bunnyVideos = {
        // GOOGLE AI
        GOOGLE: {
            name: "Google",
            playListId: "34531",
            videoId: "6d1105b4-9794-4779-9082-43636e48e1b3",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/6d1105b4-9794-4779-9082-43636e48e1b3/thumbnail_60ecc0c0.jpg",
        },
        // FACEBOOK AI STANDARD
        FACEBOOK: {
            name: "Facebook",
            playListId: "34531",
            videoId: "cc6a9110-2160-45ad-8cdd-c80eab41b9b6",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/cc6a9110-2160-45ad-8cdd-c80eab41b9b6/thumbnail_74078b01.jpg",
        },
        // FACEBOOK AI ADVANCE
        FACEBOOK_ADVANCED: {
            name: "Facebook Advance",
            playListId: "34531",
            videoId: "a2fb776e-4ea9-4d9a-aac0-a838f140691b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/a2fb776e-4ea9-4d9a-aac0-a838f140691b/thumbnail_3d0e402f.jpg",
        },
        FACEBOOK_HEADLINE: {
            name: "Facebook Headline",
            playListId: "34531",
            videoId: "9758d01b-ab89-4353-8eb5-22ee7e4b6892",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/9758d01b-ab89-4353-8eb5-22ee7e4b6892/thumbnail_832c3f26.jpg",
        },
        FACEBOOK__HIDDEN_INTERESTS: {
            name: "Facebook Hidden Interests",
            playListId: "34531",
            videoId: "9d08fb84-6e64-4470-8ab1-f5e4069408f3",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/9d08fb84-6e64-4470-8ab1-f5e4069408f3/thumbnail_7cb97998.jpg",
        },
        RELATED_KEYWORDS: {
            name: "Related Keyword",
            playListId: "34531",
            videoId: "f6d4add6-3364-4db6-b276-82703f4d5fc3",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/f6d4add6-3364-4db6-b276-82703f4d5fc3/thumbnail_7c9033d1.jpg",
        },
        KEYWORD_SUGGESTIONS: {
            name: "Keyword Suggestions",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_eb3cf7ec.jpg",
        },
        //TODO: change the view link and thubnail image
        SEARCH_VOLUME: {
            name: "Search Volume",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        KEYWORDS_SITE: {
            name: "Keywords Site",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        KEYWORDS_FOR_KEYWORDS: {
            name: "Keywords for Keywords",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        KEYWORDS_ADS_TRAFFIC: {
            name: "Keywords for Traffic",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        BING_SEARCH_VOLUME: {
            name: "Bing Search Volume",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        BING_KEYWORDS_SITE: {
            name: "Bing Keywords Site",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        BING_KEYWORDS_FOR_KEYWORDS: {
            name: "Bing Keywords for Keywords",
            playListId: "34531",
            videoId: "b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b45f3b7f-0c3e-4714-8ca0-a8bbf94d1aaf/thumbnail_503ba079.jpg",
        },
        //till here
        SPY_SHOPPING_ADS: {
            name: "Shopping Ads",
            playListId: "34531",
            videoId: "0ab7fdcf-8302-4c70-a207-5423974ccd03",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/0ab7fdcf-8302-4c70-a207-5423974ccd03/thumbnail_5174d3b2.jpg",
        },
        SPY_SEARCH_ADS: {
            name: "Search Ads",
            playListId: "34531",
            videoId: "7de9d209-ef0f-482b-bac2-3661f633c4bb",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/7de9d209-ef0f-482b-bac2-3661f633c4bb/thumbnail_64a98f7a.jpg",
        },
        GOOGLE_SHOPPING: {
            name: "Google Shopping",
            playListId: "34531",
            videoId: "b09a3171-8db9-49f9-9cdd-2949f95b5726",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b09a3171-8db9-49f9-9cdd-2949f95b5726/thumbnail_6f43e5f1.jpg",
        },
        AMAZON_LISTING: {
            name: "Amazon Shopping",
            playListId: "34531",
            videoId: "c127bed1-2b56-4ff7-aa8e-f56609d55c67",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/c127bed1-2b56-4ff7-aa8e-f56609d55c67/thumbnail_e5caef9c.jpg",
        },
        // YOUTUBE_ADS
        YOUTUBE: {
            name: "Youtube Ads",
            playListId: "34531",
            videoId: "3b8512c2-c26b-427b-9815-1bad36e44236",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/3b8512c2-c26b-427b-9815-1bad36e44236/thumbnail_9d906f2c.jpg",
        },
        GOOGLE_ECOM: {
            name: "Google Ecom Ads",
            playListId: "34531",
            videoId: "b09a3171-8db9-49f9-9cdd-2949f95b5726",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b09a3171-8db9-49f9-9cdd-2949f95b5726/thumbnail_f3725b90.jpg",
        },
        DISPLAY_ADS: {
            name: "Display Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_1ce812d1.jpg",
        },
        //TODO: change the view link and thubnail image  
        GOOGLE_ADS: {
            name: "Google Ads",
            playListId: "34531",
            videoId: "b09a3171-8db9-49f9-9cdd-2949f95b5726",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b09a3171-8db9-49f9-9cdd-2949f95b5726/thumbnail_6f43e5f1.jpg",
        },
        FLIPKART_ADS: {
            name: "Flipkart Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        REDDIT_ADS: {
            name: "Reddit Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        LINKEDIN_ADS: {
            name: "LinkedIn Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        AMAZON_ADS: {
            name: "Amazon Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        FACEBOOK_ADS: {
            name: "Facebook Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        BING_ECOM_ADS: {
            name: "Bing Ecom Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        BING_ADS: {
            name: "Bing Ads",
            playListId: "34531",
            videoId: "dff6f877-e7fc-4cd1-89b2-819ffbb80c3b",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dff6f877-e7fc-4cd1-89b2-819ffbb80c3b/thumbnail_5228380b.jpg",
        },
        // till here
        ECOM_ROAS_ANALYSIS: {
            name: "Ecom Roas Analysis",
            playListId: "34531",
            videoId: "cc06147b-0df7-4953-8352-2a2ba4bf4448",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/cc06147b-0df7-4953-8352-2a2ba4bf4448/thumbnail_b3ca8d90.jpg",
        },
        ECOM_ROAS_MAXIMIZER: {
            name: "Ecom Roas Maximizer",
            playListId: "34531",
            videoId: "82564dd3-1d51-417e-b3b6-b6f842589ea6",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/82564dd3-1d51-417e-b3b6-b6f842589ea6/thumbnail_dd542c90.jpg",
        },
        LEADS_CAMPAIGN_GOALS: {
            name: "Lead Campaign Goals",
            playListId: "34531",
            videoId: "253d04ed-bde1-4151-a416-3596eda539fc",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/253d04ed-bde1-4151-a416-3596eda539fc/thumbnail_b8672395.jpg",
        },
        LEADS_ROAS_ANALYSIS: {
            name: "Lead Roas Analysis",
            playListId: "34531",
            videoId: "dcd9314e-01c5-4c6f-b50e-0fee2ef4bfca",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/dcd9314e-01c5-4c6f-b50e-0fee2ef4bfca/thumbnail_58ebccc9.jpg",
        },
        LEADS_ROAS_MAXIMIZER: {
            name: "Lead Roas Maximizer",
            playListId: "34531",
            videoId: "b974fa35-1448-43dd-b007-e0acb0ddec50",
            thumbImage: "https://vz-2edfda68-e2f.b-cdn.net/b974fa35-1448-43dd-b007-e0acb0ddec50/thumbnail_3da3f70e.jpg",
        }
    };
    // <BunnyPlayer type="ROAS_MAXIMIZER" />
    // <BunnyPlayer type={searchType} />

    const [videoUrlId, setVideoUrlId] = useState("")
    const [modal2Visible, setModal2Visible] = useState(false)
    // const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        setVideoUrlId(bunnyVideos[type].videoId);
    }, [type]) // eslint-disable-line

    const setModal2VisibleFun = (modal2Visible) => {
        setModal2Visible(modal2Visible);
    }

    // const iframeLoaded = () => {
    //     setisLoading(false)
    // }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' ,display:'none'}}>
                <Header>Watch Tutorial </Header>
                <div className="flapImage" id="idHot" style={{ position: "relative", textAlign: "center" }}>
                    <img
                        src={bunnyVideos[type].thumbImage}
                        alt="Thumb"
                        style={{
                            position: 'relative',
                            top: '0',
                            left: '0',
                            width: '390px',
                            minWidth: '390px',
                            minHeight: '225px',
                            opacity: 0.9,
                            borderRadius: '10px',
                            border: '2px solid #0578df14',
                            cursor: 'pointer',
                        }}
                        onClick={() => setModal2VisibleFun(true)}
                    />
                    <img
                        src={Play}
                        alt="Play"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45px',
                            height: '45px',
                            cursor: "pointer"
                        }}
                        onClick={() => setModal2VisibleFun(true)}
                    />
                </div>
            </div>
            {
                modal2Visible &&
                <Modal
                    centered
                    visible={modal2Visible}
                    onOk={() => setModal2VisibleFun(false)}
                    onCancel={() => setModal2VisibleFun(false)}
                    width={800}
                    footer={null}
                    closable={false}
                    wrapClassName={'video-modal'}
                >
                    <div style={{
                        width: '800px',
                        height: '450px'
                    }}>
                        <div style={{
                            width: '800px',
                            height: '450px',
                            borderRadius: "10px",
                            backgroundColor: "#333333",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>

                            {/* {isLoading ?
                                <img src={Loading} style={{ width: '100px', height: '100px' }} alt="Loadig" />
                                : ""
                            } */}

                            <iframe
                                title="BoostWithHMA"
                                src={`https://iframe.mediadelivery.net/embed/${bunnyVideos[type].playListId}/${videoUrlId}?autoplay=true`}
                                // https://iframe.mediadelivery.net/embed/34531/412ffe70-3a33-4b9d-a011-32de3e5acc5c
                                loading={"lazy"}
                                // onLoad={() => iframeLoaded()}
                                style={{
                                    borderRadius: "10px",
                                    boxShadow: "rgba(125, 120, 120, 0.75) 10px 5px 45px 4px",
                                    margin: "0 auto",
                                    border: 'none',
                                    height: '100%',
                                    width: '100%',
                                    // display: isLoading ? 'none' : 'block'
                                    display: 'block'
                                }}
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default BunnyPlayer;
