import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Input, notification, Space, Button } from 'antd';
import { LogoutOutlined, MailOutlined } from '@ant-design/icons';
import { PAGE_HEAD_TITLE } from "../../constants";
import logo from "../../styles/images/logo.svg";
import './auth.css';

const ResetPassword = (props) => {

    const [userEmail, setUserEmail] = useState('');
    const [formError, setFormError] = useState('');

    useEffect(() => {
        document.title = `Reset Password ${PAGE_HEAD_TITLE}`;
        document.body.classList.add('bodyoverflowunset');
        return () => {
            document.body.classList.remove('bodyoverflowunset');
        };
    }, []);

    const handleResetPassword = () => {
        setFormError('');
        // console.log("handleResetPassword called")
        let errorMessage = "";

        /* eslint-disable-next-line */
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (userEmail === "") {
            errorMessage = "Please enter your email!";
        } else if (regex.test(userEmail) === false) {
            errorMessage = "Please enter valid email!";
        }
        // console.log("errorMessage:", errorMessage)

        if (errorMessage) {
            setFormError(errorMessage);
        } else {
            const auth = getAuth();
            sendPasswordResetEmail(auth, userEmail)
                .then(() => {
                    openNotificationWithIcon('success', 'Password reset email sent! Please check your spam folder as well.')
                })
                .catch((error) => {
                    const errorCode = error.code;
                    errorMessage = error.message;
                    console.log('errorCode', errorCode)
                    console.log('errorMessage', errorMessage)
                    setFormError(errorCode);
                    openNotificationWithIcon('error', errorCode)
                });
        }
    }

    const openNotificationWithIcon = (type, msg) => {
        if (msg === 'auth/user-not-found') {
            notification[type]({
                message: 'Email address does not exist with any account',
            });
            setFormError('Email address does not exist with any account');
        } else {
            setFormError(msg);
            notification[type]({
                message: msg,
            });
        }
    };

    return (
        <>
            <main className='_main_app_container auth' style={{ height: '100vh' }}>
                <Space />
                <div className="main">
                    <div className='text-center mb-4'>
                        <img src={logo} alt="Boost with HMA" className="logo auth-logo" />
                    </div>
                    <section className="signup">
                        <div className="container">
                            <div className="signup-content">
                                <form id="signup-form" className="signup-form" onSubmit={(e) => e.preventDefault()}>
                                    <h2 className="form-title" style={{ marginBottom: '10px' }}>Reset password</h2>
                                    <span className='invalid-feedback d-block' style={{ color: formError === 'Password reset email sent!' ? 'green' : 'red' }}>{formError}&nbsp;</span>
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            className="form-input"
                                            placeholder="Please enter your email address"
                                            value={userEmail}
                                            onChange={(e) => setUserEmail(e.target.value)}
                                            prefix={<MailOutlined style={{ fontSize: 20, marginRight: 10 }} />}
                                        />
                                    </div>
                                    <div className="form-group text-center mb-0 ">
                                        <Button size='large' onClick={handleResetPassword} type='primary w-100'>Send Password Reset Email <LogoutOutlined style={{ verticalAlign: 'middle' }} /></Button>
                                    </div>
                                </form>
                            </div>
                            <div className="text-center mt-4">
                                <Link to="/login" className="text-info">Go back to Login</Link>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <footer className="footer-content">
            © 2024 All Rights Reserved. HMA TECH & MARKETING SOLUTIONS LLC | <a href="https://www.marketingwithhma.com" target="_blank" rel="noreferrer">www.marketingwithhma.com</a>
      | Privacy Policy | Terms & Conditions
    </footer>
        </>
    )
}

export default ResetPassword
