import React from 'react';
import { Bar } from '@ant-design/plots';

// const data = [
//   { ageRange: '25-34', leads: 4 },
//   { ageRange: '35-44', leads: 8 },
//   { ageRange: '45-54', leads: 4 },
//   { ageRange: '55-64', leads: 1 },
//   { ageRange: '65+', leads: 0 },
// ];



const LeadsAgeBarChart = ({data}) => {
    const config = {
        data,
        xField: 'leads',
        yField: 'age',
        seriesField: 'age',
        color: '#4A3FFF', // Use your desired color
        legend: false, // Hide legend if not needed
        xAxis: {
          title: {
            text: 'leads',
            style: { fontSize: 12 },
          },
        },
        yAxis: {
          title: {
            text: 'Age',
            style: { fontSize: 12 },
          },
          label: {
            formatter: (text) => `${text}`, // Customize labels if necessary
          },
        },
        label: {
          position: 'middle', // Display label in the middle of the bar
          style: {
            fill: '#FFFFFF', // Label text color
            fontSize: 12,
          },
        },
        barWidthRatio: 0.8, // Adjust the width of bars
        marginRatio: 0.1,
        tooltip: {
          showMarkers: false,
          customContent: (title, items) => {
            return `<div style="padding: 5px; font-size: 12px;">Leads: ${items[0]?.data.leads}</div>`;
          },
        },
      };
  return (
    <div style={{ width: '100%', height: '378px' }}>
      <Bar {...config} />
    </div>
  );
};

export default LeadsAgeBarChart;
