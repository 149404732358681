import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import logo from "../../../styles/images/facebooklogo.svg";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import "../components/TableWithTrends.css";
import { calculatePercentageChange } from "../../../utils/Helpers";

const CustomTooltip = ({ children, tooltipText, date }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <span
      style={{ position: "relative", cursor: "pointer" }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: "20px",
            left: "-60",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            whiteSpace: "nowrap",
            fontSize: "12px",
            zIndex: 10,
          }}
        >
          {/* Tooltip Text with border */}
          <div
            style={{
              borderBottom: "1px solid #ddd",
              borderRadius: "4px",
              padding: "4px",
              marginRight: "4px", // Space between the columns
              flex: 1, // Ensures this column takes up available space
            }}
            dangerouslySetInnerHTML={{ __html: tooltipText }}
          />

          {/* Date Field with border */}
          <div
            style={{
              padding: "4px",
              flex: 0, // Keeps the date field size fixed
            }}
          >
            Compare To : {date}
          </div>
        </div>
      )}
    </span>
  );
};

const TableWithTrendsEcom = ({ prvdata, data, date }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const combinedData = data.flatMap((item, index) => {
    const normalDate = new Date(item?.date);

    const prevIndex = index; // This assumes the data is ordered correctly and matches the number of entries.

    // Ensure the index is valid for prvdata
    const previousDataItem =
      prevIndex < prvdata.length ? prvdata[prevIndex] : null;

    return item.data.length > 0
      ? item.data.map((entry, entryIndex) => {
          const entryDateStart = new Date(entry.date_start);
          const previousEntry = previousDataItem?.data[entryIndex];
          // pre spend
          const previousSpend = previousEntry
            ? parseFloat(previousEntry.spend)
            : 0;
          // prev outbound clicks
          const previousoutboundclicks = previousEntry
            ? parseFloat(
                previousEntry.actions?.find(
                  (action) => action.action_type === "link_click"
                )?.value
              )
            : 0;
          // prev chekout initiated
          const previouscheckoutinitiated = previousEntry
            ? parseFloat(
                previousEntry.actions?.find(
                  (action) => action.action_type === "initiate_checkout"
                )?.value
              )
            : 0;
          // prev adds to cart
          const previousaddstocart = previousEntry
            ? parseFloat(
                previousEntry.actions?.find(
                  (action) => action.action_type === "add_to_cart"
                )?.value
              )
            : 0;
          // prev website purchase
          const prevwebsitepurchases = previousEntry
            ? parseFloat(
                previousEntry.actions?.find(
                  (action) => action.action_type === "purchase"
                )?.value
              )
            : 0;

          // curr spend
          const currentSpend = entry.spend ? parseFloat(entry.spend) : 0;
          // curr outboundclicks
          const currentoutboundclicks = entry.actions
            ? parseFloat(
                entry.actions.find(
                  (action) => action.action_type === "link_click"
                )?.value
              )
            : 0;
          // curr checkout inititated
          const currentcheckoutinitiated = entry.actions
            ? parseFloat(
                entry.actions.find(
                  (action) => action.action_type === "initiate_checkout"
                )?.value
              )
            : 0;
          // curr adds to cart
          const currentaddstocart = entry.actions
            ? parseFloat(
                entry.actions.find(
                  (action) => action.action_type === "add_to_cart"
                )?.value
              )
            : 0;
          // curr website purchase
          const currwebsitepurchases = entry.actions
            ? parseFloat(
                entry.actions.find(
                  (action) => action.action_type === "purchase"
                )?.value
              )
            : 0;

          return {
            ...entry,

            date_start: formatDate(normalDate), // Format the date range using itemDateStart
            previousamountSpent: previousSpend,
            previousoutboundclicks: previousoutboundclicks,
            previousaddstocart: previousaddstocart,
            previouscheckoutinitiated: previouscheckoutinitiated,
            previouswebsitepurchases: prevwebsitepurchases,

            previousDate: formatDate(previousDataItem?.date),
            amountSpent: entry?.spend,
            checkoutinitiated:
              currentcheckoutinitiated > 0 ? currentcheckoutinitiated : 0,
            outboundclicks:
              currentoutboundclicks > 0 ? currentoutboundclicks : 0,
            addstocart: currentaddstocart > 0 ? currentaddstocart : 0,
            websitepurchases:
              currwebsitepurchases > 0 ? currwebsitepurchases : 0,

            // Add dynamic comparison fields
            outboundclicksChange: calculatePercentageChange(
              currentoutboundclicks,
              previousoutboundclicks
            ),
            amountSpentChange: calculatePercentageChange(
              currentSpend,
              previousSpend
            ),
            checkoutinitiatedChange: calculatePercentageChange(
              currentcheckoutinitiated,
              previouscheckoutinitiated
            ),
            addstocartChange: calculatePercentageChange(
              currentaddstocart,
              previousaddstocart
            ),
            websitepurchaseChange: calculatePercentageChange(
              currwebsitepurchases,
              prevwebsitepurchases
            ),
          };
        })
      : [
          {
            date_start: formatDate(normalDate),
            previousamountSpent: 0,
            previousoutboundclicks: 0,
            previousaddstocart: 0,
            previouscheckoutinitiated: 0,
            prevwebsitepurchases: 0,

            previousDate: 0,

            amountSpent: 0,
            outboundclicks: 0,
            checkoutinitiated: 0,
            addstocart: 0,
            websitepurchases: 0,

            outboundclicksChange: 0,
            amountSpentChange: 0,
            checkoutinitiatedChange: 0,
            addstocartChange: 0,
            websitepurchaseChange: 0,
          },
        ];
  });

  const createDynamicFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: ₹ <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        ₹{value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const ValueFormatter = (label, valueKey) => (cell, row) => {
    const previousValue = row[`previous${valueKey}`];
    const value = row[valueKey];
    const changeValue = row[`${valueKey}Change`] || 0;
    const tooltipText = `${label}: <strong>${previousValue}</strong> | ${
      changeValue > 0 ? "+" : ""
    }${changeValue.toFixed(2)}%`;

    return (
      <span>
        {value}{" "}
        <CustomTooltip tooltipText={tooltipText} date={row?.previousDate}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "4px 10px",
              borderRadius: "10px",
              backgroundColor: changeValue > 0 ? "#F0FAF4" : "#FEF1F1",
              color: changeValue > 0 ? "#3C763D" : "#A94442",
            }}
          >
            {changeValue > 0 ? (
              <IoMdTrendingUp size={14} style={{ marginRight: "5px" }} />
            ) : (
              <IoMdTrendingDown size={14} style={{ marginRight: "5px" }} />
            )}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {Math.abs(changeValue).toFixed(2)}%
            </span>
          </div>
        </CustomTooltip>
      </span>
    );
  };

  const columns = [
    {
      dataField: "date_start",
      text: "Date",
      sort: false,
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "spend",
      text: "Amount Spent",
      sort: false,
      formatter: createDynamicFormatter("Amount Spent", "amountSpent"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "outboundclicks",
      text: "Outbound Clicks",
      sort: false,
      formatter: ValueFormatter("Outbound Clicks", "outboundclicks"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "addstocart",
      text: "Adds To Cart",
      sort: false,
      formatter: ValueFormatter("Adds To Cart", "addstocart"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "checkoutinitiated",
      text: "Checkouts Initiated",
      sort: false,
      formatter: ValueFormatter("Checkouts Initiated", "checkoutinitiated"),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
    {
      dataField: "websitepurchases",
      text: "Website Purchases",
      sort: false,
      formatter: createDynamicFormatter(
        "Website Purchases",
        "websitepurchases"
      ),
      headerStyle: {
        whiteSpace: "nowrap",
        fontWeight: "600",
        fontSize: "13px",
        zIndex: 999999,
      },
    },
  ];

  return (
    <div className="table-card">
      <h5 className="card-title">
        <img style={{ width: "24px", height: "24px" }} src={logo} alt="fb" />{" "}
        Funnel Analysis from Meta Amount Spent to Website Purchases for | {date}
      </h5>
      <p className="text-muted">
        Amount Spent, Outbound Clicks, Adds to Cart, Checkouts Initiated,
        Website Purchases
      </p>
      <div className="table-scroll-wrapper">
        <BootstrapTable
          keyField="date"
          data={combinedData}
          columns={columns}
          bordered={false}
          hover
          condensed
          rowStyle={{ border: "none !important" }}
          classes="custom-table"
        />
      </div>
    </div>
  );
};

export default TableWithTrendsEcom;
