import React from 'react'
import { Line } from '@ant-design/charts';

const KwChart = (props) => {
    const { monthlySearchesData = [] } = props;

    const config = {
        data: monthlySearchesData,
        padding: 'auto',
        xField: 'datetime',
        yField: 'search_volume',
        xAxis: {
            // type: 'timeCat',
            // tickCount: 5,
        },
        smooth: true,
        // height: 200
    };

    return <Line {...config} />;
}

export default KwChart;
