import React from 'react';
import { Pie } from '@ant-design/charts';

const EngagementPieChart = ({ data }) => {
  // Map data to match the expected fields
  const chartData = data.map(item => ({
    item: item.gender, // Use gender as the colorField
    value: parseInt(item.leads), // Ensure value is parsed as an integer
  }));

  const config = {
    appendPadding: 20,
    data: chartData, // Use mapped chartData here
    angleField: 'value',
    colorField: 'item', // Use 'item' as the colorField which corresponds to gender
    radius: 1,
    color: ({ item }) => {
      if (item === 'male') {
        return '#118DFF'; // Blue for Male
      } else if (item === 'female') {
        return '#12239E'; // Violet for Female
      }
      return '#E66C37'; // Orange for Unknown
    },
    label: {
      type: 'outer',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [{ type: 'element-active' }],
  };

  return (
    <div style={{ width: "95%", height: '100%' }}>
      <Pie {...config} />
    </div>
  );
};

export default EngagementPieChart;
